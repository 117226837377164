import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IUser} from '../interfaces/IUser';
import {AuthService} from '../services/auth.service';
import {finalize} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

   public formLogin: UntypedFormGroup;
   public showLoginMessage = false;
   public error;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public route: ActivatedRoute,
               public loader: NgxUiLoaderService) {
      this.formLogin = new UntypedFormGroup({
         email: new UntypedFormControl('', Validators.compose([
            Validators.required,
            Validators.email
         ])),
         password: new UntypedFormControl('', Validators.required)
      });

   }

   ngOnInit() {
      this.route.queryParams.subscribe(res => {
         if (res.need_login) {
            this.showLoginMessage = true;
         }
      });

   }

   submitLoginForm() {
      this.error = null;
      this.loader.start();
      this.api.post('admin/login', this.formLogin.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.auth.login(res);
            this.router.navigate(['dashboard']);
         }, err => {
            this.error = err.error;
         });

   }
}
