import {Component, EventEmitter, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as lo from 'lodash';

@Component({
   selector: 'app-sub-payment',
   templateUrl: './sub-payment.component.html',
   styleUrls: ['./sub-payment.component.scss'],
   providers: [
      {
         multi: true,
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => SubPaymentComponent),
      }
   ]
})
export class SubPaymentComponent implements ControlValueAccessor {

   @Input() items = [];
   public subPayments = [];
   public emit = (_any) => {};

   constructor() { }

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {
      const c = [];
      lo.each(this.items, (item) => {
         c.push({
            id: item.product_id,
            type: item.type,
            amount: 0
         });
      });
      this.subPayments = c;

      if (obj) {
         lo.each(this.subPayments, (payment) => {

            lo.each(obj, (p) => {
               if (p.id === payment.id && p.type === payment.type) payment.amount = p.amount;
            });

         });
      }
      this.emit(this.subPayments);

   }

   updated() {
      this.emit(this.subPayments);
   }
}
