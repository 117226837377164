import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ILaravelDataTableConfig } from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import { Observable, of } from 'rxjs';
import { IAdManagerConfig, ManageAdsComponent } from '../manage-ads/manage-ads.component';
import swal from 'sweetalert2';
import { filter, finalize, map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { flatMap } from 'rxjs/internal/operators';

@Component({
   selector: 'app-manage-notices',
   templateUrl: './manage-notices.component.html',
   styleUrls: ['./manage-notices.component.scss']
})
export class ManageNoticesComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public interestedPrograms$: Observable<any> = this.api.get('models/programs', {}).pipe(
      map((data: any) => data.filter(item => item.is_test_series))
   );

   public selectedItem;
   public endpoint = environment.endpoint + 'admin/notice/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         { name: 'ID', key: 'id', type: 'text', width: 'min' },
         { name: 'Title', key: 'title', type: 'text', },
         { name: 'Targeted', key: 'general', type: 'text', },
         { name: 'Sent', key: 'sent', type: 'text', },
         { name: 'Status', key: 'status', type: 'text', width: 'min' },
         { name: 'Created', key: 'created_at', type: 'dateIST', width: 'min' }
      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/notification/delete', { id: dataset[index].id })
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe((res: any) => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };


   public form = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.compose([])),
      title: new UntypedFormControl('', Validators.compose([Validators.required])),
      summary: new UntypedFormControl('', Validators.compose([Validators.required])),
      body: new UntypedFormControl('', Validators.compose([])),
      general: new UntypedFormControl('0', Validators.compose([])),
      send_time: new UntypedFormControl(''),
      status: new UntypedFormControl('draft', Validators.required),
      config: new UntypedFormControl(),
   });

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public loader: NgxUiLoaderService
   ) {

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({ status: 'publish' });
      this.modal.open(addNew);
   }

   save() {
      if (this.form.invalid) {
         for (const field in this.form.controls) {
            const control = this.form.get(field);
            if (control.invalid) console.log(control);
            control.markAsTouched();
         }

         return;
      }
      this.loader.start();
      this.api.post('admin/notice/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

}
