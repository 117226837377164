import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
   providedIn: 'root', deps: [AuthService]
})
export class ApiService {

   constructor(
      public http: HttpClient,
      public toast: ToastrService,
      public auth: AuthService,
      public router: Router,
      public loader: NgxUiLoaderService
   ) {
      //alert(window.location.hostname);

   }

   post(path, data, api = true) {
      let endpoint = environment.endpoint + path;
      if (!api) {
         endpoint = environment.website + '/' + path;
      }
      return this.http.post(endpoint, data).pipe(catchError(
         err => {
            return this.handleError(err);
         }), tap(res => this.showToast(res)));

   }

   get(path, data, api = true) {

      let endpoint = environment.endpoint + path;
      // console.log(environment.endpoint);
      if (!api) {
         endpoint = environment.website + '/' + path;
      }

      return this.http.get(endpoint, {
         params: data
      }).pipe(catchError(
         err => {
            return this.handleError(err);
         }));
   }

   handleError(error: HttpErrorResponse) {
      // console.log(error);
      if (error.error && error.error.need_login) {
         this.auth.logout();
         this.loader.start();
         setTimeout(() => {
            this.router.navigate([''], {queryParams: {need_login: true}});
            this.loader.stop();
            //  console.log(this.auth.user);
         }, 1000);
      }
      if (error.error && error.error.hasOwnProperty('toast')) {
         this.toast.error(error.error.toast, 'Error');
      }

      switch (error.status) {
         case 404:
            this.toast.error('Invalid entity / Entity could not be found', 'Error');
            break;
      }
      if (error.status === 0) {
         const err = {error: 'Server is offline'};
         return throwError(err);
      }

      if (error.status === 500) {
         const err = {error: 'Something went wrong.Please notify developer'};
         this.toast.error('Something went wrong.Please notify developer', 'Error');

         return throwError(error.error);
      }

      error.error.status = error.status;
      return throwError(error.error);
   }

   public upload(path, data, api = false) {
      const endpoint = environment.endpoint + path;

      return this.http.post(endpoint, data, {
         reportProgress: true,
         observe: 'events'
      }).pipe(map((event) => {

            switch (event.type) {

               case HttpEventType.UploadProgress:
                  const progress = Math.round(100 * event.loaded / event.total);
                  return {status: 'progress', message: progress};
               case HttpEventType.Response:
                  return {files: event.body};
               default:
                  return `Unhandled event: ${event.type}`;
            }
         })
      );
   }

   private showToast(res) {
      if (res.toast) {
         switch (res.type) {
            case 'OK':
               this.toast.success(res.toast, 'Success', {timeOut: 1000});
               break;
            case 'FAIL':
               this.toast.error(res.toast, 'Failed', {timeOut: 3000});
               break;
         }
      }

   }

   public endExamFilter() {
      return this.get('admin/main-course-filter', {});
   }

   public resourceEndExamFilter() {
      return this.get('admin/resource-main-course-filter', {});
   }

   public paperStageFilter(mainCourseId) {
      return this.post('admin/previous-papers-stage-filter', {main_course_id: mainCourseId});
   }

   public getFileUrl(file) {

      if (typeof file === 'string') {
         file = file.replace('public', 'storage');
         return environment.website + '/' + file;
      }
      return '';
   }
}
