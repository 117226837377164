<select class="custom-select" (change)="changed($event)" [(ngModel)]="this.value">
    <option value="0">--SELECT--</option>
    <ng-container *ngTemplateOutlet="opt;context:{courses:tree,pre:''}"></ng-container>
</select>
<ng-template #opt let-courses="courses" let-pre="pre">
    <ng-container *ngFor="let course of courses">
        <option [value]="course.id">{{pre + course.course_name}}</option>
        <ng-container *ngTemplateOutlet="opt;context:{courses:course.sub_courses,pre:pre+'--'}"></ng-container>
    </ng-container>
</ng-template>

