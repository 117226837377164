import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class UrlHelperService {

   constructor() {
   }

   url(path) {
      if (path.indexOf('public/') > -1) {
         const p = path.replace('public\/', 'storage/');
         return environment.website + '/' + p;
      }
   }
}
