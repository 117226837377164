import {Injectable} from '@angular/core';
import {IUser} from '../interfaces/IUser';
import {Router} from '@angular/router';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';

@Injectable({
   providedIn: 'root',
   deps: [LocalStorageService]
})
export class AuthService {

   @LocalStorage()
   public user: any;

   constructor(
      public router: Router,
      public localStorageService: LocalStorageService,
   ) {
      this.user = this.localStorageService.retrieve('user');
   }

   checkRole(role) {
      if (this.user && this.user.is_role === role) {
         return true; //
      }
   }

   isUserAuthenticated() {
      if (this.user && this.user.api_token) {
         return true;
      }
      return false;
   }

   login(user: IUser) {
      this.user = user;
   }

   logout() {
      this.user = {};
   }
}
