<div class="modal-header"><h4 class="modal-title">Select Languages</h4>
   <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
   </button>

</div>
<div class="modal-body">

   <ul class="list-unstyled">
      <ng-container *ngFor="let l of languages;let idx=index">
         <li class="pt-3 pb-3 text-uppercase" *ngIf="test['paper_'+l.language] !=null"><input [(ngModel)]="l.state" class="mr-3" type="checkbox">{{l.language}}</li>
      </ng-container>
   </ul>

   <div class="section">Document Content</div>
   <ul class="list-unstyled">
      <li *ngFor="let key of config|keyvalue" class="pt-3 pb-3 text-uppercase">
         <input [(ngModel)]="config[key.key]" class="mr-3" type="checkbox">{{key.key}}
      </li>
   </ul>
</div>
<div class="modal-footer">
   <button class="btn btn-block btn-primary" (click)="download()">Download</button>
</div>
