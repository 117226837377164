<div class="page-title d-flex justify-content-between align-items-center">
   <h3>{{routeDataMp ? routeDataMp.title : 'Manage Programmes'}}</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>


<xs-laravel-data-table #table [config]="config" [endpoint]="endpoint">
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button *ngIf="testSeriesPage" class="btn btn-sm btn-secondary mr-1" (click)="openAllotTests(item)">Allot Tests</button>
         <div ngbDropdown class="d-inline-block">
            <button class="btn btn-sm btn-primary" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
               <button ngbDropdownItem (click)="edit(item,idx)">Edit</button>
               <button ngbDropdownItem (click)="manageAds(item,idx)">Manage Advertisements</button>
               <button ngbDropdownItem (click)="duplicate(item)">Duplicate</button>
            </div>
         </div>

         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
   <ng-template field="available" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.available?'Yes':'No'"></app-badge>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Program</h5>
      <button type="button" class="close" (click)="modal.close()" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form"><input type="hidden" formControlName="id" class="form-control">
         <div class="row">
            <div class="col-sm-12">
               <div class="row"><!--add-->

                  <div class="col-sm-12 mb-5">
                     <div class="section">BDRT</div>

                     <app-bdrt formControlName="bdrt"></app-bdrt>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>Program/Batch Name</label>
                        <input type="text" formControlName="name" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>Sub Heading</label>
                        <input type="text" formControlName="sub_heading" class="form-control">
                     </div>
                  </div>

                  <div class="col-sm-12">
                     <div class="form-group">
                        <label>Icon Image</label>
                        <app-icon-uploader [icon]="selectedItem?.icon_image_url" formControlName="icon_image"></app-icon-uploader>

                     </div>
                  </div>

                  <div class="col-6">
                     <div class="form-group">
                        <label>Course</label>
                        <app-course-tree-selection formControlName="courses"></app-course-tree-selection>


                     </div>
                  </div>
                  <div class="col-sm-6" *ngIf="!type">
                     <label>Program category</label>
                     <app-program-category-selector formControlName="category"></app-program-category-selector>
                  </div>
                  <div class="col-sm-12 mb-3" *ngIf="form.value.category.indexOf(15)>-1">
                     <div class="d-flex">
                        <div class="mr-4">
                           <label>TEST OFFLINE</label>
                           <app-toggle-switch formControlName="test_offline"></app-toggle-switch>
                        </div>
                        <div><label>TEST ONLINE</label>
                           <app-toggle-switch formControlName="test_online"></app-toggle-switch>
                        </div>
                     </div>
                     <div class="form-group mt-3">
                        <label for="">Number of tests</label>
                        <input type="text" appNumberOnly formControlName="no_of_tests" class="form-control">
                     </div>


                  </div>

                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 1</label>
                        <input type="text" formControlName="key_point_1" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 2</label>
                        <input type="text" formControlName="key_point_2" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 3</label>
                        <input type="text" formControlName="key_point_3" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 4</label>
                        <input type="text" formControlName="key_point_4" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 5</label>
                        <input type="text" formControlName="key_point_5" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 6</label>
                        <input type="text" formControlName="key_point_6" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>Key point 7</label>
                        <input type="text" formControlName="key_point_7" class="form-control">
                     </div>
                  </div>
                  <div class="col-sm-12">
                     <div class="form-group">
                        <label>Description </label>
                        <app-editor formControlName="content"></app-editor>
                     </div>
                  </div>


                  <div class="col-12">
                     <div class="form-group">

                        <app-doc-uploader
                           [url]="selectedItem?.sample_brochure_url"
                           formats=".pdf,.doc,.docx"
                           directory="sample-brochure"
                           label="Sample Brochure"
                           formControlName="sample_brochure"
                        ></app-doc-uploader>

                     </div>
                  </div>

                  <div class="col-12 mb-4" *ngIf="testSeriesPage">
                     <div class="section">Include In Programs</div>
                     <angular2-multiselect formControlName="programs" [settings]="dropdownSettings" [data]="programs">
                        <c-search>
                           <ng-template>
                              <input type="text" (keyup)="onSearch($event)" placeholder="Search by name/batch" style="border: none;width: 100%; height: 100%;outline: none;"/>
                           </ng-template>
                        </c-search>
                        <c-item>
                           <ng-template let-item='item'>
                              {{item.name}} - {{item.batch_code}}
                           </ng-template>
                        </c-item>
                     </angular2-multiselect>
                  </div>


                  <div class="col-sm-12">
                     <div class="section">Fee</div>
                  </div>

                  <div class="col-sm-12">
                     <div class="row">
                        <div class="col-sm-8">
                           <div class="row">
                              <div class="col-4">
                                 <div class="form-group">
                                    <label>Tax Percentage</label>
                                    <input (input)="calculateTaxes()" type="text" [readonly]="!enableTaxChange" formControlName="tax_percentage" appNumberOnly class="form-control">
                                    <a (click)="enableTaxChange=!enableTaxChange"><small>{{enableTaxChange == true ? 'cancel update' : 'update'}}</small></a>
                                 </div>
                              </div>
                              <div class="col-4">
                                 <div class="form-group">
                                    <label>Admission Fee</label>
                                    <input (input)="calculateTaxes()" type="text" formControlName="admission_fee" appNumberOnly class="form-control">
                                 </div>
                              </div>
                              <div class="col-4">
                                 <div class="form-group">
                                    <label>Tution Fee</label>
                                    <input (input)="calculateTaxes()" type="text" formControlName="tution_fee" appNumberOnly class="form-control">
                                 </div>
                              </div>
                              <div class="col-4">
                                 <div class="form-group">
                                    <label>Total Fee</label>
                                    <input type="text" formControlName="total_fee" readonly class="form-control">
                                 </div>
                              </div>
                              <div class="col-4">
                                 <div class="form-group">
                                    <label>Discounted Fee</label>
                                    <input type="text" formControlName="discounted_fee" class="form-control">
                                 </div>
                              </div>
                              <div class="col-4">
                                 <div class="form-group">
                                    <label>Full downpayment discount</label>
                                    <input type="text" formControlName="full_down_payment_discount" class="form-control">
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-4">
                           <div class="alert alert-info">
                              <div class="d-flex justify-content-between">
                                 <div>
                                    <small>Admission Fee</small>
                                    <div class="font-weight-bold">{{taxes.admissionFee|currency:'INR'}}</div>
                                 </div>
                                 <div>
                                    <small class="mt-2">Admission GST</small>
                                    <div class="font-weight-bold">{{taxes.admissionTax |currency:'INR' }}</div>
                                 </div>
                              </div>

                              <div class="d-flex justify-content-between mt-3">
                                 <div>
                                    <small class="mt-2">Tuition Fee</small>
                                    <div class="font-weight-bold">{{taxes.tutionFee |currency:'INR' }}</div>
                                 </div>
                                 <div>
                                    <small class="mt-2">Tuition Tax</small>
                                    <div class="font-weight-bold">{{taxes.tutionTax |currency:'INR' }}</div>
                                 </div>
                              </div>
                              <div class="d-flex justify-content-between mt-3">
                                 <div>
                                    <small class="font-weight-bold mt-3">Total GST</small>
                                    <div class="font-weight-bold">{{(+taxes.admissionTax + +taxes.tutionTax).toFixed(2) |currency:'INR'}}</div>
                                 </div>
                                 <div>
                                    <small class="font-weight-bold mt-3">Total Fee</small>
                                    <div class="font-weight-bold">{{taxes.net |currency:'INR'}}</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>


                  <div class="col-12 mb-3">

                     <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" formControlName="partial_payment" id="partial-payment">
                        <label class="custom-control-label" for="partial-payment">Allow Partial payment</label>
                     </div>


                  </div>

                  <ng-container *ngIf="form.value.partial_payment">
                     <div class="col-6">
                        <div class="form-group">
                           <label>Minimum partial payment</label>
                           <input type="text" formControlName="minimum_partial_payment" class="form-control">
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="form-group">
                           <label>Days to pay full payment</label>
                           <input type="text" formControlName="days_to_pay_full_payment" class="form-control">
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="form-group">
                           <label>Penalty</label>
                           <input type="text" formControlName="penalty" class="form-control">
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="form-group">
                           <label>Penalty after no. of days </label>
                           <input type="text" formControlName="penalty_threshold" class="form-control">
                        </div>
                     </div>
                  </ng-container>

                  <div class="col-sm-12">
                     <div class="section">Batch</div>
                  </div>
                  <div class="col-3">
                     <div class="form-group">
                        <label>Batch Code</label>
                        <input type="text" formControlName="batch_code" class="form-control">
                     </div>
                  </div>


                  <div class="col-3">
                     <div class="form-group">
                        <label>Batch Year</label>
                        <input type="text" formControlName="batch_year" class="form-control">
                     </div>
                  </div>


                  <div class="col-3">
                     <div class="form-group">
                        <label>Batch Time</label>
                        <input type="text" formControlName="batch_time" class="form-control">
                     </div>
                  </div>


                  <div class="col-3">

                     <div class="form-group">
                        <label>Start Date</label>
                        <div class="input-group">
                           <input class="form-control" readonly
                                  name="dp" formControlName="start_date" ngbDatepicker #d="ngbDatepicker">
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                           </div>
                        </div>
                     </div>


                  </div>


                  <div class="col-12 mt-3 mb-3">
                     <div class="form-group">
                        <label class="d-block">Available For enrollment</label>
                        <app-toggle-switch formControlName="available"></app-toggle-switch>
                     </div>
                  </div>

                  <div class="col-12 mt-3 mb-3">
                     <div class="form-group">
                        <label class="d-block">Offline Payment</label>
                        <app-toggle-switch formControlName="offline_payment"></app-toggle-switch>
                     </div>
                  </div>

                  <div class="col-12 mt-3 mb-3">
                     <div class="form-group">
                        <label class="d-block">Online Payment</label>
                        <app-toggle-switch formControlName="online_payment"></app-toggle-switch>
                     </div>


                  </div>


                  <div class="col-6 mt-3">
                     <div class="form-group">
                        <label>Sort order</label>
                        <input type="text" formControlName="sort_order" class="form-control">
                     </div>
                  </div>


                  <div class="col-12">
                     <div class="form-group">
                        <label class="d-block">Status</label>
                        <app-status formControlName="status" [statusList]="['Draft','Publish','Upcoming']"></app-status>
                     </div>
                  </div>

                  <div class="col-12" *ngIf="form.value.status=='upcoming'">
                     <label class="d-block">Upcoming Date</label>
                     <app-prime-date-time-picker formControlName="upcoming_date"></app-prime-date-time-picker>
                  </div>


               </div>
            </div>
         </div>

      </form>


   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<ng-template #ads>
   <form></form>
</ng-template>
