<div class="page-title d-flex justify-content-between align-items-center">
    <h3>Manage ProgrammeKeyPoint</h3>
    <div>

        <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
    </div>
</div>
<xs-laravel-data-table #table
                       [endpoint]="endpoint"
                       [config]="tableConfig"
></xs-laravel-data-table>

<ng-template #addNew let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} ProgrammeKeyPoint</h5>
        <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <input type="hidden" formControlName="id" class="form-control">

            <div class="row">

                <div class="col-12">
                    <div class="form-group">
                        <label for="">NAME</label>
                        <input type="text" formControlName="name" class="form-control">
                    </div>
                </div>


                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 1</label>
                        <input type="text" formControlName="point_1" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 2</label>
                        <input type="text" formControlName="point_2" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 3</label>
                        <input type="text" formControlName="point_3" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 4</label>
                        <input type="text" formControlName="point_4" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 5</label>
                        <input type="text" formControlName="point_5" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 6</label>
                        <input type="text" formControlName="point_6" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 7</label>
                        <input type="text" formControlName="point_7" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">KEY POINT 8</label>
                        <input type="text" formControlName="point_8" class="form-control">
                    </div>
                </div>
                <div class="col-12">
                    <div class="section">BDRT</div>
                    <app-bdrt formControlName="bdrt"></app-bdrt>
                </div>


            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
    </div>
</ng-template>


