import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import {DatePipe} from '@angular/common';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-test-coupon',
   templateUrl: './manage-test-coupon.component.html',
   styleUrls: ['./manage-test-coupon.component.scss'],
   providers: [DatePipe]
})
export class ManageTestCouponComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   @LocalStorage()
   public showAddCouponForm = false;
   public userResults = [];

   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/test-coupon/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Code', key: 'code', type: 'text', focus: true},
         {
            name: 'Type', key: 'type', type: 'select', width: 'min', selectOptions: of([
               {label: 'Amount', value: 'amount'},
               {label: 'Percent', value: 'percent'},
            ])
         },
         {name: 'Value', key: 'value', type: 'text', width: 'min'},
         {name: 'Used', key: 'used', type: 'text', width: 'min'},
         {name: 'Max Use', key: 'max_use', type: 'text', width: 'min'},
         {
            name: 'Active', key: 'active', type: 'select', width: 'min', selectOptions: of([
               {label: 'Yes', value: 1},
               {label: 'No', value: 0},
            ])
         },
         {name: 'Valid From', key: 'valid_from', type: 'dateIST', width: 'min'},
         {name: 'Valid To', key: 'valid_to', type: 'dateIST', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST', width: 'min'},

      ]
   };
   public form: UntypedFormGroup;
   public singleCoupon: UntypedFormGroup;
   public couponType = [
      {label: 'Amount', value: 'amount'},
      {label: 'Percent', value: 'percent'}
   ];

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public datePipe: DatePipe,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),

      });

      this.singleCoupon = new UntypedFormGroup({
         id: new UntypedFormControl(),
         type: new UntypedFormControl('', Validators.required),
         code: new UntypedFormControl(),
         value: new UntypedFormControl('', Validators.required),
         active: new UntypedFormControl(),
         max_use: new UntypedFormControl(1),
         valid_from: new UntypedFormControl('', Validators.required),
         valid_to: new UntypedFormControl('', Validators.required),
         user: new UntypedFormControl(''),
      });
   }

   ngOnInit() {

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.singleCoupon.reset();
      this.modal.open(addNew, {size: 'md'});
   }

   save() {
      this.loader.start();
      this.api.post('admin/test-coupon/save', this.singleCoupon.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.showAddCouponForm = false;
            this.table.loadData();
         });
   }

   edit(item, index) {
      this.selectedItem = item;
      this.singleCoupon.patchValue(this.selectedItem);
      this.showAddCouponForm = true;
   }

   delete(item, index) {
      swal.fire({
         title: 'Delete :' + item.code + '?',
         icon: 'question',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/test-coupon/delete', {id: item.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.table.removeItemAtIndex(index);
               });
         }
      });
   }

   search($event: any) {
      this.api.post('admin/users/search', {term: $event.query})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.userResults = res;
         });
   }
}
