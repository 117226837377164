<div class="page-title d-flex justify-content-between align-items-center">
    <h3>Manage Exam Analysis</h3>
    <div>
        <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>
        <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
    </div>
</div>
<xs-laravel-data-table #table
                       [endpoint]="endpoint"
                       [config]="tableConfig"
></xs-laravel-data-table>

<ng-template #addNew let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} ExamAnalysis</h5>
        <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <input type="hidden" formControlName="id" class="form-control">
            <div class="row">
                <div class="col-sm-12 mb-5">
                    <div class="section">BDRT</div>
                    <app-bdrt formControlName="bdrt"></app-bdrt>
                </div>
                <div class="col-12">
                    <div class="section">Exam Analysis</div>

                    <div class="form-group">
                        <label for="">End Exam </label>
                        <app-main-category-selector [tree]="categoryTree" formControlName="main_course_id"></app-main-category-selector>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">EXAM NAME</label>
                        <input type="text" formControlName="exam_name" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">SUBJECT NAME</label>
                        <input type="text" formControlName="subject_name" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">EXAM YEAR</label>
                        <input type="text" formControlName="exam_year" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="" class="d-block">PDF</label>
                        <app-doc-uploader directory="exam-analysis" formControlName="pdf"></app-doc-uploader>

                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="">DESCRIPTION</label>
                        <app-editor formControlName="description"></app-editor>
                    </div>
                </div>


                <div class="col-6">
                    <div class="form-group">
                        <label for="">SORT ORDER</label>
                        <input type="text" appNumberOnly formControlName="sort_order" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="" class="d-block">STATUS</label>
                        <app-status formControlName="status"></app-status>
                    </div>
                </div><!--add-->
            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
    </div>
</ng-template>


<ng-template #bdrt let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Teachers BDRT</h5>
        <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="pageForm">
            <app-bdrt formControlName="bdrt"></app-bdrt>
            <input type="hidden" formControlName="id" class="form-control">
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="saveBdrt()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
    </div>
</ng-template>
