import {finalize} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../services/api.service';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {UntypedFormControl, UntypedFormGroup, NgModel} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export class BaseComponent {

    public pageForm: UntypedFormGroup;
    public pageId: number;

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public modal: NgbModal,
                public loader: NgxUiLoaderService) {
        this.pageForm = new UntypedFormGroup({
            id: new UntypedFormControl(),
            bdrt: new UntypedFormControl(),
        });
    }

    saveBdrt() {
        this.loader.start();
        this.api.post('admin/page/save', this.pageForm.value)
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.modal.dismissAll();
            });
    }

    loadPage() {
        this.loader.start();
        this.api.get('admin/page/' + this.pageId, {})
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.pageForm.patchValue(res);
            });
    }

}
