import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PhotoService} from '../../services/photo.service';

@Component({
   selector: 'app-photo-uploader',
   templateUrl: './photo-uploader.component.html',
   styleUrls: ['./photo-uploader.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => PhotoUploaderComponent),
         multi: true
      }
   ]
})
export class PhotoUploaderComponent implements OnInit, ControlValueAccessor {
   @Input() width = 200;
   @Input() height = 200;
   @Input() dir = 'misc';
   @Input() hideLabel = false;
   @Input() uploadUrl = 'admin/upload-image';
   @Input() completeUrl = false;
   public fieldValue;
   public path: string;
   public progress = 0;
   @Output() uploaded = new EventEmitter();
   public response;

   @ViewChild('file') fileElement;

   getPlaceholder() {
      const size = this.width + 'x' + this.height;
      return 'https://place-hold.it/' + size + '.png&text=' + size;
   }

   propagateChange = (_: any) => {
   };

   writeValue(obj: any): void {
      this.fieldValue = obj;

   }

   registerOnChange(fn: any): void {
      this.propagateChange = fn;
   }

   registerOnTouched(fn: any): void {
      // throw new Error('Method not implemented.');
   }

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public photoService: PhotoService,
               public loader: NgxUiLoaderService) {

   }

   ngOnInit() {
   }

   upload($event, finput) {
      const icon = $event.target.files[0];
      const formData = new FormData();
      formData.append('icon', icon);
      formData.set('dir', this.dir);

      this.api.upload(this.uploadUrl, formData).subscribe((res: any) => {
         if (res.status === 'progress') {
            this.progress = res.message;

         } else {
            if (res.files) {
               this.propagateChange(res.files.path);
               this.fieldValue = res.files.path;
               this.progress = 0;

               this.response = res;
               this.uploaded.emit({
                  path: res.files.path,
                  url: res.files.url
               });
               this.fileElement.nativeElement.value = '';
            }

         }
      });
   }

}
