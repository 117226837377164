import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, tap} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
   selector: 'app-user-picker',
   templateUrl: './user-picker.component.html',
   styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent implements OnInit, AfterViewInit {

   @ViewChild('searchInp') searchInp;
   @Input() debounce = 300;
   @Output() selected = new EventEmitter();
   public users = [];
   public loading = false;

   constructor(public api: ApiService, public activeModal: NgbActiveModal) {
   }

   ngOnInit(): void {
   }

   ngAfterViewInit(): void {
      fromEvent(this.searchInp.nativeElement, 'keyup')
         .pipe(
            filter(Boolean),
            debounceTime(this.debounce),
            distinctUntilChanged(),
            tap((text) => {
               const term = this.searchInp.nativeElement.value;
               this.searchUsers(term);
            })
         )
         .subscribe();
   }

   searchUsers(val) {
      this.loading = true;
      this.api.post('admin/users/search', {term: val})
         .pipe(finalize(() => this.loading = false))
         .subscribe((res: any) => {
            this.users = res;
         });
   }

   select(u: any) {
      this.selected.emit(u);
      this.activeModal.close(u);
   }
}
