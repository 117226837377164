<div class="page-title d-flex justify-content-between align-items-center">
   <h3>User Roles & Permissions</h3>

</div>
<div>
   <div class="row">
      <div class="col-sm-6">
         <table class="table table-bordered table-striped">
            <tr>
               <th>Id</th>
               <th>Role</th>
               <th></th>
            </tr>
            <tr *ngFor="let r of roles;let idx =index">
               <td>{{r.id}}</td>
               <td>{{r.name}}</td>
               <td>
                  <button class="btn btn-sm btn-primary" (click)="edit(r,idx)">Edit</button>
                  <button class="btn btn-sm btn-danger" (click)="delete(r,idx)">Delete</button>
               </td>
            </tr>
         </table>
      </div>
      <div class="col-sm-6">
         <form [formGroup]="form">

            <div class="form-group">
               <label for="">Name</label>
               <input class="form-control" type="text" formControlName="name">
            </div>
            <div class="form-group">
               <label for="">Permissions</label>
               <angular2-multiselect [data]="permissions" [settings]="{labelKey:'name'}" formControlName="permissions">
                  <ng-template let-item="item">
                     <label style="color: #333;min-width: 150px;">{{item.name}}</label>
                     {{item|json}}
                  </ng-template>
               </angular2-multiselect>
            </div>
            <div class="d-flex">
               <button *ngIf="form.value.id" class="btn btn-danger m-0 btn-block" (click)="form.reset()">Cancel Update</button>
               <button (click)="save()" [disabled]="form.invalid" class="btn m-0 btn-primary btn-block">{{form.value.id ? 'Update' : 'Add New Role'}}</button>

            </div>

         </form>

      </div>
   </div>

</div>
