import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
   selector: 'app-shipping-input',
   templateUrl: './shipping-input.component.html',
   styleUrls: ['./shipping-input.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => ShippingInputComponent),
         multi: true
      }
   ]
})
export class ShippingInputComponent implements ControlValueAccessor, OnInit, OnChanges {

   @Input() quantity;
   public value: any = {};
   public looper = [];
   propagateChange = (_: any) => {
   };

   constructor() {
      this.looper = Array.from(Array(this.quantity).keys());
    //  console.log(this.looper);
   }

   ngOnChanges(changes: SimpleChanges) {

      this.setupLooper(this.quantity);
   }

   registerOnChange(fn: any): void {
      this.propagateChange = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {
      if (obj) {
         this.value = obj;
         this.update();
      }
   }

   ngOnInit(): void {
      this.setupLooper(this.quantity);
   }

   setupLooper(len) {
      const n = +len;
      this.looper = [].concat(Array.from(Array(n).keys()));
   }

   update() {
      this.propagateChange(this.value);
     // console.log(this.value);
   }
}
