<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Book Coupons</h3>
   <div class="spaced-btns">
      <button class="btn btn-secondary" routerLink="/dashboard/manage/book-coupon-batches">Manage Batches</button>
      <button class="btn btn-info" (click)="showExportModal=true">Export</button>
      <button class="btn btn-primary" (click)="showAddNewModal=true">Add Bulk Coupons</button>
      <button class="btn btn-warning" (click)="showEditCouponModal=true">Add Single Coupon</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">

   <ng-template lt-cell-template field="batch" let-item let-idx="index">
      {{item.batch?.name}}
   </ng-template>
   <ng-template field="used" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.used ? 'Yes': 'No'"></app-badge>
   </ng-template>
   <ng-template lt-cell-template field="actions" let-item let-idx="index">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="edit(item,idx)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #detail let-modal>
   <div class="modal-header">
      <h5 class="modal-title">User Details</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="row">
         <div class="col-sm-8"><h2>Coupon Code: {{selectedItem.code}}</h2>
            <table class="table-sm table table-striped">
               <tbody>
               <tr>
                  <td>Name</td>
                  <td>{{selectedItem.name}}</td>
               </tr>
               <tr>
                  <td>Email</td>
                  <td>{{selectedItem?.user?.email}}</td>
               </tr>
               <tr>
                  <td>Phone</td>
                  <td>{{selectedItem?.user?.phone_no}}</td>
               </tr>
               <tr>
                  <td>Gender</td>
                  <td>{{selectedItem.gender}}</td>
               </tr>
               <tr>
                  <td>Category</td>
                  <td>{{selectedItem.category}}</td>
               </tr>
               <tr>
                  <td>Date Of Birth</td>
                  <td>{{selectedItem.dob}}</td>
               </tr>
               <tr>
                  <td>Father's Name</td>
                  <td>{{selectedItem.father_name}}</td>
               </tr>
               <tr>
                  <td>Graduate</td>
                  <td>{{selectedItem.graduate ? 'YES' : 'NO'}}</td>
               </tr>
               <tr>
                  <td>City</td>
                  <td>{{selectedItem.city}}</td>
               </tr>
               <tr>
                  <td>Pincode</td>
                  <td>{{selectedItem.pincode}}</td>
               </tr>


               </tbody>
            </table>
         </div>
         <div class="col-sm-4">
            <img class="img-fluid" src="{{photoService.resize(selectedItem.photo,400,400)}}" alt="">
         </div>
      </div>


   </div>

</ng-template>

<p-dialog header="Book Coupon" [(visible)]="showEditCouponModal" (onHide)="editForm.reset();selectedItem=false; showEditCouponModal=false">
   <form [formGroup]="editForm">
      <div class="form-group">
         <label for="">Coupon Code</label>
         <input [readOnly]="editForm.value.id" type="text" formControlName="code" class="form-control">
      </div>

      <div class="form-group">
         <label for="">Batch</label>
         <select class="form-control" formControlName="batch_id">
            <option *ngFor="let b of couponBatches" [value]="b.id">{{b.name}}</option>
         </select>
      </div>


      <div class="form-group">
         <label for="">Usage Limit</label>
         <input type="text" appNumberOnly formControlName="usage_limit" class="form-control">
         <small>no value means 1</small>
      </div>
   </form>
   <ng-template pTemplate="footer">
      <button class="btn btn-success" (click)="processEdit()">{{editForm.value.id ? 'Update' : 'Add'}} Coupon</button>
   </ng-template>

</p-dialog>
<p-dialog header="Add Coupons" [(visible)]="showAddNewModal">
   <form [formGroup]="form">
      <div class="form-group">
         <label for="">Number of Coupons</label>
         <input type="text" class="form-control" appNumberOnly formControlName="no_of_coupons">
      </div>
      <div class="form-group">
         <label for="">Batch</label>
         <select class="form-control" formControlName="batch_id">
            <option *ngFor="let b of couponBatches" [value]="b.id">{{b.name}}</option>
         </select>
      </div>
      <button [disabled]="form.invalid" (click)="save()" class="btn btn-success">Add Coupon</button>
   </form>
</p-dialog>
<p-dialog header="Export Coupons" [(visible)]="showExportModal">
   <form [formGroup]="exportForm">
      <div class="form-group">
         <label for="">Batch</label>
         <select class="form-control" formControlName="batch_id">
            <option *ngFor="let b of couponBatches" [value]="b.id">{{b.name}}</option>
         </select>
      </div>

      <div class="row">
         <div class="col-6">
            <label for="">From</label>
            <app-date-picker formControlName="from"></app-date-picker>
         </div>
         <div class="col-6">
            <label for="">To</label>
            <app-date-picker formControlName="to"></app-date-picker>
         </div>
      </div>


      <div class="form-group d-none">
         <label for="">Status</label>
         <p-selectButton [options]="[{label:'Used',value:'true'},{label:'Unused',value:null},{label:'All',value:'all'}]" formControlName="used"></p-selectButton>
      </div>

   </form>
   <ng-template pTemplate="footer">
      <button (click)="export()" pButton class="p-button-success" label="Export" [disabled]="exportForm.invalid"></button>
   </ng-template>
</p-dialog>
