<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage quick-links</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig"></xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} quick-links</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <div class="form-group mb-3"><label for="label">Label</label>
            <input type="text" formControlName="label" class="form-control" id="label">
         </div>
         <div class="form-group mb-3"><label for="link">Link</label>
            <input type="text" formControlName="link" class="form-control" id="link">
         </div>
         <div class="form-group mb-3"><label for="link">Order</label>
            <input type="text" formControlName="link" class="form-control" id="sort_order">
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>


