<div class="page-title d-flex justify-content-between align-items-center">
    <h3>Email Settings</h3>
    <div></div>
</div>

<form [formGroup]="form">

    <div formGroupName="concern">
        <div class="section">New Concern Email Settings</div>
        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable User Email</label>
                    <app-toggle-switch formControlName="user_email"></app-toggle-switch>

                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable Admin Email</label>
                    <app-toggle-switch formControlName="admin_email"></app-toggle-switch>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable Admin SMS</label>
                    <app-toggle-switch formControlName="admin_sms"></app-toggle-switch>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label>User SMS</label>
                    <app-toggle-switch formControlName="user_sms"></app-toggle-switch>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Receiving Email ID</label>
                    <input type="text" class="form-control" formControlName="get">
                </div>

            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Sending Email ID</label>
                    <input type="text" class="form-control" formControlName="send">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>User Email Subject</label>
                    <input type="text" formControlName="subject" class="form-control"/>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="form-group">
                    <label>Optional Message</label>
                    <input type="text" formControlName="message" class="form-control"/>
                </div>
            </div>
        </div>
    </div>
    <div formGroupName="callback">
        <div class="section">Request Callback Settings</div>
        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable User Email</label>
                    <app-toggle-switch formControlName="user_email"></app-toggle-switch>

                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable Admin Email</label>
                    <app-toggle-switch formControlName="admin_email"></app-toggle-switch>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Receiving Email ID</label>
                    <input type="text" class="form-control" formControlName="get">
                </div>

            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Sending Email ID</label>
                    <input type="text" class="form-control" formControlName="send">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>User Email Subject</label>
                    <input type="text" formControlName="subject" class="form-control"/>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="form-group">
                    <label>Optional Message</label>
                    <input type="text" formControlName="message" class="form-control"/>
                </div>
            </div>
        </div>
    </div>
    <div formGroupName="book_order">
        <div class="section">New Book Order Settings</div>
        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable User Email</label>
                    <app-toggle-switch formControlName="user_email"></app-toggle-switch>

                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Enable Admin Email</label>
                    <app-toggle-switch formControlName="admin_email"></app-toggle-switch>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Receiving Email ID</label>
                    <input type="text" class="form-control" formControlName="get">
                </div>

            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Sending Email ID</label>
                    <input type="text" class="form-control" formControlName="send">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>User Email Subject</label>
                    <input type="text" formControlName="subject" class="form-control"/>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="form-group">
                    <label>Optional Message</label>
                    <input type="text" formControlName="message" class="form-control"/>
                </div>
            </div>
        </div>
    </div>
    <div formGroupName="forgot_password">
        <div class="section">Forgot Password</div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Sending Email ID</label>
                    <input type="text" class="form-control" formControlName="send">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Subject</label>
                    <input type="text" formControlName="subject" class="form-control"/>
                </div>
            </div>
        </div>

    </div>
</form>

<button class="btn   mt-4 btn-success btn-lg" (click)="save()" style="position: fixed;right: 10px;bottom: 10px;z-index: 100;"><i class="fa fa-save mr-2"></i>Save Settings</button>
