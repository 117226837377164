import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {finalize} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
   selector: 'app-roles-and-permissions',
   templateUrl: './roles-and-permissions.component.html',
   styleUrls: ['./roles-and-permissions.component.scss']
})
export class RolesAndPermissionsComponent implements OnInit {

   public roles = [];
   public permissions = [];
   public form: UntypedFormGroup;
   public selectedItem;

   constructor(public api: ApiService, public loader: NgxUiLoaderService) {

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl('', Validators.required),
         permissions: new UntypedFormControl('')
      });

   }

   ngOnInit(): void {
      this.loadRolesAndPermissions();
   }

   save() {
      this.loader.start();
      this.api.post('admin/roles/save', this.form.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.form.reset();
            if (!this.selectedItem) {
               this.roles = this.roles.concat(res.data);
            } else {
               this.roles[this.selectedItem] = res.data;
            }
         });
   }

   loadRolesAndPermissions() {
      this.loader.start();
      this.api.post('admin/roles/list', {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.roles = res.roles;
            this.permissions = res.permissions;
         });
   }

   delete(role, index) {
      Swal.fire({
         title: 'Delete ' + role.name + ' role?',
         showCancelButton: true,
      }).then(res => {
         this.loader.start();
         this.api.post('admin/roles/delete', {id: role.id})
            .pipe(finalize(() => this.loader.stop()))
            .subscribe(() => {
               this.roles.splice(index, 1);
            });
      });
   }

   edit(r: any, idx: number) {
      this.selectedItem = idx;
      this.form.patchValue(r);
   }
}
