import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-previous-papers-stage-list',
    templateUrl: './previous-papers-stage-list.component.html',
    styleUrls: ['./previous-papers-stage-list.component.scss']
})
export class PreviousPapersStageListComponent implements OnInit {

    @Input() tree;
    @Output() editEvt = new EventEmitter();
    @Output() deleteEvt = new EventEmitter();

    constructor(public api: ApiService) {
    }

    ngOnInit() {
    }

    dropped(event, arr) {
        moveItemInArray(arr, event.previousIndex, event.currentIndex);

        const sortedList = [];
        this.tree[event.currentIndex].sort = event.currentIndex;
        this.tree.forEach((item, index) => {
            sortedList.push({id: item.id, sort: 1 + index, name: item.course_name});
        });
        this.api.post('admin/previous-papers-stage/sort', {sortedList}).subscribe(res => {

        });

    }

    edit(item, index) {
        this.editEvt.emit(item);
    }

    delete(item, index) {
        this.deleteEvt.emit(item);
    }
}
