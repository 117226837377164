<div class="p-4">
   <h2>Select User <a (click)="activeModal.close(null)"><i class="fa fa-times"></i></a></h2>
   <div class="position-relative">
      <input class="form-control" #searchInp placeholder="search users by name or email"/>
      <div *ngIf="loading" class="spinner-border spinner text-primary" role="status">
         <span class="sr-only">Loading...</span>
      </div>
   </div>
   <ul class="list-unstyled">
      <li *ngFor="let u of users" (click)="select(u)">
         <strong>{{u.name}}</strong>
         <small>{{u.email}}</small>
      </li>
   </ul>
</div>
