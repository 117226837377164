import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-current-affair-category',
   templateUrl: './manage-current-affair-category.component.html',
   styleUrls: ['./manage-current-affair-category.component.scss']
})
export class ManageCurrentAffairCategoryComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/current-affair-category/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Category Name', key: 'category_title', focus: true},
         {name: 'Sort', key: 'sort_order', type: 'sort'},
         {name: 'Status', key: 'status', type: 'status',},

      ],

   };
   public form: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({

         id: new UntypedFormControl('', Validators.compose([])),
         category_title: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl()

      });
   }

   ngOnInit() {
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew, {size: 'lg'});
   }

   save() {
      this.loader.start();
      this.api.post('admin/current-affair-category/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   delete(item: any, idx: any) {
      this.loader.start();
      this.api.post('admin/current-affair-category/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.table.removeItemAtIndex(idx);
         });
   }

   edit(item) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(this.addNewModal, {size: 'lg'});
   }
}
