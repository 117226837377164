import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Constants} from '../../Constants';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {of} from 'rxjs';

@Component({
    selector: 'app-manage-faq',
    templateUrl: './manage-faq.component.html',
    styleUrls: ['./manage-faq.component.scss']
})
export class ManageFaqComponent implements OnInit {
    @ViewChild('addNew') addNewModal;
    @ViewChild('table') table;
    public editorOptions = Constants.editorOptions;
    public selectedItem;
    public endpoint = environment.endpoint + 'admin/faq/list';
    public tableConfig: ILaravelDataTableConfig = {
        fields: [
            {
                name: 'ID',
                key: 'id',

                type: 'text',
                width: 'min'
            },
            {
                name: 'Question',
                key: 'question',
                type: 'text',

            },
            {
                name: 'Type',
                key: 'faq_type',
                type: 'select',
                filterKey: 'faq_type',
                selectOptions: of([{label: 'Website', value: 'website'}, {label: 'Test Series', value: 'test-series'}])

            },
            {
                name: 'Sort',
                key: 'sort_order',
                width: 'min',
                type: 'sort',
            },

        ],
        actions: [
            {
                name: 'Edit',
                type: 'primary',
                fn: (dataset, index) => {
                    this.selectedItem = dataset[index];
                    this.form.patchValue(this.selectedItem);
                    const modalRef = this.modal.open(this.addNewModal, {size: 'lg'});
                }
            },
            {
                name: 'Delete',
                type: 'danger',
                fn: (dataset, index) => {
                    swal.fire({
                        title: 'Delete :' + dataset[index].title + '?',
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                    }).then(res => {
                        if (res.value) {
                            this.loader.start();
                            this.api.post('admin/faq/delete', {id: dataset[index].id})
                                .pipe(finalize(() => {
                                    this.loader.stop();
                                }))
                                .subscribe((res: any) => {
                                    dataset.splice(index, 1);
                                });
                        }
                    });
                }
            }
        ],
        sortFn: (item) => {
            this.toastr.info('Updating..', null, {
                timeOut: 800
            });
            this.api.post('admin/faq/save', item)
                .pipe(finalize(() => {
                    this.loader.stop();
                }))
                .subscribe((res: any) => {
                    this.toastr.success('Sort Order Updated', null, {
                        timeOut: 800
                    });
                });
            return void (0);
        }

    };
    public form: UntypedFormGroup;

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public modal: NgbModal,
                public loader: NgxUiLoaderService) {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            question: new UntypedFormControl(),
            answer: new UntypedFormControl(),
            faq_type: new UntypedFormControl(),
            status: new UntypedFormControl(),
            sort_order: new UntypedFormControl(),
        });
    }

    ngOnInit() {
    }

    add(addNew: TemplateRef<any>) {
        this.selectedItem = null;
        this.form.reset({
            faq_type: 'website'
        });
        this.modal.open(addNew, {size: 'lg'});
    }

    save() {
        this.loader.start();
        this.api.post('admin/faq/save', this.form.value)
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.modal.dismissAll();
                this.table.loadData();
            });
    }
}
