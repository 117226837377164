import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoggedInGuard} from './guards/logged-in.guard';
import {LogoutGuard} from './guards/logout.guard';

import {StudentCourseManagerComponent} from './student/student-course-manager/student-course-manager.component';

import {ManageTestimonialsComponent} from './_admin/manage-testimonials/manage-testimonials.component';
import {ManageProgrammesComponent} from './_admin/manage-programmes/manage-programmes.component';
import {ManageTeacherComponent} from './_admin/manage-teacher/manage-teacher.component';
import {ManageFaqComponent} from './_admin/manage-faq/manage-faq.component';
import {ManagePageComponent} from './_admin/manage-page/manage-page.component';
import {ManageCouponComponent} from './_admin/manage-coupon/manage-coupon.component';
import {ManageOrderComponent} from './_admin/manage-order/manage-order.component';
import {ManagePublicationComponent} from './_admin/manage-publication/manage-publication.component';
import {ManageCommentComponent} from './_admin/manage-comment/manage-comment.component';
import {ManageCurrentAffairCategoryComponent} from './_admin/current-affairs/manage-current-affair-category/manage-current-affair-category.component';
import {ManageCurrentAffairComponent} from './_admin/current-affairs/manage-current-affair/manage-current-affair.component';
import {ManageNotificationComponent} from './_admin/manage-notification/manage-notification.component';
import {ManageGalleryComponent} from './_admin/manage-gallery/manage-gallery.component';
import {ManageSettingsComponent} from './_admin/manage-settings/manage-settings.component';
import {ManageHomepageComponent} from './_admin/manage-homepage/manage-homepage.component';
import {ManageBookOrderComponent} from './_admin/manage-book-order/manage-book-order.component';
import {ManageContactPageComponent} from './_admin/manage-contact-page/manage-contact-page.component';
import {ManageMainCoursesComponent} from './_admin/manage-main-courses/manage-main-courses.component';
import {ManageTopRankerComponent} from './_admin/manage-top-ranker/manage-top-ranker.component';
import {ManageSuggestedBookComponent} from './_admin/manage-suggested-book/manage-suggested-book.component';
import {ManageExamAnalysisComponent} from './_admin/manage-exam-analysis/manage-exam-analysis.component';
import {ManageExamNotificationComponent} from './_admin/manage-exam-notification/manage-exam-notification.component';
import {ManagePreviousPaperComponent} from './_admin/manage-previous-paper/manage-previous-paper.component';
import {ManageAboutExamComponent} from './_admin/manage-about-exam/manage-about-exam.component';
import {ManageSyllabusComponent} from './_admin/manage-syllabus/manage-syllabus.component';
import {ManageProgrammeKeyPointComponent} from './_admin/manage-programme-key-point/manage-programme-key-point.component';
import {ManageEmailConcernComponent} from './_admin/manage-email-concern/manage-email-concern.component';
import {ManageEnquiryCallbackComponent} from './_admin/manage-enquiry-callback/manage-enquiry-callback.component';
import {ManageEmailSettingComponent} from './_admin/manage-email-setting/manage-email-setting.component';
import {ManageExpertComponent} from './_admin/manage-experts/manage-expert.component';
import {ManageUserComponent} from './_admin/manage-user/manage-user.component';
import {ManageBookCouponComponent} from './_admin/manage-book-coupon/manage-book-coupon.component';
import {ManageQuestionReportsComponent} from './_admin/manage-question-reports/manage-question-reports.component';
import {ManageTestCouponModule} from './_admin/manage-test-coupon/manage-test-coupon.module';
import {ManageTestCouponComponent} from './_admin/manage-test-coupon/manage-test-coupon.component';
import {TestOrderModule} from './_admin/manage-test-order/test-order.module';
import {RolesAndPermissionsComponent} from './_admin/roles-and-permissions/roles-and-permissions/roles-and-permissions.component';
import {ManageBookCouponBatchComponent} from './_admin/manage-book-coupon-batch/manage-book-coupon-batch.component';
import {ManageBookCouponUserComponent} from './_admin/manage-book-coupon-user/manage-book-coupon-user.component';
import {TestListingModule} from './_admin/manage-tests-listing/test-listing.module';
import {CurrentAffairsRoutingModule} from './_admin/current-affairs/current-affairs-routing.module';
import {ManageTopRankerCategoryComponent} from './_admin/manage-top-ranker-category/manage-top-ranker-category.component';
import {ManageFreeResourceComponent} from './_admin/manage-free-resource-courses/manage-free-resource.component';
import {EnquiryFormComponent} from './_admin/enquiry-form/enquiry-form.component';
import {ManageQuickLinksComponent} from './_admin/manage-quick-links/manage-quick-links.component';
import {ManageInterestedProgramsComponent} from './_admin/manage-interested-programs/manage-interested-programs';
import {ManageContactFormsComponent} from './_admin/manage-contact-forms/manage-contact-forms.component';
import {ManageWhoWeAreComponent} from './_admin/manage-who-we-are/manage-who-we-are.component';
import {ManageNoticesComponent} from './_admin/manage-notices/manage-notices.component';
import {PaidContentComponent} from './_admin/paid-content/paid-content.component';
import {UpdatePasswordComponent} from './dashboard/update-password.component';

const routes: Routes = [
   {
      path: '',
      component: LoginComponent,
      canActivate: [LogoutGuard]
   },
   {
      path: 'dashboard',
      component: DashboardComponent,
      canActivate: [LoggedInGuard],
      children: [
         {path: 'update/password', component: UpdatePasswordComponent},
         {path: 'manage/paid-material', component: PaidContentComponent},
         {path: 'manage/contact-forms', component: ManageContactFormsComponent},
         {path: 'manage/quick-links', component: ManageQuickLinksComponent},
         {path: 'manage/interested-programs', component: ManageInterestedProgramsComponent},
         {path: 'manage/top-ranker-categories', component: ManageTopRankerCategoryComponent},
         {path: 'entry/enquiry-form', loadComponent: () => import('./_admin/enquiry-form/enquiry-form.component').then(m => m.EnquiryFormComponent)},
         {path: 'manage/users', component: ManageUserComponent},
         {path: 'manage/student-courses/:id', component: StudentCourseManagerComponent},
         {path: 'manage/contact-page', component: ManageContactPageComponent},
         {path: 'manage/courses', component: ManageMainCoursesComponent},
         {path: 'manage/free-resource-courses', component: ManageFreeResourceComponent},
         {path: 'manage/suggested-books', component: ManageSuggestedBookComponent},
         {path: 'top-ranker', component: ManageTopRankerComponent},
         {path: 'top-ranker-categories', component: ManageTopRankerCategoryComponent,},
         {path: 'manage/testimonials', component: ManageTestimonialsComponent},
         {path: 'manage/programs', component: ManageProgrammesComponent},
         {path: 'manage/notices', component: ManageNoticesComponent},
         {path: 'manage/who-we-are', component: ManageWhoWeAreComponent},
         {
            path: 'manage/group-test-series',
            component: ManageProgrammesComponent,
            data: {title: 'Manage Group Of Test', type: 'test', cat: 'group'}
         },
         {
            path: 'manage/individual-test-series',
            component: ManageProgrammesComponent,
            data: {title: 'Manage Individual Tests', type: 'test', cat: 'single'}
         },

         {path: 'manage/teacher', component: ManageTeacherComponent},
         {path: 'manage/experts', component: ManageExpertComponent},
         {path: 'manage/faq', component: ManageFaqComponent},
         {path: 'manage/page', component: ManagePageComponent},
         {path: 'manage/page/cat/:cat', component: ManagePageComponent},
         {path: 'manage/coupon', component: ManageCouponComponent},
         {path: 'manage/order', component: ManageOrderComponent},
         {path: 'manage/books', component: ManagePublicationComponent},
         {path: 'manage/comments/:type', component: ManageCommentComponent},

         {path: 'manage/notifications', component: ManageNotificationComponent},
         {path: 'manage/gallery', component: ManageGalleryComponent},
         {path: 'manage/settings', component: ManageSettingsComponent},
         {path: 'manage/homepage', component: ManageHomepageComponent},
         {path: 'manage/book-orders', component: ManageBookOrderComponent},
         {path: 'manage/exam-analysis', component: ManageExamAnalysisComponent},
         {path: 'manage/exam-notifications', component: ManageExamNotificationComponent},
         {path: 'manage/previous-papers', component: ManagePreviousPaperComponent},
         {path: 'manage/about-exam', component: ManageAboutExamComponent},
         {path: 'manage/syllabus', component: ManageSyllabusComponent},
         {path: 'manage/programme-key-points', component: ManageProgrammeKeyPointComponent},
         {path: 'manage/enquiry-concerns', component: ManageEmailConcernComponent},
         {path: 'manage/enquiry-callbacks', component: ManageEnquiryCallbackComponent},
         {path: 'manage/email-settings', component: ManageEmailSettingComponent},
         {path: 'manage/book-coupons', component: ManageBookCouponComponent},
         {path: 'manage/book-coupon-batches', component: ManageBookCouponBatchComponent},
         //
         {
            path: 'manage/test-series/allot-tests',
            loadChildren: () => import('./_admin/manage-tests-listing/test-listing.module').then(m => TestListingModule),
            data: {type: 'allot'}
         },
         {
            path: 'manage/tests',
            loadChildren: () => import('./_admin/manage-tests-listing/test-listing.module').then(m => TestListingModule)
         },
         {
            path: 'manage/current-affairs',
            loadChildren: () => import('./_admin/current-affairs/current-affairs.module').then(m => CurrentAffairsRoutingModule)
         },
         {
            path: 'manage/test-coupons',
            loadChildren: () => import('./_admin/manage-test-coupon/manage-test-coupon.module').then(m => ManageTestCouponModule),
            component: ManageTestCouponComponent
         },
         {
            path: 'manage/test-orders',
            loadChildren: () => import('./_admin/manage-test-order/test-order.module').then(m => TestOrderModule),

         },
         {
            path: 'manage/tests/:id/attempts',
            loadChildren: () => import('./_admin/manage-test-attempt/manage-test-attempt.module').then(m => m.TestAttemptModule)
         },
         {
            path: 'manage/question-reports',
            loadChildren: () => import('./_admin/manage-question-reports/manage-question-reports.module').then(m => m.ManageQuestionReportsModule),
            component: ManageQuestionReportsComponent
         },
         {
            path: 'manage/roles-and-permissions',
            loadChildren: () => import('./_admin/roles-and-permissions/roles-and-permissions.module').then(m => m.RolesAndPermissionsModule),
            component: RolesAndPermissionsComponent
         },
         {
            path: 'manage/book-coupon-users',
            loadChildren: () => import('./_admin/manage-book-coupon-user/manage-book-coupon-user.module').then(m => m.ManageBookCouponUserModule),
            component: ManageBookCouponUserComponent
         }

      ]
   },

];

@NgModule({
   imports: [RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
   })],
   exports: [RouterModule]
})
export class AppRoutingModule {
}
