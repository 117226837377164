import {Component, forwardRef, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-current-affair-category-selector',
    templateUrl: './current-affair-category-selector.component.html',
    styleUrls: ['./current-affair-category-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrentAffairCategorySelectorComponent),
            multi: true
        }
    ]
})
export class CurrentAffairCategorySelectorComponent implements OnInit, ControlValueAccessor {
    public categories = [];
    public value = [];
    public fn = (_any) => {
    };

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public loader: NgxUiLoaderService) {

    }

    ngOnInit() {
        this.api.get('admin/current-affairs/category', {}).subscribe((res: any) => {
            this.categories = res;
        });
    }

    registerOnChange(fn: any): void {
        this.fn = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        if (obj && Array.isArray(obj)) {
            this.value = obj;
        } else {
            this.value = [];
        }
    }

    change($event, id) {

        if (!$event.target.checked) {
          //  console.log('uncheked-' + id);
            this.value.splice(this.value.indexOf(id), 1);
        } else {
            this.value.push(id);
            this.value = [].concat(this.value);
        }

        this.fn(this.value);
    }
}
