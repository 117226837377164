import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {Constants} from '../../Constants';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-book-coupon-user',
   templateUrl: './manage-book-coupon-user.component.html',
   styleUrls: ['./manage-book-coupon-user.component.scss']
})
export class ManageBookCouponUserComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public baseEndpoint = environment.endpoint + 'admin/book-coupon-user/list';
   public endpoint = this.baseEndpoint;
   public editCouponUserDialogVisible = false;
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'Code', key: 'code', width: 'min'},
         {name: 'Name', key: 'name', filterKey: '#book_coupon_users.name', focus: true},
         {name: 'Father', key: 'father_name', filterKey: '#book_coupon_users.father_name'},
         {
            name: 'Graduate', key: 'graduate', width: 'min', filterKey: '#book_coupon_users.graduate', type: 'select',
            selectOptions: of([
               {label: 'Yes', value: 1},
               {label: 'No', value: 0}
            ])
         },
         {
            name: 'Category', key: 'category', filterKey: '#book_coupon_users.category',
            type: 'select', selectOptions: of(Constants.category)
         },
         {name: 'City', key: 'city', filterKey: '#book_coupon_users.city'},
         {name: 'Used On', key: 'created_at', type: 'dateIST'},
      ]
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public showExportModal = false;
   public showViewModal = false;
   public constants = Constants;
   public exportForm: UntypedFormGroup;
   public couponBatches: any = [];
   public batchFilter = 0;
   public viewFields = [
      {label: 'Code', value: 'code',},
      {label: 'Name', value: 'name',},
      {label: 'Father Name', value: 'father_name',},
      {label: 'Gender', value: 'gender',},
      {label: 'Date Of Birth', value: 'dob',},
      {label: 'Graduate', value: 'graduate',},
      {label: 'Category', value: 'category',},
      {label: 'City', value: 'city',},
      {label: 'Pincode', value: 'pincode'},

   ];
   public editForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
      });
      this.exportForm = new UntypedFormGroup({
         batch_id: new UntypedFormControl('', Validators.required),
         from: new UntypedFormControl(),
         to: new UntypedFormControl()
      });
      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
      });
      this.loadCouponBatches();
      this.editForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         email: new UntypedFormControl(),
         city: new UntypedFormControl(),
         father_name: new UntypedFormControl(),
         code: new UntypedFormControl(),
         dob: new UntypedFormControl(),
         gender: new UntypedFormControl(),
         category: new UntypedFormControl(),
         pincode: new UntypedFormControl(),
         graduate: new UntypedFormControl(),
         photo: new UntypedFormControl(),
      })
      ;
   }

   loadCouponBatches() {
      this.loader.start();
      this.api.post('admin/book-coupon-batch/dropdown', {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.couponBatches = res;
         });
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/book-coupon-user/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   delete(item: any, idx: any) {
      this.loader.start();
      this.api.post('admin/book-coupon-user/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.table.removeItemAtIndex(idx);
         });
   }

   export() {
      this.loader.start();
      this.api.post('admin/book-coupon-user/export', this.exportForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }

   filterByBatch() {
      this.endpoint = this.baseEndpoint + '?id=' + this.batchFilter;
   }

   editItem(item: any) {
      this.selectedItem = item;
      this.editForm.patchValue(item);
      this.editCouponUserDialogVisible = true;
   }

   update() {
      this.loader.start();
      this.api.post('admin/book-coupon-user/save', this.editForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.editForm.reset();
            this.editCouponUserDialogVisible = false;
            this.table.loadData();
         });
   }
}
