import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Inject, Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './user-management/user-management.component';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { TokenInterceptor } from './interceptors/token-interceptor.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from './add-user/add-user.component';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ShowInArrayDirective } from './directives/show-in-array.directive';
import { StudentCourseManagerComponent } from './student/student-course-manager/student-course-manager.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { ManageCourseItemsComponent } from './modals/manage-course-items/manage-course-items.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { IconUploaderComponent } from './_shared/icon-uploader/icon-uploader.component';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';

import { AddNewTopRankerComponent } from './modals/add-new-top-ranker/add-new-top-ranker.component';
import { CustomDateParserFormatter } from './services/custom-date-parser-formatter.service';
import { CustomAdapter } from './services/custom-adapter.service';
import { NoItemsComponent } from './_shared/no-items/no-items.component';
import { ManageTestimonialsComponent } from './_admin/manage-testimonials/manage-testimonials.component';
import { ManageProgrammesComponent } from './_admin/manage-programmes/manage-programmes.component';
import { ManageAdsComponent } from './_admin/manage-ads/manage-ads.component';
import { ManageTeacherComponent } from './_admin/manage-teacher/manage-teacher.component';
import { NumberOnlyDirective } from './directives/number-only-directive.directive';
import { ManageFaqComponent } from './_admin/manage-faq/manage-faq.component';
import { ManagePageComponent } from './_admin/manage-page/manage-page.component';
import { ManageCouponComponent } from './_admin/manage-coupon/manage-coupon.component';
import { ManageOrderComponent } from './_admin/manage-order/manage-order.component';
import { ManagePublicationComponent } from './_admin/manage-publication/manage-publication.component';
import { ManageCommentComponent } from './_admin/manage-comment/manage-comment.component';
import { DOCUMENT } from '@angular/common';

import { environment } from '../environments/environment';
import { ShippingInputComponent } from './_shared/shipping-input/shipping-input.component';
import { MultiPicUploaderComponent } from './_shared/multi-pic-uploader/multi-pic-uploader.component';
import { CourseTreeSelectionComponent } from './_shared/course-tree-selection/course-tree-selection.component';
import { ManageNotificationComponent } from './_admin/manage-notification/manage-notification.component';
import { ManageGalleryComponent } from './_admin/manage-gallery/manage-gallery.component';
import { ManageSettingsComponent } from './_admin/manage-settings/manage-settings.component';
import { ManageHomepageComponent } from './_admin/manage-homepage/manage-homepage.component';
import { ManageBookOrderComponent } from './_admin/manage-book-order/manage-book-order.component';
import { OrderDetailComponent } from './_shared/order-detail/order-detail.component';
import { ManageContactPageComponent } from './_admin/manage-contact-page/manage-contact-page.component';
import { BdrtComponent } from './nook-ui/bdrt/bdrt.component';
import { ManageMainCoursesComponent } from './_admin/manage-main-courses/manage-main-courses.component';
import { MainCourseNodeComponent } from './_shared/main-course-node/main-course-node.component';
import { BdrtStandaloneComponent } from './_shared/bdrt-standalone/bdrt-standalone.component';
import { ManageTopRankerComponent } from './_admin/manage-top-ranker/manage-top-ranker.component';
import { XsLaravelDataTableModule } from '../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.module';
import { ManageSuggestedBookComponent } from './_admin/manage-suggested-book/manage-suggested-book.component';
import { MainCategorySelectorComponent } from './_shared/main-category-selector/main-category-selector.component';
import { ManageExamAnalysisComponent } from './_admin/manage-exam-analysis/manage-exam-analysis.component';
import { ManageExamNotificationComponent } from './_admin/manage-exam-notification/manage-exam-notification.component';
import { ManagePreviousPaperComponent } from './_admin/manage-previous-paper/manage-previous-paper.component';
import { ManagePreviousPapersStageComponent } from './_admin/manage-previous-papers-stage/manage-previous-papers-stage.component';
import { PaperStageSelectorComponent } from './_shared/paper-stage-selector/paper-stage-selector.component';
import { PreviousPapersStageListComponent } from './_shared/previous-papers-stage-list/previous-papers-stage-list.component';
import { ManageAboutExamComponent } from './_admin/manage-about-exam/manage-about-exam.component';
import { ManageSyllabusComponent } from './_admin/manage-syllabus/manage-syllabus.component';
import { ManageProgrammeKeyPointComponent } from './_admin/manage-programme-key-point/manage-programme-key-point.component';
import { ManageEmailConcernComponent } from './_admin/manage-email-concern/manage-email-concern.component';
import { ManageEnquiryCallbackComponent } from './_admin/manage-enquiry-callback/manage-enquiry-callback.component';
import { ManageEmailSettingComponent } from './_admin/manage-email-setting/manage-email-setting.component';
import { ManageExpertComponent } from './_admin/manage-experts/manage-expert.component';
import { EnrollmentOrderDetailComponent } from './_shared/enrollment-order-detail/enrollment-order-detail.component';
import { PrettyJsonPipe } from './pretty-json.pipe';
import { ManageUserComponent } from './_admin/manage-user/manage-user.component';
import { ProgramCategorySelectorComponent } from './_shared/program-category-selector/program-category-selector.component';
import { ProgramPickerComponent } from './_shared/programm-picker/program-picker.component';
import { ApplicationEditorComponent } from './_shared/application-editor/application-editor.component';
import { UserPickerComponent } from './_shared/user-picker/user-picker.component';
import { ManageTestComponent } from './_admin/manage-test/manage-test.component';
import { CheckboxGroupModule } from '../../projects/checkbox-group/src/lib/checkbox-group.module';
import { MarkingControlComponent } from './nook-ui/marking-control/marking-control.component';
import { SafeHtmlPipePipe } from './safe-html-pipe.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NookUiModule } from './nook-ui/nook-ui.module';
import { ManageBookCouponComponent } from './_admin/manage-book-coupon/manage-book-coupon.component';
import { DownloadConfigComponent } from './_modals/download-config/download-config.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NoticeCountComponent } from './notice-count/notice-count.component';
import { PermissionCheckDirective } from './permission-check.directive';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { ManageBookCouponBatchComponent } from './_admin/manage-book-coupon-batch/manage-book-coupon-batch.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { TooltipModule } from 'primeng/tooltip';
import { ManageTopRankerCategoryComponent } from './_admin/manage-top-ranker-category/manage-top-ranker-category.component';
import { ManageFreeResourceComponent } from './_admin/manage-free-resource-courses/manage-free-resource.component';
import { ManageQuickLinksComponent } from './_admin/manage-quick-links/manage-quick-links.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AuthService } from './services/auth.service';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ManageInterestedProgramsComponent } from './_admin/manage-interested-programs/manage-interested-programs';
import { ManageTopRankerCategoriesComponent } from './_admin/manage-top-ranker-categories/manage-top-ranker-categories';
import { ManageContactFormsComponent } from './_admin/manage-contact-forms/manage-contact-forms.component';
import { ManageWhoWeAreComponent } from './_admin/manage-who-we-are/manage-who-we-are.component';
import { ManageNoticesComponent } from './_admin/manage-notices/manage-notices.component';
import { TabViewModule } from 'primeng/tabview';
import { NoticeTargetsComponent } from './_admin/manage-notices/notice-targets/notice-targets.component';
import { PaginatorModule } from 'primeng/paginator';
import { PaidContentComponent } from './_admin/paid-content/paid-content.component';
import { NgxErrorsModule } from '@ngspot/ngx-errors';

const providers: any[] = [
   { provide: NgbDateAdapter, useClass: CustomAdapter },
   { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
   { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];

@NgModule({
   declarations: [
      AppComponent,
      LoginComponent,
      DashboardComponent,
      UserManagementComponent,
      AddUserComponent,
      FormErrorsComponent,
      StudentCourseManagerComponent,
      ShowInArrayDirective,
      CalculatorComponent,
      ManageCourseItemsComponent,
      FileUploaderComponent,
      IconUploaderComponent,
      ManageTopRankerComponent,
      AddNewTopRankerComponent,
      NoItemsComponent,
      ManageTestimonialsComponent,
      ManageProgrammesComponent,
      ManageAdsComponent,
      ManageTeacherComponent,
      ManageExpertComponent,
      NumberOnlyDirective,
      ManageFaqComponent,
      ManagePageComponent,
      ManageCouponComponent,
      ManageOrderComponent,
      ManagePublicationComponent,
      ManageCommentComponent,
      ShippingInputComponent,
      MultiPicUploaderComponent,
      CourseTreeSelectionComponent,
      ManageNotificationComponent,
      ManageGalleryComponent,
      ManageSettingsComponent,
      ManageHomepageComponent,
      ManageBookOrderComponent,
      OrderDetailComponent,
      ManageContactPageComponent,
      ManageMainCoursesComponent,
      MainCourseNodeComponent,
      BdrtStandaloneComponent,
      ManageSuggestedBookComponent,
      MainCategorySelectorComponent,
      ManageExamAnalysisComponent,
      ManageExamNotificationComponent,
      ManagePreviousPaperComponent,
      ManagePreviousPapersStageComponent,
      PaperStageSelectorComponent,
      PreviousPapersStageListComponent,
      ManageAboutExamComponent,
      ManageSyllabusComponent,
      ManageProgrammeKeyPointComponent,
      ManageEmailConcernComponent,
      ManageEnquiryCallbackComponent,
      ManageEmailSettingComponent,
      EnrollmentOrderDetailComponent,
      ManageUserComponent,
      PrettyJsonPipe,
      ProgramCategorySelectorComponent,
      ProgramPickerComponent,
      ApplicationEditorComponent,
      UserPickerComponent,
      ManageTestComponent,
      SafeHtmlPipePipe,
      ManageBookCouponComponent,
      DownloadConfigComponent,
      NoticeCountComponent,
      PermissionCheckDirective,
      ManageBookCouponBatchComponent,
      ManageTopRankerCategoryComponent,
      ManageFreeResourceComponent,
      ManageQuickLinksComponent,
      ManageInterestedProgramsComponent,
      ManageTopRankerCategoriesComponent,
      ManageContactFormsComponent,
      ManageWhoWeAreComponent,
      ManageNoticesComponent,
      NoticeTargetsComponent,
      PaidContentComponent,
   ],

   imports: [
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      FormsModule,
      HttpClientModule,
      ToastrModule.forRoot(),
      BrowserAnimationsModule,
      NgxUiLoaderModule,
      NgbModule,
      SweetAlert2Module.forRoot(),
      DragDropModule,
      FroalaEditorModule.forRoot(),
      FroalaViewModule.forRoot(),
      NgxErrorsModule,
      XsLaravelDataTableModule,
      CheckboxGroupModule,
      InfiniteScrollModule,
      NookUiModule,
      AngularMultiSelectModule,
      OverlayPanelModule,
      ButtonModule,
      SharedModule,
      DialogModule,
      CalendarModule,
      ConfirmationPopoverModule.forRoot({
         popoverTitle: 'Confirm',
         placement: 'left',
         popoverMessage: 'Are you sure?',//
         confirmButtonType: 'danger', // set defaults here
      }),
      MenuModule,
      MultiSelectModule,
      AccordionModule,
      CheckboxModule,
      AutoCompleteModule,
      SelectButtonModule,
      SidebarModule,
      TooltipModule,
      KeyFilterModule,
      NgxWebstorageModule.forRoot(),
      ErrorTailorModule.forRoot({
         errors: {
            useValue: {
               required: 'This field is required',
               minlength: ({ requiredLength, actualLength }) =>
                  `Expect ${requiredLength} but got ${actualLength}`,
               invalidAddress: error => `Address isn't valid`
            }
         }
      }),
      TabViewModule,
      PaginatorModule
   ],
   providers,
   exports: [
      NumberOnlyDirective,
      EnrollmentOrderDetailComponent,
      BdrtComponent,
      SafeHtmlPipePipe,
      MarkingControlComponent,
      FormErrorsComponent,
   ],
   bootstrap: [AppComponent]
})

export class AppModule {
   constructor(config: NgbModalConfig, private modalService: NgbModal,
               @Inject(DOCUMENT) private document: Document
   ) {
      // customize default values of modals used by this component tree
      //this is et
      config.backdrop = 'static';
      config.keyboard = false;
      config.size = 'xl';
      config.scrollable = true;
      config.backdrop = 'static';

      if (environment.production) {
         document.body.classList.add('xs-prod');
      }
   }

}
