<div class="wrapper">

   <div (click)="file.click()">
      <button type="button" class="btn btn-block btn-primary" *ngIf="!fieldValue">Upload <span *ngIf="!hideLabel">{{width}} x {{height}}</span></button>
      <app-cdn-image [src]="fieldValue" [width]="300"></app-cdn-image>
      <div class="progress" *ngIf="progress" [style.width.%]="progress"></div>
      <div class="d-flex action-bar">
         <a *ngIf="fieldValue" class="change">Change Image ({{width}}x{{height}})</a>
         <a *ngIf="fieldValue" class="clear-btn" (click)="fieldValue=null; fieldValue=null;propagateChange(null)">X</a>
      </div>

   </div>
</div>
<input type="file" #file (change)="upload($event,file)" accept=".jpg,.jpeg,.png" class="d-none">
