<div class="wrap">
    <ul cdkDropList (cdkDropListDropped)="dropped($event,tree)">

        <li *ngFor="let item of tree;let idx=index" cdkDrag>
            <div *cdkDragPlaceholder class="placeholder"></div>
            <div *cdkDragPreview class="preview">{{item.name}}</div>


            <div class="d-flex item align-items-center justify-content-between">
                <div>
                    <i class="fa fa-sort"></i>
                    <span>{{item.name}}</span>
                </div>
                <div class="actions">
                    <button class="btn btn-sm btn-secondary mr-2" (click)="edit(item,idx)">Edit</button>
                    <button class="btn btn-sm btn-danger" (click)="delete(item,idx)">Delete</button>
                </div>
            </div>
            <app-previous-papers-stage-list
                    (editEvt)="editEvt.emit($event)"
                    (deleteEvt)="deleteEvt.emit($event)"
                    [tree]="item.childs"></app-previous-papers-stage-list>

        </li>

    </ul>
</div>
