import {ChangeDetectorRef, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import * as lo from 'lodash';

@Injectable({
   providedIn: 'root'
})
export class NoticeCountService {
   public noticeCount = {
      concern: 0,
      callback: 0
   };

   constructor(public api: ApiService) {
      this.getNotice();
      setInterval(() => {
         this.getNotice();
      }, 60 * 1000);
   }

   getNotice() {
      this.api.post('admin/notice-count', {})
         .subscribe((res: any) => {
            lo.assign(this.noticeCount, res);
         });
   }

   clear(type) {
      this.api.post('admin/settings/save', {key: 'admin.notice.' + type, value: 0, silent: true}).subscribe();
      this.getNotice();
   }
}
