<form [formGroup]="form" (keydown.end)="$event.preventDefault()" (submit)="save()">

   <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel title="Social Media Links">
         <ng-template ngbPanelContent>


            <div formGroupName="social">
               <div class="form-group">
                  <label>Facebook</label>
                  <input type="text" class="form-control" formControlName="facebook">
               </div>
               <div class="form-group">
                  <label>Twitter</label>
                  <input type="text" class="form-control" formControlName="twitter">

               </div>
               <div class="form-group">
                  <label>Instagram</label>
                  <input type="text" class="form-control" formControlName="instagram">
               </div>
               <div class="form-group">
                  <label>Youtube</label>
                  <input type="text" class="form-control" formControlName="youtube">
               </div>
               <div class="form-group">
                  <label>Linkedin</label>
                  <input type="text" class="form-control" formControlName="linkedin">
               </div>
               <div class="form-group">
                  <label>Whatsapp</label>
                  <input type="text" class="form-control" formControlName="whatsapp">
               </div>
               <div class="form-group">
                  <label>Telegram</label>
                  <input type="text" class="form-control" formControlName="telegram">
               </div>
            </div>

         </ng-template>
      </ngb-panel>
      <ngb-panel title="Contact Info">

         <ng-template ngbPanelContent>

            <div formGroupName="contact">
               <div class="form-group">
                  <label>Address</label>
                  <app-editor formControlName="address"></app-editor>
               </div>
               <div class="form-group">
                  <label>Phone</label>
                  <input type="text" class="form-control" formControlName="phone">

               </div>
               <div class="form-group">
                  <label>Email</label>
                  <input type="text" class="form-control" formControlName="email">
               </div>

            </div>
         </ng-template>
      </ngb-panel>
      <ngb-panel title="Misc Settings">
         <ng-template ngbPanelContent>
            <div class="form-group">
               <label>Copyright Text</label>
               <input type="text" class="form-control" formControlName="copyright">
            </div>
         </ng-template>
      </ngb-panel>
      <ngb-panel title="Test Series Payment Gateways">
         <ng-template ngbPanelContent>

            <div formGroupName="test_gateways">
               <div class="form-group">
                  <label>Offline Mode</label>
                  <app-toggle-switch formControlName="offline"></app-toggle-switch>
               </div>
               <div class="form-group">
                  <label>Online Mode</label>
                  <app-toggle-switch formControlName="online"></app-toggle-switch>
               </div>
            </div>
         </ng-template>
      </ngb-panel>
      <ngb-panel title="Tax Settings">
         <ng-template ngbPanelContent>
            <div formGroupName="tax">
               <div class="form-group">
                  <label>Tax Percentage For Programs/Test Series</label>
                  <input type="text" appNumberOnly class="form-control" formControlName="programs">
               </div>
            </div>
         </ng-template>
      </ngb-panel>
   </ngb-accordion>
   <button class="btn btn-lg btn-block btn-success">Save Settings</button>

</form>
