import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NoticeCountService} from '../../services/notice-count.service';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import * as lo from 'lodash';

@Component({
   selector: 'app-manage-email-concern',
   templateUrl: './manage-email-concern.component.html',
   styleUrls: ['./manage-email-concern.component.scss']
})
export class ManageEmailConcernComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('detail') detailModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public message;
   public endpoint = environment.endpoint + 'admin/email-concern/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'user_name', type: 'text', focus: true},
         {name: 'Email', key: 'user_email', type: 'text'},
         {name: 'Phone', key: 'user_phone_no', type: 'text', width: 'min'},
         {name: 'Date', key: 'created_at', type: 'dateIST', width: 'min'},
         {name: 'Status', key: 'status', width: 'min'},

      ]
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public data;
   public displayExport: boolean;
   public exportTo: any;
   public exportFrom: any;
   public exportForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public noticeCountService: NoticeCountService,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
      });
      this.exportForm = new UntypedFormGroup({
         from: new UntypedFormControl(),
         to: new UntypedFormControl(),
         type: new UntypedFormControl('all')
      });
   }

   ngOnInit() {
      this.api.post('admin/email-concern/list', {})
         .subscribe((res: any) => {
            this.data = {
               data: res.data,
               total: res.total
            };
         });

      this.noticeCountService.clear('concern');
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/email-concern/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   markAsRead(item, unread = false) {
      this.loader.startBackground();
      this.api.post('admin/email-concern/mark-as-read', {id: item.id, unread})
         .pipe(finalize(() => this.loader.stopBackground()))
         .subscribe((res: any) => {
            lo.assign(item, {
               read_at: res.data.read_at,
               status: res.data.status,
            });
         });
   }

   delete(item: any, index) {

      this.loader.start();
      this.api.post('admin/email-concern/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(index);

         });

   }

   view(item: any) {
      this.selectedItem = item;
      // if (item.read_at === null) this.markAsRead(item);
      this.modal.open(this.detailModal);
   }

   export() {
      this.loader.start();
      this.api.post('admin/email-concern/export', this.exportForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }

   sendEmail(item) {
      this.loader.start();
      this.api.post('admin/email-concern/reply', {message: this.message, id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            lo.assign(item, {
               read_at: res.data.read_at,
               status: res.data.status,
            });
         });
   }
}


