<form [formGroup]="form">
    <div class="form-group">
        <label>Title</label>
        <input type="text" class="form-control" formControlName="title">
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label>Descriptor</label>
                <input type="text" class="form-control" formControlName="descriptor">
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>Read More Url</label>
                <input type="text" class="form-control" formControlName="descriptor_link">
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>Layout</label>
                <select class="form-control" formControlName="layout">
                    <option value="half">Half Banner</option>
                    <option value="full">Full Banner</option>
                </select>
            </div>
        </div>
    </div>


    <div class="form-group" *ngIf="!hideContent">
        <label class="d-block">TEXT</label>
        <app-editor formControlName="content"></app-editor>
    </div>
    <label class="d-block">Banner Image</label>
    <app-photo-uploader formControlName="banner" dir="bdrt" width="1920" [height]="form.value.layout=='full'?1080 : 700"></app-photo-uploader>
</form>

