import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { PhotoUploaderComponent } from './photo-uploader/photo-uploader.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
   NgbDateAdapter,
   NgbDateParserFormatter,
   NgbDatepickerModule,
   NgbTimepicker,
   NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter } from '../services/custom-adapter.service';
import { CustomDateParserFormatter } from '../services/custom-date-parser-formatter.service';
import { SizeDetectorComponent } from './size-detector/size-detector.component';
import { SafeHtmlPipePipe } from './pipes/safe-html-pipe';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { JwtLoginComponent } from './jwt-login/jwt-login.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { EnrollmentDetailViewComponent } from './enrollment-detail-view/enrollment-detail-view.component';
import { AnswerCollapseDirective } from './answer-collapse.directive';
import { NumberOnlyDirective } from './directives/number-only-directive.directive';
import { StatusComponent } from './status/status.component';
import { NoItemsComponent } from './no-items/no-items.component';
import { PrimeDateTimePickerComponent } from './prime-date-time-picker/prime-date-time-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { InArrayPipe } from './pipes/in-array.pipe';
import { EditorComponent } from './editor/editor.component';
import { MarkingControlComponent } from './marking-control/marking-control.component';
import { DocUploaderComponent } from './doc-uploader/icon-uploader.component';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { NoDataComponent } from './no-data/no-data.component';
import { BadgeComponent } from './badge/badge.component';
import { CdnImageComponent } from './cdn-image/cdn-image.component';
import { UserMiniDetailsComponent } from './user-mini-details/user-mini-details.component';
import { BdrtComponent } from './bdrt/bdrt.component';
import {SelectButtonModule} from 'primeng/selectbutton';

@NgModule({
   declarations: [
      DatePickerComponent,
      PhotoUploaderComponent,
      ToggleSwitchComponent,
      SizeDetectorComponent,
      SafeHtmlPipePipe,
      CountdownTimerComponent,
      JwtLoginComponent,
      SpinnerComponent,
      EnrollmentDetailViewComponent,
      AnswerCollapseDirective,
      NumberOnlyDirective,
      StatusComponent,
      NoItemsComponent,
      PrimeDateTimePickerComponent,
      InArrayPipe,
      EditorComponent,
      MarkingControlComponent,
      DocUploaderComponent,
      NoDataComponent,
      BadgeComponent,
      CdnImageComponent,
      UserMiniDetailsComponent,
      BdrtComponent
   ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        CalendarModule,
        FroalaEditorModule.forRoot(),
        SelectButtonModule,

    ],
   providers: [
      { provide: NgbDateAdapter, useClass: CustomAdapter },
      { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
   ],
   exports: [
      CommonModule,
      ReactiveFormsModule,
      DatePickerComponent,
      PhotoUploaderComponent,
      ToggleSwitchComponent,
      SizeDetectorComponent,
      SafeHtmlPipePipe,
      CountdownTimerComponent,
      JwtLoginComponent,
      SpinnerComponent,
      EnrollmentDetailViewComponent,
      AnswerCollapseDirective,
      NumberOnlyDirective,
      StatusComponent,
      NoItemsComponent,
      PrimeDateTimePickerComponent,
      EditorComponent,
      MarkingControlComponent,
      DocUploaderComponent,
      NoDataComponent,
      BadgeComponent,
      CdnImageComponent,
      UserMiniDetailsComponent,
      BdrtComponent,

   ]
})
export class NookUiModule {
}
