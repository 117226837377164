<p-tabView>
   <p-tabPanel header="Queries">
      <div *ngFor="let ip of interestedPrograms$|async" class="py-2">
         <p-checkbox (onChange)="sendValue()" name="groupname" [label]="ip.name" [value]="ip.id" [(ngModel)]="config.queries"></p-checkbox>
      </div>
   </p-tabPanel>
   <p-tabPanel header="Enrolled In Programs">
      <div class="tw-grid tw-grid-cols-2 tw-gap-1">
         <div *ngFor="let ip of program$|async" class="w-[50%] py-2">
            <div class="tw-flex tw-items-center">
               <p-checkbox (onChange)="sendValue()" name="groupname" [value]="ip.id" [(ngModel)]="config.programs"></p-checkbox>
               <div class="tw-ml-4">{{ip.name}}
                  <small class="tw-block">{{ip.batch_code}}</small>
               </div>
            </div>
         </div>
      </div>
   </p-tabPanel>
   <p-tabPanel header="Specific Users">
      <small>Comma separated emails or user ids or phone number of users </small>
      <textarea class="form-control" [(ngModel)]="config.users" (ngModelChange)="sendValue()"></textarea>
   </p-tabPanel>
   <p-tabPanel header="Test Series">
      <div class="tw-grid tw-grid-cols-2 tw-gap-1">
         <div *ngFor="let ip of testSeriesProgram$|async" class="w-[50%] py-2">
            <div class="tw-flex tw-items-center">
               <p-checkbox (onChange)="sendValue()" name="groupname" [value]="ip.id" [(ngModel)]="config.testSeries"></p-checkbox>
               <div class="tw-ml-4">{{ip.name}}
                  <small class="tw-block">{{ip.batch_code}}</small>
               </div>
            </div>
         </div>
      </div>
   </p-tabPanel>
</p-tabView>
