<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage {{type}} Comments</h3>
   <div>
      <!--        <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>-->
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="user.name" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex">
         <app-cdn-image class="mr-2" [src]="item.user.profile_image"></app-cdn-image>
         <div>
            {{item.user.name}}
            <small class="d-block">{{item.user.email}}</small>
         </div>
      </div>

   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
   <ng-template field="comment" let-idx="index" lt-cell-template="" let-item="">
      {{item.comment|slice:0:100}}
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item>
      <div class="spaced-btns">
         <ng-container *ngIf="item.status=='pending'">
            <button class="btn btn-sm btn-success" (click)="edit(item)">Approve</button>
            <button class="btn btn-sm btn-warning" (click)="reject(item)">Reject</button>
         </ng-container>

         <button class="btn btn-sm btn-primary" (click)="edit(item)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Comment</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form"><input type="hidden" formControlName="id" class="form-control">
         <div class="row">

            <div class="col-sm-12" *ngIf="selectedItem">
               <label class="d-block">Author : {{selectedItem.user.name}}</label>
               <label class="d-block">EMail : {{selectedItem.user.email}}</label>
               <label class="d-block">Phone : {{selectedItem.user.phone_no}}</label>

            </div>

            <div class="col-12 mt-4">
               <div class="form-group">
                  <label>COMMENT</label>
                  <textarea type="text" formControlName="comment" class="form-control"></textarea>
               </div>
            </div>


            <div class="col-6">
               <div class="form-group">
                  <label>RATING</label>
                  <input type="text" formControlName="rating" class="form-control">
               </div>
            </div>

            <div class="col-6">
               <div class="form-group">
                  <label class="d-block">STATUS</label>
                  <app-status formControlName="status" [statusList]="['Pending','Publish','Reject']"></app-status>

               </div>
            </div>
            <div class="col-12 mt-4" *ngIf="form.value.status=='publish'">
               <div class="form-group">
                  <label>ADMIN COMMENT</label>
                  <textarea type="text" formControlName="admin_comment" class="form-control"></textarea>
               </div>
            </div>

         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
