import {Component} from '@angular/core';
import {NgxUiLoaderService, SPINNER} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss']
})
export class AppComponent {
   title = 'cohen-ui';
   public spinner = SPINNER;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public loader: NgxUiLoaderService) {
      this.api.get('v1/csrf-cookie', {}).subscribe();
      this.api.get('admin/check-user', {}).subscribe(res => {
      }, err => {
      });

   }
}
