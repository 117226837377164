import {AfterContentInit, AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {XsLaravelDataTableComponent} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.component';
import {ILaravelDataTableField} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableField';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import * as lo from 'lodash';

@Component({
   selector: 'app-manage-comment',
   templateUrl: './manage-comment.component.html',
   styleUrls: ['./manage-comment.component.scss']
})
export class ManageCommentComponent implements AfterContentInit, AfterViewInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table: XsLaravelDataTableComponent;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/comment/list';
   public bookOnlyColumns: ILaravelDataTableField[] = [
      {name: 'Book', key: 'book.book_title', type: 'text'},
      {
         name: 'Rating', key: 'rating', type: 'text', render: (val) => {
            let stars = '';
            for (const i in [1, 2, 3, 4, 5]) {

               if (i < val) {
                  stars = stars + '<i class="fa fa-star" style="color: #cb9906 !important;">  </i>';
               } else {
                  stars = stars + '<i class="fa fa-star-o"> </i>';
               }
            }
            return stars;
         }
      },
      {name: 'Created', key: 'created_at', type: 'dateIST'},
      {name: 'Status', key: 'status', width: 'min'},

   ];
   public currentAffairOnlyColumns: ILaravelDataTableField[] = [
      {name: 'Current Affair', key: 'current_affair.title_name', type: 'text'},
      {name: 'Created', key: 'created_at', type: 'dateIST'},
      {name: 'Status', key: 'status', width: 'min'},

   ];
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Author', key: 'user.name', type: 'text',},
         {name: 'Comment', key: 'comment', type: 'text'},
      ]
   };
   public form: UntypedFormGroup;
   public type;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public activatedRoute: ActivatedRoute,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         user_id: new UntypedFormControl('', Validators.compose([])),
         author_name: new UntypedFormControl('', Validators.compose([])),
         comment: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         type: new UntypedFormControl('', Validators.compose([])),
         subject_id: new UntypedFormControl('', Validators.compose([])),
         parent: new UntypedFormControl('', Validators.compose([])),
         rating: new UntypedFormControl('', Validators.compose([])),
         admin_comment: new UntypedFormControl()
         //add
      });
      setTimeout(() => {
         this.updateColumns();
      }, 10);
   }

   ngAfterContentInit() {

      this.activatedRoute.params.subscribe(res => {
         this.type = res.type;

         this.endpoint = environment.endpoint + 'admin/comment/list?type=' + res.type;
         this.updateColumns();

      });

   }

   updateColumns() {
      if (!this.table) return;
      switch (this.type) {
         case 'book':
            this.table.removeColumn(3, 1);
            this.tableConfig.fields.splice(3, 0, ...this.bookOnlyColumns);
            break;
         case 'current-affair':
            this.table.removeColumn(3, 2);
            this.tableConfig.fields.splice(3, 0, ...this.currentAffairOnlyColumns);
            break;
      }
   }

   ngAfterViewInit(): void {

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/comment/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   edit(item: any) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(this.addNewModal);
   }

   delete(item: any, idx: any) {
      this.loader.start();
      this.api.post('admin/comment/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(idx);
         });
   }

   reject(item) {
      this.loader.start();
      this.api.post('admin/comment/save', {id: item.id, status: 'reject'})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            item = lo.assign(item, res.data);
         });
   }
}
