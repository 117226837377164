import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../services/api.service';
import {finalize} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
   selector: 'app-download-config',
   templateUrl: './download-config.component.html',
   styleUrls: ['./download-config.component.scss']
})
export class DownloadConfigComponent implements AfterViewInit {
   @Input() test;

   public config = {
      questions: false,
      options: false,
      answer: false,
      details: false
   };

   public languages = [
      {language: 'english', state: false},
      {language: 'punjabi', state: false},
      {language: 'hindi', state: false},
   ];

   constructor(public modal: NgbActiveModal, public api: ApiService, public loader: NgxUiLoaderService) { }

   ngAfterViewInit(): void {
     //console.log(this.test);
   }

   download() {
      this.loader.start();
      this.api.post('admin/test/pdf', {lang: this.languages, id: this.test.id, options: this.config})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.open(res.url, '_blank');
         });
   }
}
