<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage PreviousPaper</h3>
   <div>
      <button class="btn btn-success btn-icon mr-2" (click)="modal.open(stages)">Manage Stages</button>
      <button class="btn btn-primary btn-icon" (click)="openAddNewForm()"> Add New Paper</button>
   </div>
</div>

<xs-laravel-data-table #table [endpoint]="endpoint2" [config]="tableConfig"
></xs-laravel-data-table>

<div class="right-overlay" [ngClass]="{active:addNewOpen}">
   <h2>Add New <a (click)="closeAddNewForm()"><i class="fa fa-times"></i></a></h2>
   <form [formGroup]="form">
      <input type="hidden" formControlName="id" class="form-control">
      <div class="form-group">
         <label for="">MAIN COURSE </label>
         <app-main-category-selector
            (selected)="courseSelected()"
            [tree]="categoryTree"
            formControlName="main_course_id">
         </app-main-category-selector>
      </div>
      <div class="form-group">
         <label for="">PAPER STAGE</label>
         <app-paper-stage-selector [tree]="stageTree" formControlName="previous_papers_stage_id"></app-paper-stage-selector>
      </div>
      <div class="form-group">
         <label for="">EXAM YEAR</label>
         <input type="text" formControlName="exam_year" class="form-control">
      </div>
      <div class="form-group">
         <label for="" class="d-block">PDF 3</label>
         <app-doc-uploader formats=".pdf" directory="previous-papers" formControlName="pdf"></app-doc-uploader>
      </div>
      <div class="form-group">
         <label for="">SORT ORDER</label>
         <input type="text" appNumberOnly formControlName="sort_order" class="form-control">
      </div>
      <div class="form-group">
         <label for="" class="d-block">STATUS</label>
         <app-status formControlName="status"></app-status>
      </div>

      <button (click)="save()" class="btn btn-block btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </form>
</div>


<ng-template #stages let-modal="">
   <div class="modal-header">
      <h5 class="modal-title"></h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <app-manage-previous-papers-stage></app-manage-previous-papers-stage>
   </div>

</ng-template>
