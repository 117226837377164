<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Book Coupon Users</h3>
   <div>
      <button class="btn btn-info" (click)="showExportModal=true">Export</button>
   </div>
</div>

<label for="batch-filter">Filter By Coupon Batch</label>
<select [(ngModel)]="batchFilter" (ngModelChange)="filterByBatch()" id="batch-filter" class="form-control">
   <option value="0">All Batches</option>
   <option *ngFor="let item of  couponBatches" [value]="item.id">{{item.name}}</option>
</select>

<p-dialog header="Export Coupon Users" [(visible)]="showExportModal">
   <form [formGroup]="exportForm">
      <div class="form-group">
         <label for="">Batch</label>
         <select class="form-control" formControlName="batch_id">
            <option *ngFor="let b of couponBatches" [value]="b.id">{{b.name}}</option>
         </select>
      </div>

      <div class="row">
         <div class="col-6">
            <label for="">From</label>
            <app-date-picker formControlName="from"></app-date-picker>
         </div>
         <div class="col-6">
            <label for="">To</label>
            <app-date-picker formControlName="to"></app-date-picker>
         </div>
      </div>


   </form>
   <ng-template pTemplate="footer">
      <button (click)="export()" pButton class="p-button-success" label="Export" [disabled]="exportForm.invalid"></button>
   </ng-template>
</p-dialog>


<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="code" let-idx="index" lt-cell-template="" let-item="">
      {{item.code}}
      <small class="d-block">{{item.batch_name}}</small>
   </ng-template>
   <ng-template field="graduate" let-idx="index" lt-cell-template="" let-item="">
      <span class="tbl-status" [ngClass]="'status-'+item.graduate">{{item.graduate == 1 ? 'YES' : 'NO'}}</span>
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="editItem(item)">Edit</button>
         <button class="btn btn-sm btn-info" (click)="selectedItem=item;showViewModal=true">View</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>

   </ng-template>
   <ng-template field="city" let-idx="index" lt-cell-template="" let-item="">
      <div>{{item.city}}</div>
      <small>{{item.pincode}}</small>

   </ng-template>
   <ng-template field="name" let-idx="index" lt-cell-template="" let-item="">

      <div class="d-flex">
         <div class="mr-2">
            <app-cdn-image [src]="item.photo"></app-cdn-image>
         </div>
         <div>{{item.name}}
            <small class="d-block"> {{item.dob}}

            </small></div>
      </div>

   </ng-template>
</xs-laravel-data-table>
<p-dialog header="Details" [(visible)]="showViewModal">
   <div class="row" *ngIf="selectedItem">
      <div class="col-sm-8">
         <div *ngFor="let f of viewFields" class="d-flex pt-2 pb-2 border-bottom">
            <label class="mb-0" style="width: 150px;">{{f.label}}</label>
            <span class="mr-2">:</span>
            <div class="font-weight-bold">{{selectedItem[f.value]}}</div>
         </div>
         <div class="d-flex pt-2 pb-2 border-bottom">
            <label class="mb-0" style="width: 150px;">Used On</label>
            <span class="mr-2">:</span>
            <div class="font-weight-bold">{{selectedItem.created_at|date:'medium':'+530'}}</div>
         </div>

      </div>
      <div class="col-sm-4">
         <app-cdn-image [width]="200" [height]="0" [src]="selectedItem.photo"></app-cdn-image>
      </div>
   </div>
</p-dialog>
<p-dialog header="Edit Coupon User" [(visible)]="editCouponUserDialogVisible">
   <ng-container *ngIf="selectedItem">
      <form [formGroup]="editForm">
         <div class="row">
            <div class="col-sm-6" *ngFor="let f of viewFields">
               <ng-container [ngSwitch]="f.value">
                  <div class="form-group" *ngSwitchCase="'graduate'">
                     <label class="mb-0" style="width: 150px;">{{f.label}}</label>
                     <app-toggle-switch [formControlName]="f.value"></app-toggle-switch>
                  </div>
                  <div class="form-group" *ngSwitchCase="'gender'">
                     <label class="mb-0" style="width: 150px;">{{f.label}}</label>
                     <select class="form-control" [formControlName]="f.value">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="third_gender">Third Gender</option>
                     </select>
                  </div>
                  <div class="form-group" *ngSwitchCase="'category'">
                     <label class="mb-0" style="width: 150px;">{{f.label}}</label>
                     <select class="form-control" [formControlName]="f.value">
                        <option *ngFor="let c of constants.category" [value]="c.value">{{c.label}}</option>
                     </select>
                  </div>
                  <div class="form-group" *ngSwitchDefault>
                     <label class="mb-0" style="width: 150px;">{{f.label}}</label>
                     <input [readOnly]="f.value=='code'" type="text" [formControlName]="f.value" class="form-control">
                  </div>
               </ng-container>
            </div>
            <div class="col-sm-12">
               <app-photo-uploader formControlName="photo"></app-photo-uploader>
            </div>
         </div>

      </form>
      <button (click)="update()" class="btn btn-primary">Update</button>
   </ng-container>
</p-dialog>
