import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {of} from 'rxjs';
import {PhotoService} from '../../services/photo.service';
import {DatePipe} from '@angular/common';
import t from 'typy';
import {MenuItem} from 'primeng/api';

@Component({
   selector: 'app-manage-order',
   templateUrl: './manage-order.component.html',
   styleUrls: ['./manage-order.component.scss'],
   providers: [DatePipe]
})
export class ManageOrderComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('addTransaction') addTransactionModal;

   @ViewChild('transactionModal') transactionModal;
   @ViewChild('table') table;

   public currentApplication;
   public selectedItem;
   public selectedIndex;
   public user;
   public qualification;
   public menuItems: MenuItem[];
   public endpoint = environment.endpoint + 'admin/order/list';
   public tableConfig: ILaravelDataTableConfig = {
      groupActions: true,
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'User', key: 'user.name', focus: true, type: 'text'},
         {
            name: 'Program',
            key: 'program.name',
            filterKey: 'program_id',
            selectOptions: this.api.post('get-filters', {type: 'program-tree'})
         },
         {name: 'Batch Code', key: 'program.batch_code'},
         {name: 'Step', key: 'application.step'},
         {name: 'Coupon', key: 'coupon_code'},
         {
            name: 'Status', key: 'status', type: 'select', filterKey: 'status',
            selectOptions: of([
               {value: 'pending', label: 'Pending'},
               {value: 'paid', label: 'Paid'},
               {value: 'cancelled', label: 'Cancelled'},
            ]),
         },
         {
            name: 'Payment Mode',
            key: 'payment_mode',
            type: 'select',
            width: 'min',
            filterKey: 'payment_mode',
            selectOptions: of([
               {value: 'online', label: 'Online'},
               {value: 'offline', label: 'Offline'},
            ])
         },
         {key: 'updated_at', name: 'Date', type: 'dateIST'}
      ],

   };
   public form: UntypedFormGroup;
   public newTransactionForm: UntypedFormGroup;

   public transactionIndex = null;
   displayExport: boolean;
   public exportForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public datePipe: DatePipe,
               public photoService: PhotoService,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
      });
      this.exportForm = new UntypedFormGroup({
         from: new UntypedFormControl(),
         to: new UntypedFormControl(),
         status: new UntypedFormControl('')
      });

      this.newTransactionForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         order_id: new UntypedFormControl(),
         bank_ref_no: new UntypedFormControl(),
         amount: new UntypedFormControl(),
         gateway_status: new UntypedFormControl('success'),
         bank_or_card_name: new UntypedFormControl('MANUAL'),
         transaction_date: new UntypedFormControl()
      });
      // this.dp.serda;
      this.menuItems = [
         {
            label: 'Edit / Update', icon: 'pi pi-edit', command: () => {
               const m = this.modal.open(this.addNewModal);
               m.result.then(res => {
                  this.table.loadData();
               });
            }
         },
         {
            label: 'Details', icon: 'pi pi-edit', command: () => {

               this.user = this.selectedItem.user;
               this.qualification = this.user.qualification;
               const modalRef = this.modal.open(this.transactionModal);
               modalRef.result.then(res => {
                  //dataset[index] = {...this.selectedItem};
               });
            }
         },
         {
            label: 'Download', command: () => {
               this.downloadPDF(this.selectedItem);
            }
         }
      ];

   }

   ngOnInit() {
   }

   delete(item, idx) {
      this.loader.start();
      this.api.post('admin/order/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(idx);
            //dataset.splice(index, 1);
         });
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/order/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   public downloadPDF(item) {

      this.loader.start();
      this.api.post('admin/order/mmpdf', {id: item.id}).subscribe((res: any) => {
         window.open(res.url);
         this.loader.stop();
      });

   }

   getTotalPaid() {
      if (this.selectedItem) {
         let total = 0;
         this.selectedItem.transactions.forEach(item => {
            total += item.amount;
         });
         return total;
      }
   }

   getTotalPending() {
      return this.selectedItem.order_total - this.getTotalPaid();

   }

   applicationFormUpdated($event: any) {
      this.currentApplication = $event;

   }

   saveApplication() {
      this.loader.start();
      this.api.post('admin/application/save', this.currentApplication)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe(res => {

         });
   }

   addTransaction() {
      this.newTransactionForm.patchValue({
         order_id: this.selectedItem.id
      });
      this.loader.start();
      this.api.post('admin/transaction/save', this.newTransactionForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe(res => {
            this.newTransactionForm.patchValue({
               amount: null,
               bank_ref_no: null,
               transaction_date: null
            });
            this.selectedItem = {...res};

         });
   }

   editTransaction(transaction, index) {
      this.transactionIndex = index;
      this.newTransactionForm.patchValue(transaction);
   }

   cancelTransactionUpdate() {
      this.transactionIndex = null;
      this.newTransactionForm.patchValue({
         id: null,
         amount: null,
         bank_ref_no: null
      });
   }

   deleteTransaction(transaction: any, index) {
      this.transactionIndex = index;
      this.loader.start();
      this.api.post('admin/transaction/delete', {id: transaction.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.selectedItem = res.data.order;

         });
   }

   export() {
      this.loader.start();
      this.api.post('admin/order/export', this.exportForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }
}
