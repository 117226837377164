import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import Swal from 'sweetalert2';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-user',
   templateUrl: './manage-user.component.html',
   styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements AfterViewInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   @ViewChild('rowActions') actions;
   @ViewChild('userStatus') userStatus;
   public roles = [];
   public pageId;
   public trashed: false;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/user/list';
   public tableConfig: ILaravelDataTableConfig;
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public loader: NgxUiLoaderService
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         name: new UntypedFormControl('', Validators.compose([])),
         gender: new UntypedFormControl('', Validators.compose([])),
         role: new UntypedFormControl('', Validators.compose([])),
         father_name: new UntypedFormControl('', Validators.compose([])),
         date_of_birth: new UntypedFormControl('', Validators.compose([])),
         phone_no: new UntypedFormControl('', Validators.compose([])),
         email: new UntypedFormControl('', Validators.compose([])),
         password: new UntypedFormControl('', Validators.compose([])),
         profile_image: new UntypedFormControl('', Validators.compose([])),
         is_status: new UntypedFormControl('', Validators.compose([])),
         phone_status: new UntypedFormControl('', Validators.compose([])),
         email_status: new UntypedFormControl('', Validators.compose([])),
         verify_code: new UntypedFormControl('', Validators.compose([])),
         verify_phone: new UntypedFormControl('', Validators.compose([])),
         pswd_token: new UntypedFormControl('', Validators.compose([])),
         handicapped: new UntypedFormControl('', Validators.compose([])),
         category: new UntypedFormControl('', Validators.compose([])),
         mother_name: new UntypedFormControl('', Validators.compose([])),
         father_occupation: new UntypedFormControl('', Validators.compose([])),
         mobile2: new UntypedFormControl('', Validators.compose([])),
         landline: new UntypedFormControl('', Validators.compose([])),
         qualification: new UntypedFormControl('', Validators.compose([])),
         sign: new UntypedFormControl('', Validators.compose([])),
         id_proof: new UntypedFormControl('', Validators.compose([])),
         aadhaar_number: new UntypedFormControl('', Validators.compose([])),
         permanent_address: new UntypedFormControl('', Validators.compose([])),
         city: new UntypedFormControl('', Validators.compose([])),
         post_office: new UntypedFormControl('', Validators.compose([])),
         pin_code: new UntypedFormControl('', Validators.compose([])),
         district: new UntypedFormControl('', Validators.compose([])),
         state: new UntypedFormControl('', Validators.compose([])),
         correspondence_address: new UntypedFormControl('', Validators.compose([])),
         cor_city: new UntypedFormControl('', Validators.compose([])),
         cor_post_office: new UntypedFormControl('', Validators.compose([])),
         cor_pin_code: new UntypedFormControl('', Validators.compose([])),
         cor_state: new UntypedFormControl('', Validators.compose([])),
         cor_district: new UntypedFormControl('', Validators.compose([])),
         landmark: new UntypedFormControl('', Validators.compose([])),
         cor_landmark: new UntypedFormControl('', Validators.compose([])),

      });
      this.loadRoles();

   }

   loadRoles() {
      this.api.post('admin/roles/role-list', {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.roles = res;
         });
   }

   ngAfterViewInit() {
      setTimeout(() => {
         this.tableConfig = {
            quickLinks: true,
            groupActions: true,
            fields: [
               {name: 'ID', key: 'id', type: 'text', width: 'min'},
               {name: 'Name', key: 'name', type: 'text'},
               {name: 'Email', key: 'email', type: 'text'},
               {name: 'Phone', key: 'phone_no', type: 'text'},
               {name: 'App Installed', key: 'app_installed', type: 'text'},
               {name: 'Referred By', key: 'referred_by', type: 'text', filterKey: 'referred_by.name:users'},
               {name: 'Role', key: 'role', type: 'text'},
               {
                  name: 'Lock', key: 'enabled', type: 'select', selectOptions: of([
                     {label: 'Locked', value: 0},
                     {label: 'UnLocked', value: 1}
                  ]), width: 'min'
               },
               {name: 'Created', key: 'created_at', type: 'dateIST'},

            ],
            actions: this.actions,
            // actions: [
            //    {
            //       name: 'Edit',
            //       type: 'primary',
            //       fn: (dataset, index) => {
            //          this.selectedItem = dataset[index];
            //          this.form.patchValue(this.selectedItem);
            //          const modalRef = this.modal.open(this.addNewModal);
            //
            //       }
            //    },
            //    {
            //       name: 'Delete',
            //       type: 'danger',
            //       fn: (dataset, index) => {
            //          swal.fire({
            //             title: 'Delete :' + dataset[index].name + '?',
            //             icon: 'question',
            //             showCancelButton: true,
            //             cancelButtonText: 'No',
            //             confirmButtonText: 'Yes',
            //          }).then(res => {
            //             if (res.value) {
            //                this.loader.start();
            //                this.api.post('admin/user/delete', {id: dataset[index].id})
            //                   .pipe(finalize(() => {
            //                      this.loader.stop();
            //                   }))
            //                   .subscribe((res: any) => {
            //                      dataset.splice(index, 1);
            //                   });
            //             }
            //          });
            //       }
            //    }
            // ]
         };
      });

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/user/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   deleteUser(item) {
      const permanentDeleteMsg = `This will permanently delete <strong>${item.name}</strong>.
This action cant be undone.Please proceed carefully.`;
      Swal.fire({
         titleText: 'Confirm',
         html: item.deleted_at ? permanentDeleteMsg : `Delete :  ${item.name}`,
         icon: 'warning',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/user/delete', {id: item.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.table.loadData();
               });
         }
      });
   }

   editUser(item) {

      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);


      if (item.roles.length > 0) {
         this.form.patchValue({role: item.roles[0].id})
      } else {
         this.form.patchValue({role: 2});
      }

      const modalRef = this.modal.open(this.addNewModal);
   }

   enableOrDisableUser(item) {

      this.loader.start();
      this.api.post('admin/user/lock', {id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe(res => {
            item.enabled = !item.enabled;
         });
   }

   restore(item) {
      this.loader.start();
      this.api.post('admin/user/restore', {id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe(res => {
            this.table.loadData();
         });
   }

}
