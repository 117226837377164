import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Constants} from '../../Constants';
import {IAdManagerConfig, ManageAdsComponent} from '../manage-ads/manage-ads.component';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-page',
   templateUrl: './manage-page.component.html',
   styleUrls: ['./manage-page.component.scss']
})
export class ManagePageComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public close = false;
   public editorOptions = Constants.editorOptions;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/page/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Title', key: 'title', type: 'text', width: ''},
         {name: 'Status', key: 'status', type: 'text', width: 'min'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST'},
      ],
      actions: [
         {
            name: 'Advertisements',
            type: 'info',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(ManageAdsComponent);
               const config: IAdManagerConfig = {
                  adType: 'page',
                  isModal: true,
                  parent: dataset[index].id,
                  title: dataset[index].title

               };
               modalRef.componentInstance.setConfig(config);

            }
         },
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.resetFormFields();
               this.selectedItem = dataset[index];
               if (this.selectedItem.fields) {
                  this.selectedItem.fields.forEach(item => {
                     (this.form.get('meta') as UntypedFormGroup).addControl(item.key, new UntypedFormControl());
                  });
               }
               //  console.log(this.selectedItem);
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/page/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe(() => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;
   public pageCategory;
   public bdrtForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public activatedRoute: ActivatedRoute,
               public loader: NgxUiLoaderService) {

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
         meta: new UntypedFormGroup({}),
         status: new UntypedFormControl(),
         sort_order: new UntypedFormControl(),
         desc: new UntypedFormControl(),
      });
      this.activatedRoute.params.subscribe(res => {
         //  this.pageCategory = res;
         if (res.cat) {
            this.pageCategory = res.cat;
            this.endpoint = this.endpoint + '?parent=' + this.pageCategory;
         }

      });
   }

   resetFormFields() {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
         meta: new UntypedFormGroup({}),
         status: new UntypedFormControl(),
         desc: new UntypedFormControl(),
         sort_order: new UntypedFormControl(),
      });

      this.bdrtForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl()
      });
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({status: 'publish', layout: 'half'});
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/page/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            if (this.close) {

               this.form.reset({
                  status: 'publish',
                  layout: 'half'
               });
               return;
            }
            this.modal.dismissAll();
            this.table.loadData();
         });
   }
}
