<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Main Courses</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<ng-container *ngIf="tree">
   <app-main-course-node [tree]="tree" (edit)="edit($event)" (delete)="delete($event)" (statusChanged)="statusUpdate($event)"></app-main-course-node>
</ng-container>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Course</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id">

         <div class="form-group">
            <label for="">Course Name</label>
            <input type="text" formControlName="course_name" class="form-control">
         </div>

         <div class="form-group">
            <label for="">Parent</label>
            <select formControlName="parent" class="form-control">
               <option value="0">-- NEW COURSE --</option>
               <ng-container *ngTemplateOutlet="option;context:{indent:'',items :tree}"></ng-container>
            </select>
         </div>

         <div class="form-group" *ngIf="form.value.parent>0">
            <label for="" class="form-label">Visible To</label>

            <ng-container *ngFor="let c of studentPanelCourses">
               <div>
                  <div *ngIf="c.sub_courses.length==0" class="mt-4">
                     <p-checkbox [formControl]="form.controls['visible_to']" [label]="c.course_name" [value]="c.id"></p-checkbox>
                  </div>
                  <div *ngIf="c.sub_courses.length>0">
                     <strong class="tw-block tw-mb-2">{{c.course_name}}</strong>
                     <div *ngFor="let sc of c.sub_courses">
                        <p-checkbox [formControl]="form.controls['visible_to']" [label]="sc.course_name" [value]="c.id"></p-checkbox>
                     </div>
                  </div>
               </div>
            </ng-container>

         </div>

         <div class="form-group">
            <label for="">Sort</label>
            <input type="text" appNumberOnly formControlName="sort" class="form-control">
         </div>

         <div class="form-group">
            <label class="d-block">Status</label>
            <app-status formControlName="status"></app-status>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <i *ngIf="updating" class="fas fa-circle-notch fa-spin"></i>
      <button (click)="save()" class="btn btn-primary" *ngIf="!updating">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<ng-template #option let-items="items" let-indent="indent">
   <ng-container *ngFor="let item of items">
      <option [value]="item.id">{{indent}} {{item.course_name}}</option>
      <ng-container *ngTemplateOutlet="option;context:{indent: '--'+indent,items :item.sub_courses}"></ng-container>
   </ng-container>
</ng-template>

<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <app-bdrt formControlName="bdrt"></app-bdrt>
         <input type="hidden" formControlName="id" class="form-control">
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
