<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Coupon</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="type" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.type"></app-badge>
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="edit(item)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Coupon</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id">
         <div class="row">
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Coupon Type <span>*</span></label>
                  <select class="form-control custom-select" formControlName="type">
                     <option value="amount">Fixed Amount</option>
                     <option value="percent">Percentage value</option>
                  </select>
               </div>

            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Assign to user </label>
                  <input type="text" class="form-control"
                         [ngbTypeahead]="search"
                         [editable]="false"
                         [value]="selectedItem?.user?.email"
                         (selectItem)="userSelected($event)"
                         [inputFormatter]="inputFormatter"
                         [resultTemplate]="rt">
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Coupon Value <span>*</span></label>
                  <input type="text" appNumberOnly class="form-control" formControlName="value">
               </div>
            </div>
            <div class="col-sm-6" *ngIf="!form.value.id">
               <div class="form-group">
                  <label for="">Can be used # time <span>*</span></label>
                  <input type="text" appNumberOnly class="form-control" formControlName="use_count">
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Number of coupons <span>*</span></label>
                  <input type="text" appNumberOnly class="form-control" formControlName="count">
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Small Description<span>*</span></label>
                  <input type="text" class="form-control" formControlName="desc">
               </div>
            </div>

         </div>
      </form>
   </div>
   <div class="modal-footer">
      <div class="text-danger" *ngIf="form.invalid">Fill required fields</div>
      <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Generate Coupons'}}</button>
   </div>
</ng-template>
<ng-template #rt let-r="result" let-t="term">
   <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
   <small class="d-block">{{r.email}}</small>
</ng-template>
