<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage faq</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table
                       [endpoint]="endpoint"
                       [config]="tableConfig"
></xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} faq</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id">
         <div class="form-group">
            <label for="">Question</label>
            <input type="text" class="form-control" formControlName="question">
         </div>
         <div class="form-group">
            <label for="">Answer</label>
            <app-editor formControlName="answer"></app-editor>
         </div>

         <div class="row">
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Faq type</label>
                  <select formControlName="faq_type" class="custom-select">
                     <option value="website">Website</option>
                     <option value="test-series">Test Series</option>
                     <option value="student-dashboard">Student Dashboard</option>
                  </select>
               </div>
            </div>

            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Sort Order</label>
                  <input appNumberOnly type="text" class="form-control" formControlName="sort_order">
               </div>
            </div>
         </div>
         <div class="form-group">
            <label class="d-block">Status</label>
            <app-status formControlName="status"></app-status>
         </div>

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
