import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
    selector: 'app-manage-programme-key-point',
    templateUrl: './manage-programme-key-point.component.html',
    styleUrls: ['./manage-programme-key-point.component.scss']
})
export class ManageProgrammeKeyPointComponent implements OnInit {
    @ViewChild('addNew') addNewModal;
    @ViewChild('table') table;
    public pageId;
    public selectedItem;
    public endpoint = environment.endpoint + 'admin/programme-key-point/list';
    public tableConfig: ILaravelDataTableConfig = {
        fields: [
            {name: 'ID', key: 'id', type: 'text', width: 'min'},
            {name: 'Name', key: 'name'},

        ],
        actions: [
            {
                name: 'Edit',
                type: 'primary',
                fn: (dataset, index) => {
                    this.selectedItem = dataset[index];
                    this.form.patchValue(this.selectedItem);
                    const modalRef = this.modal.open(this.addNewModal);

                }
            },
            {
                name: 'Delete',
                type: 'danger',
                fn: (dataset, index) => {
                    swal.fire({
                        title: 'Delete :' + dataset[index].title + '?',
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                    }).then(res => {
                        if (res.value) {
                            this.loader.start();
                            this.api.post('admin/programme-key-point/delete', {id: dataset[index].id})
                                .pipe(finalize(() => {
                                    this.loader.stop();
                                }))
                                .subscribe((res: any) => {
                                    dataset.splice(index, 1);
                                });
                        }
                    });
                }
            }
        ]
    };
    public form: UntypedFormGroup;
    public pageForm: UntypedFormGroup;

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public modal: NgbModal,
                public loader: NgxUiLoaderService) {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl('', Validators.compose([])),
            name: new UntypedFormControl('', Validators.compose([])),
            icon: new UntypedFormControl('', Validators.compose([])),
            point_1: new UntypedFormControl('', Validators.compose([])),
            point_2: new UntypedFormControl('', Validators.compose([])),
            point_3: new UntypedFormControl('', Validators.compose([])),
            point_4: new UntypedFormControl('', Validators.compose([])),
            point_5: new UntypedFormControl('', Validators.compose([])),
            point_6: new UntypedFormControl('', Validators.compose([])),
            point_7: new UntypedFormControl('', Validators.compose([])),
            point_8: new UntypedFormControl('', Validators.compose([])),
            bdrt: new UntypedFormControl('', Validators.compose([])),

        });

    }

    ngOnInit() {

    }

    add(addNew: TemplateRef<any>) {
        this.selectedItem = null;
        this.form.reset();
        this.modal.open(addNew);
    }

    save() {
        this.loader.start();
        this.api.post('admin/programme-key-point/save', this.form.value)
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.modal.dismissAll();
                this.table.loadData();
            });
    }

}
