import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IAdManagerConfig, ManageAdsComponent } from '../manage-ads/manage-ads.component';
import { ILaravelDataTableConfig } from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-about-exam',
   templateUrl: './manage-about-exam.component.html',
   styleUrls: ['./manage-about-exam.component.scss']
})
export class ManageAboutExamComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId = 51;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/about-exam/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         { name: 'ID', key: 'id', type: 'text', width: 'min' },
         { name: 'Title', key: 'title', type: 'text' },
         {
            name: 'End Exam', key: 'course.course_name', filterKey: 'main_course_id',
            type: 'select', selectOptions: this.api.get('admin/resource-main-course-filter', {}),

         },
         { name: 'Status', key: 'status', render: (val) => `<span class="status-${val}">${val}</span>`, width: 'min' },
         { name: 'Sort', key: 'sort_order', type: 'sort', width: 'min' },

      ],
      actions: [
         {
            name: 'Advertisement',
            type: 'info',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(ManageAdsComponent);
               const config: IAdManagerConfig = {
                  adType: 'about-exam',
                  isModal: true,
                  parent: dataset[index].id,
                  title: dataset[index].title

               };
               modalRef.componentInstance.setConfig(config);

            }
         },
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/about-exam/delete', { id: dataset[index].id })
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe((res: any) => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public courseTree = [];

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public loader: NgxUiLoaderService
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         main_course_id: new UntypedFormControl('', Validators.compose([])),
         content: new UntypedFormControl('', Validators.compose([])),
         bdrt: new UntypedFormControl('', Validators.compose([])),

      });
      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
      });
   }

   ngOnInit() {
      this.loadPage();
      this.getCourseTree();
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/about-exam/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   saveBdrt() {
      this.loader.start();
      this.api.post('admin/page/save', this.pageForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
         });
   }

   loadPage() {
      this.loader.start();
      this.api.get('admin/page/' + this.pageId, {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.pageForm.patchValue(res);
         });
   }

   private getCourseTree() {
      this.api.post('admin/free-resource-course/get-tree', {})
         .subscribe((res: any) => {
            this.courseTree = res;
         });
   }
}
