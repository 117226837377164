import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {NgxUiLoaderModule, NgxUiLoaderService} from 'ngx-ui-loader';
import {ApiService} from '../../../services/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
   selector: 'app-add-edit-transaction',
   templateUrl: './add-edit-transaction.component.html',
   styleUrls: ['./add-edit-transaction.component.scss']
})
export class AddEditTransactionComponent implements OnInit {
   public newTransactionForm: UntypedFormGroup;
   @Input() selectedItem;
   @Input() testOrderId;
   @Input() orderItems;
   @Input() transaction;
   public form;

   constructor(public loader: NgxUiLoaderService, public api: ApiService, public modal: NgbActiveModal) {

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         test_order_id: new UntypedFormControl(),
         gateway_transaction_id: new UntypedFormControl(),
         date: new UntypedFormControl(),
         added_date: new UntypedFormControl(),
         bank_or_card_name: new UntypedFormControl(),
         sub_payments: new UntypedFormControl(),
         mode: new UntypedFormControl('offline'),
         gateway_status: new UntypedFormControl(),
         cash: new UntypedFormControl()
      });
   }

   ngOnInit(): void {
      if (this.transaction) this.form.patchValue(this.transaction);
      if (this.testOrderId) this.form.patchValue({test_order_id: this.testOrderId});
   }

   save() {
      this.loader.start();
      this.api.post('admin/test-order-transaction/save', this.form.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.modal.close(res);
         });

   }
}
