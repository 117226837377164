<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Current Affair Category</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>


<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="edit(item)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Current Affair Category</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id" class="form-control">
         <div class="row">

            <div class="col-6">
               <div class="form-group">
                  <label for="">CATEGORY TITLE</label>
                  <input type="text" formControlName="category_title" class="form-control">
               </div>
            </div>
            <div class="col-sm-12">
               <label for="" class="d-block">Status</label>
               <app-status formControlName="status"></app-status>
            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
