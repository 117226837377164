import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewRef} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {catchError, debounceTime, delay, distinctUntilChanged, finalize, map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ICheckBoxItem} from '../../../../projects/checkbox-group/src/interfaces/ICheckBoxItem';
import {from, Observable, of, Subscription} from 'rxjs';
import {DownloadConfigComponent} from '../../_modals/download-config/download-config.component';

import {IAdManagerConfig, ManageAdsComponent} from '../manage-ads/manage-ads.component';
import {MenuItem} from 'primeng/api';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-tests-listing',
   templateUrl: './manage-tests-listing.component.html',
   styleUrls: ['./manage-tests-listing.component.scss']
})
export class ManageTestsListingComponent implements AfterViewInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('importPapers') importPaperModal;
   @ViewChild('table') table;
   @ViewChild('allotTestModal') allotTestModal;
   public selectedTest;
   public displayQuestionSidebar = false;
   public questionFetcher$: Subscription;

   public program;
   public languages = ['english', 'punjabi', 'hindi'];
   public allottedUsersLoading = false;
   public usersAllottedToTest = [];

   public questions = [];
   public qNumbering = 0;
   public loadingQuestions = false;
   public selectedLanguage = 'english';
   public sidebar = {
      state: 'closed'
   };
   public answerSheetConfigOptions: ICheckBoxItem[] = [
      {label: 'Questions', value: 'questions'},
      {label: 'Options', value: 'options'},
      {label: 'Answer', value: 'answers'},
      {label: 'Description', value: 'details'}
   ];
   public pageId;
   public endpoint;
   public tableConfig: ILaravelDataTableConfig;
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public importForm: UntypedFormGroup;
   //allot tests to users
   public items: MenuItem[] = [
      {
         label: 'Allot Tests To User', command: () => {
            this.openAllotTestModal(this.selectedTest);
         }
      },
      {
         label: 'View Questions', command: () => {
            this.loadQuestions('english');
            this.displayQuestionSidebar = true;
         }
      },
      {label: 'Manage Ads', command: () => this.manageAds(this.selectedTest)},
      {
         label: 'User Attempts',
         command: () => {
            this.router.navigate(['dashboard', 'manage', 'tests', this.selectedTest.id, 'attempts'], {queryParams: {testName: this.selectedTest.name}});
         }
      },
      {
         label: 'Download Word File', command: () => {
            this.downloadQuestionsPdf(this.selectedTest);
         }
      },
      {label: 'Import Questions', command: () => this.import(this.selectedTest)},
      {label: 'Edit', command: () => this.edit(this.selectedTest)},
      {label: 'Remove', command: () => this.removeTest(this.selectedTest)},
   ];
   public alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
   public tax;
   public priceWithTax;
   public priceWithoutTax;
   public testPickerModal = false;
   public tests = [];
   public selectedTests = [];

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public activatedRoute: ActivatedRoute,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {

      this.activatedRoute.queryParams.subscribe(res => {
         //  console.log(res);
         this.program = res;
      });

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         no_of_questions: new UntypedFormControl(),
         sub_heading: new UntypedFormControl(),
         syllabus: new UntypedFormControl(),
         total_marks: new UntypedFormControl(),
         //
         can_view_question_paper: new UntypedFormControl(),
         sections: new UntypedFormControl(),
         marking: new UntypedFormControl(),
         pdf_download: new UntypedFormControl(),
         buy_enabled: new UntypedFormControl(),
         price: new UntypedFormControl(),
         sale_price: new UntypedFormControl(),
         tax_rate: new UntypedFormControl('18'),
         //negative
         negative_marking: new UntypedFormControl(),
         negative_marking_value: new UntypedFormControl(),
         //
         answers: new UntypedFormControl(),
         answer_display_date: new UntypedFormControl('1-6-2020'),
         answer_pdf_download: new UntypedFormControl(),
         answer_pdf_config: new UntypedFormControl(['options', 'answers']),
         // timing
         start_date: new UntypedFormControl(),
         end_date: new UntypedFormControl(),
         live_test: new UntypedFormControl(),
         duration: new UntypedFormControl(),
         attempts: new UntypedFormControl(),
         //
         status: new UntypedFormControl(),
         sort: new UntypedFormControl(),
         //relations
         // programs: new FormControl(),
         lock_answer: new UntypedFormControl(),
         switch_language: new UntypedFormControl(),
         download_pdf: new UntypedFormControl(),
         single_program: new UntypedFormControl(),
         programs: new UntypedFormControl(),
         q_pdf: new UntypedFormControl(),
         a_pdf: new UntypedFormControl()
      });
      this.importForm = new UntypedFormGroup({
         paper_english: new UntypedFormControl(),
         paper_punjabi: new UntypedFormControl(),
         paper_hindi: new UntypedFormControl(),
         id: new UntypedFormControl()
      });
      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         //bdrt: new FormControl
      });

   }

   calculateTax() {
      const form = this.form.value;
      const taxRate = +(form.tax_rate ? form.tax_rate : 18);
      this.priceWithTax = +(form.sale_price ? form.sale_price : form.price);
      this.priceWithoutTax = +((this.priceWithTax / (100 + +taxRate)) * 100);
      if (this.priceWithoutTax) this.priceWithoutTax = this.priceWithoutTax.toFixed(2);
   }

   getQuestionNumber(type) {
      if (type === 'comp') {
         return '';
      }
      return ++this.qNumbering;
   }

   ngAfterViewInit() {
      // this.loadPage();
      setTimeout(() => {
         if (this.program) {
            this.endpoint = environment.endpoint + 'admin/test/list?programId=' + this.program.id;
         } else {
            this.endpoint = environment.endpoint + 'admin/test/list';
         }

         this.tableConfig = {
            fields: [
               {name: 'ID', key: 'id', type: 'text', width: 'min'},
               {name: 'Name', key: 'name', type: 'text'},
               {name: 'Questions', key: 'no_of_questions', type: 'text', width: 'min'},
               {name: 'Live Test', key: 'live_test', width: 'min'},
               {name: 'Negative Marking', key: 'negative_marking', width: 'min'},
               {
                  name: 'Status', key: 'status', type: 'select', width: 'min',
                  selectOptions: of([
                     {label: 'Future', value: 'future'},
                     {label: 'Expired', value: 'expire'},
                     {label: 'Publish', value: 'publish'},
                  ])
               },
               {
                  name: 'Sort', key: 'sort_by', type: 'sort', width: 'min'

               }
            ],
         };
      });

   }

   add(addNew: TemplateRef<any>) {
      this.selectedTest = null;
      this.form.reset({
         single_program: this.program.id,
         tax_rate: '18'
      });
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/test/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   saveBdrt() {
      this.loader.start();
      this.api.post('admin/page/save', this.pageForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
         });
   }

   loadPage() {
      this.loader.start();
      this.api.get('admin/page/' + this.pageId, {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.pageForm.patchValue(res);
         });
   }

   importPapersClick() {
      this.loader.start();
      this.api.post('admin/test/import', this.importForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.selectedTest.paper_english = res.data.paper_english;
            this.selectedTest.paper_hindi = res.data.paper_hindi;
            this.selectedTest.paper_punjabi = res.data.paper_punjabi;
            this.selectedTest = {...this.selectedTest};
         });
   }

   questionTrackFn(index, item) {
      return item.id;
   }

   edit(item) {
      this.selectedTest = item;
      this.form.patchValue(this.selectedTest);
      const programs = [];
      this.selectedTest.programs.forEach((v) => {
         programs.push(v.id);
      });
      this.form.patchValue({programs});
      this.form.patchValue({single_program: this.program.id});
      const modalRef = this.modal.open(this.addNewModal);

   }

   delete(item, index) {
      this.loader.start();
      this.api.post('admin/test/delete', {id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.table.removeItemAtIndex(index);
         });
   }

   viewQuestions(item) {
      this.selectedTest = item;
      this.sidebar.state = 'open';
      this.loadQuestions('english');
   }

   loadQuestions(language) {
      this.loadingQuestions = false;
      this.selectedTest.questions = [];

      this.selectedLanguage = language;
      this.loadingQuestions = true;

      if (this.questionFetcher$) this.questionFetcher$.unsubscribe();
      this.questionFetcher$ = this.api.post('admin/test/questions', {id: this.selectedTest.id, language})
         .pipe(finalize(() => this.loadingQuestions = false))
         .subscribe((res: any) => {
            this.qNumbering = 0;
            this.selectedTest.questions = res;

         });
   }

   import(item) {
      this.selectedTest = item;
      this.importForm.patchValue(this.selectedTest);
      const modalRef = this.modal.open(this.importPaperModal);
   }

   openAllotTestModal(item) {
      this.selectedTest = item;
      this.modal.open(this.allotTestModal, {size: 'md'});
      this.loadUsersWithAllotment();
   }

   allotTestClick(value: string) {
      this.allottedUsersLoading = true;
      this.api.post('admin/test/allot', {users: value, id: this.selectedTest.id})
         .pipe(finalize(() => this.allottedUsersLoading = false))
         .subscribe((res: any) => {
            this.allottedUsersLoading = false;
            this.loadUsersWithAllotment();
         });
   }

   removeUserFromTest(item, idx) {
      this.allottedUsersLoading = true;
      this.api.post('admin/test/allot/remove', {id: this.selectedTest.id, guid: item.guid})
         .pipe(finalize(() => this.allottedUsersLoading = false))
         .subscribe((res: any) => {
            this.allottedUsersLoading = false;
            this.usersAllottedToTest.splice(idx, 1);
         });
   }

   downloadQuestionsPdf(item) {
      const ref = this.modal.open(DownloadConfigComponent, {size: 'md'});
      ref.componentInstance.test = item;
   }

   manageAds(item: any) {
      const modalRef = this.modal.open(ManageAdsComponent);
      const config: IAdManagerConfig = {
         adType: 'test',
         isModal: true,
         parent: item.id,
         title: item.name
      };
      modalRef.componentInstance.setConfig(config);
   }

   searchTest($event: any) {
      const term = $event.query;
      this.api.post('admin/test/search', {term})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.tests = res;
         });
   }

   addTestToTestSeries() {
      this.loader.start();
      this.api.post('admin/test/add-existing', {tests: this.selectedTests, id: this.program.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.table.loadData();
            this.selectedTests = [];
            this.testPickerModal = false;
         });
   }

   private removeTest(selectedItem: any) {
      this.loader.start();
      this.api.post('admin/test/remove', {test_id: selectedItem.id, id: this.program.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.table.loadData();
         });
   }

   private loadUsersWithAllotment() {
      this.allottedUsersLoading = true;
      this.api.get('admin/test/allot', {id: this.selectedTest.id})
         .pipe(finalize(() => this.allottedUsersLoading = false))
         .subscribe((res: any) => {
            this.usersAllottedToTest = res;
         });
   }

   duplicate(item: any, index: any) {
      this.loader.start();
      this.api.post('admin/test/duplicate', {id: item.id, program_id: this.program.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.table.loadData();
         });
   }

   updateSort(item: any, $e) {

      this.loader.start();
      this.api.post('admin/test/update-sort-order', {test_id: item.id, program_id: this.program.id, sort_order: $e.target.value})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
         });
   }
}
