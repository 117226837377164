<div [ngClass]="{'p-3':componentConfig.isModal}">
    <div class="page-title d-flex justify-content-between align-items-center">
        <h3>Manage Advertisements - <span *ngIf="componentConfig.title" style="color: #00aa88">{{componentConfig.title}}</span></h3>
        <div>
            <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
        </div>
    </div>
    <xs-laravel-data-table #table
                           [endpoint]="endpoint"
                           [config]="tableConfig"
    ></xs-laravel-data-table>
    <ng-template #addNew let-modal>
        <div class="modal-header">
            <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Advertisement</h5>
            <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label for="">Link Url</label>
                            <input type="text" class="form-control" formControlName="url">
                        </div>
                        <div class="form-group">
                            <label class="d-block mt-3">Sort Order</label>
                            <input appNumberOnly type="text" formControlName="sort_order" class="form-control"/>
                        </div>
                        <div class="form-group">
                            <label class="d-block mt-3">Status</label>
                            <app-status formControlName="status" label="Status"></app-status>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="">Advertisement Image</label>
                        <app-photo-uploader formControlName="image" dir="ads"></app-photo-uploader>
                    </div>

                </div>

            </form>
        </div>
        <div class="modal-footer">
            <button [disabled]="form.invalid" (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
        </div>
    </ng-template>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="activeModal.close(false)"> Close</button>
</div>
