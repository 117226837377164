import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-coupon',
   templateUrl: './manage-coupon.component.html',
   styleUrls: ['./manage-coupon.component.scss']
})
export class ManageCouponComponent implements OnInit {
   @ViewChild('addNew') addNewModal;

   @ViewChild('table') table;

   public selectedItem;
   public endpoint = environment.endpoint + 'admin/coupon/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Code', key: 'code', type: 'text', width: 'min'},
         {name: 'Type', key: 'type', width: 'min'},
         {name: 'Value', key: 'value', width: 'min'},
         {name: 'Max Use', key: 'use_count', type: 'text', width: 'min'},
         {name: 'Used', key: 'used', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST', width: 'min'},
      ],

   };
   public form: UntypedFormGroup;
   private searching: boolean;
   private searchFailed: boolean;
   search = (text$: Observable<string>) =>
      text$.pipe(
         debounceTime(100),
         distinctUntilChanged(),
         tap(() => this.searching = true),
         switchMap(term =>
            this.api.post('admin/users/search', {term}).pipe(
               tap(() => this.searchFailed = false),
               catchError(() => {
                  this.searchFailed = true;
                  return of([]);
               }))
         ),
         tap(() => this.searching = false)
      );

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         use_count: new UntypedFormControl('', Validators.required),
         type: new UntypedFormControl('amount'),
         value: new UntypedFormControl('', Validators.required),
         count: new UntypedFormControl('', Validators.required),
         user_id: new UntypedFormControl(),
         desc: new UntypedFormControl()
      });
   }

   ngOnInit() {
   }

   inputFormatter(item) {
      return item.email;
   };

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({
         type: 'amount'
      });
      this.modal.open(addNew, {size: 'lg'});
   }

   save() {
      this.loader.start();
      this.api.post('admin/coupon/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   userSelected($event) {
      // console.log($event);
      this.form.patchValue({user_id: $event.item.id});
   }

   delete(item: any) {
      this.loader.start();
      this.api.post('admin/coupon/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.loadData();
         });
   }

   edit(item) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      this.form.patchValue({count: 1});
      const modalRef = this.modal.open(this.addNewModal, {size: 'lg'});
   }
}
