import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAdManagerConfig, ManageAdsComponent} from '../../manage-ads/manage-ads.component';
import {ILaravelDataTableConfig} from '../../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-current-affair',
   templateUrl: './manage-current-affair.component.html',
   styleUrls: ['./manage-current-affair.component.scss']
})
export class ManageCurrentAffairComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/current-affair/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'title_name', type: 'text', focus: true},
         {name: 'Status', key: 'status', type: 'status', width: 'min'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
         {name: 'Topic Date', key: 'created_at', width: 'min', type: 'date'},
      ],

   };
   public form: UntypedFormGroup;
   public bdrtForm: UntypedFormGroup;
   public categories = [];
   public pageId = 11;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         title_name: new UntypedFormControl('', Validators.compose([])),
         sub_title: new UntypedFormControl('', Validators.compose([])),
         desc: new UntypedFormControl('', Validators.compose([])),
         category: new UntypedFormControl('', Validators.compose([])),
         read_more_url: new UntypedFormControl('', Validators.compose([])),
         topic_date: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         image: new UntypedFormControl('', Validators.compose([])),
         featured_image: new UntypedFormControl('', Validators.compose([])),
         bdrt: new UntypedFormControl(),
         protected: new UntypedFormControl(),
         created_at: new UntypedFormControl(),
      });

      this.bdrtForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl()
      });
   }

   ngOnInit() {
      this.loadPage();
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/current-affair/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   saveBdrt() {
      this.loader.start();
      this.api.post('admin/page/save', this.bdrtForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
         });
   }

   loadPage() {
      this.loader.start();
      this.api.get('admin/page/' + this.pageId, {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.bdrtForm.patchValue(res);
         });
   }

   delete(item, idx) {
      this.loader.start();
      this.api.post('admin/current-affair/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.table.removeItemAtIndex(idx);
         });
   }

   edit(currentAffair) {
      this.selectedItem = currentAffair;
      this.form.patchValue(this.selectedItem);
      const cats = [];
      this.selectedItem.categories.forEach(item => {
         cats.push(item.id);
      });
      this.form.patchValue({category: cats});
      const modalRef = this.modal.open(this.addNewModal);
   }

   manageAds(CurrentAffair) {
      this.selectedItem = CurrentAffair;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(ManageAdsComponent);
      const config: IAdManagerConfig = {
         adType: 'current-affair',
         isModal: true,
         parent: CurrentAffair.id,
         title: CurrentAffair.title_name

      };
      modalRef.componentInstance.setConfig(config);
   }
}
