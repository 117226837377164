<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Order</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
      <button class="btn btn-info btn-icon ml-2 " (click)="displayExport=true"> Export</button>
   </div>
</div>
<p-dialog header="Export Data" [(visible)]="displayExport" modal="true" [closable]>

   <div style="height: 400px;">
      <form [formGroup]="exportForm">
         <div class="row">
            <div class="col-6">
               <label for="">From</label>
               <app-date-picker formControlName="from"></app-date-picker>
            </div>
            <div class="col-6">
               <label for="">To</label>
               <app-date-picker formControlName="to"></app-date-picker>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label for="">Status</label>
                  <select class="form-control" formControlName="status">
                     <option value="">All</option>
                     <option value="paid">Paid</option>
                     <option value="partial">Partial</option>
                     <option value="pending">Pending</option>
                  </select>
               </div>
            </div>
         </div>
      </form>

   </div>
   <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="Export" (click)="export()"></p-button>
   </ng-template>
</p-dialog>


<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="user.name" let-idx="index" lt-cell-template="" let-item="">

      <div class="d-flex">
         <div>
            <app-cdn-image [src]="item.user.profile_image"></app-cdn-image>
         </div>
         <div class="ml-3">
            <div> {{item.user.name}}</div>
            <small>{{item.user.email }}</small>
         </div>
      </div>
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex spaced-btns">
         <button type="button" class="btn btn-sm btn-primary" (click)="menu.toggle($event);selectedItem=item">
         <span class="span d-flex align-items-center">
         <i class="fa fa-bars mr-2"></i>Actions
      </span>
         </button>
         <p-menu #menu [popup]="true" appendTo="body" [model]="menuItems"></p-menu>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
   <ng-template field="payment_mode" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.payment_mode"></app-badge>
   </ng-template>
</xs-laravel-data-table>
<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Order</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <app-application-editor (updated)="applicationFormUpdated($event)" [order]="selectedItem"></app-application-editor>
   </div>
   <div class="modal-footer">
      <button (click)="saveApplication()" class="btn btn-primary">{{currentApplication?.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<ng-template #transactionModal let-modal="">
   <div class="modal-header">
      <h5 class="modal-title">Order</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">

      <ul ngbNav #nav="ngbNav" class="nav-tabs">
         <li ngbNavItem>
            <a ngbNavLink>Details</a>
            <ng-template ngbNavContent>
               <div class="row">
                  <div class="col-sm-6">
                     <table class="table table-striped table-bordered table-sm">
                        <tbody>
                        <tr>
                           <td>Program</td>
                           <td>{{selectedItem.program.name}}</td>
                        </tr>
                        <tr>
                           <td>Batch Code</td>
                           <td>{{selectedItem.program.batch_code}}</td>
                        </tr>
                        <tr>
                           <td>Amount Payable</td>
                           <td>{{selectedItem.order_total|currency:'INR'}}</td>
                        </tr>
                        <tr>
                           <td>Coupon Discount
                              <ng-container *ngIf="selectedItem.coupon"><strong>({{selectedItem.coupon.code}})</strong></ng-container>
                           </td>
                           <td>{{selectedItem.order_coupon_discount|currency:'INR'}}</td>
                        </tr>
                        <tr>
                           <td>Payment Mode</td>
                           <td><span [ngClass]="'pm-'+selectedItem.payment_mode">{{selectedItem.payment_mode}}</span></td>
                        </tr>
                        <tr>
                           <td>Payment Status</td>
                           <td><span [ngClass]="'ps-'+selectedItem.status">{{selectedItem.status}}</span></td>
                        </tr>
                        <tr>
                           <td>Amount Paid</td>
                           <td class="text-success">{{selectedItem.total_paid|currency:'INR'}}</td>
                        </tr>
                        <tr *ngIf="getTotalPending()>0">
                           <td>Pending Amount</td>
                           <td class="text-danger">{{selectedItem.pending_amount|currency:'INR'}}</td>
                        </tr>
                        <tr *ngIf="selectedItem.pending_amount>0 && selectedItem.status=='partial'">
                           <td>Due date for pending Amount</td>
                           <td>{{selectedItem.partial_payment_due_date|date:'dd-MMM-yyyy'}}
                              <span class="badge badge-danger" *ngIf="selectedItem.due_days >=0">{{selectedItem.due_days}} Days</span>
                              <span class="badge badge-danger" *ngIf="selectedItem.due_days <0">Overdue</span></td>
                        </tr>
                        </tbody>
                     </table>
                     <div *ngIf="selectedItem.mocks">
                        Mock Tests : {{selectedItem.mocks.join(',')}}
                     </div>
                  </div>
                  <div class="col-sm-6">

                  </div>
               </div>
            </ng-template>
         </li>
         <li ngbNavItem>
            <a ngbNavLink>Application Preview</a>
            <ng-template ngbNavContent>
               <app-enrollment-detail-view [order]="selectedItem"></app-enrollment-detail-view>
            </ng-template>
         </li>
         <li ngbNavItem>
            <a ngbNavLink>Transactions</a>
            <ng-template ngbNavContent>
               <div class="row">
                  <div class="col-sm-8">
                     <table class="table table-vertical-align table-sm ">
                        <tbody>
                        <tr>
                           <th>ID</th>
                           <th>Bank Ref</th>
                           <th>Amount</th>
                           <th>Source</th>
                           <th class="text-uppercase">Status</th>
                           <td colspan="2"></td>

                        </tr>
                        <tr *ngFor="let t of selectedItem.transactions;let idx=index">
                           <td>{{t.id}}</td>
                           <td>{{t.bank_ref_no}}</td>
                           <td>{{t.amount|currency:'INR'}}</td>
                           <td>{{t.bank_or_card_name}}</td>
                           <td>{{t.gateway_status}}</td>
                           <td class="min-width">
                              <a ngbTooltip="Edit" (click)="editTransaction(t,idx)" class="btn text-primary"><i class="fa fa-edit"></i></a>

                           </td>
                           <td class="min-width">
                              <a ngbTooltip="Delete" (click)="deleteTransaction(t,idx)" class="btn text-danger"><i class="fa fa-times"></i></a>
                           </td>
                        </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="col-sm-4">
                     <form [formGroup]="newTransactionForm">
                        <div class="form-group">
                           <label for="">Transaction Id</label>
                           <input formControlName="bank_ref_no" type="text" class="form-control">
                        </div>
                        <div class="form-group">
                           <label for="">Transaction Date</label>
                           <div class="input-group">
                              <input readonly class="form-control" name="dp" formControlName="transaction_date" ngbDatepicker #d="ngbDatepicker">
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                              </div>
                           </div>
                        </div>
                        <div class="form-group">
                           <label for="">Amount</label>
                           <input formControlName="amount" type="text" class="form-control" appNumberOnly>
                        </div>
                        <div class="d-flex">
                           <button *ngIf="newTransactionForm.value.id" type="button" (click)="cancelTransactionUpdate()" class="btn btn-secondary mr-2  btn-primary">Cancel</button>
                           <button type="button" (click)="addTransaction()" class="btn   btn-primary">{{newTransactionForm.value.id ? 'Update' : 'Add'}} Transaction</button>

                        </div>

                     </form>
                  </div>
               </div>

            </ng-template>
         </li>
         <li ngbNavItem>
            <a ngbNavLink>User Detail</a>
            <ng-template ngbNavContent>
               <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                     <td style="width: 150px;">User ID</td>
                     <td>{{selectedItem.user.id}}</td>
                  </tr>
                  <tr>
                     <td>Name</td>
                     <td>{{selectedItem.user.name}}</td>
                  </tr>
                  <tr>
                     <td>Email</td>
                     <td>{{selectedItem.user.email}}</td>
                  </tr>
                  <tr>
                     <td>Phone</td>
                     <td>{{selectedItem.user.phone_no}}</td>
                  </tr>
                  <tr>
                     <td>DOB</td>
                     <td>{{selectedItem.user.date_of_birth}}</td>
                  </tr>
                  <tr>
                     <td>Gender</td>
                     <td>{{selectedItem.user.gender}}</td>
                  </tr>
                  <tr>
                     <td>Handicapped</td>
                     <td>{{selectedItem.user.handicapped == 1 ? 'YES' : 'NO'}}</td>
                  </tr>
                  <tr>
                     <td>Pincode</td>
                     <td>{{selectedItem.user.pin_code}}</td>
                  </tr>
                  <tr>
                     <td>Father's Name</td>
                     <td>{{selectedItem.user.father_name}}</td>
                  </tr>
                  </tbody>
               </table>
            </ng-template>
         </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>


   </div>

</ng-template>

