import {Injectable} from '@angular/core';
import {environment} from '../../../../src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ImageResize {

    public endpoint = '//images.weserv.nl/';

    constructor() {
    }

    public resize(url, w, h, p = 'top') {
        if (url) {
            url = url.replace('public', 'storage');
            return environment.website + '/ir/fit?path=' + url + '&w=' + w + '&h=' + h;
        } else {
            return 'https://via.placeholder.com/150x100?text=NULL';
        }
    }

    public resizeWithRatio(url, w, h) {
        if (url) {
            url = url.replace('public', 'storage');
            return environment.website + '/ir/resize?path=' + url + '&w=' + w + '&h=' + h;
        } else {
            return `https://via.placeholder.com/${w}x${h}?text=NULL`;
        }
    }

}
