import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAdManagerConfig, ManageAdsComponent} from '../manage-ads/manage-ads.component';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-exam-analysis',
   templateUrl: './manage-exam-analysis.component.html',
   styleUrls: ['./manage-exam-analysis.component.scss']
})
export class ManageExamAnalysisComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId = 40;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/exam-analysis/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Exam Name', key: 'exam_name'},
         {name: 'Exam Year', key: 'exam_year'},
         {
            name: 'Course',
            type: 'select',
            key: 'course.course_name',
            filterKey: 'main_course_id',
            selectOptions: this.api.endExamFilter()
         },
         {name: 'Subject', key: 'subject_name'},
         {name: 'Exam Year', key: 'exam_year'},
         {name: 'Status', key: 'status', render: (val) => `<span class="status-${val}">${val}</span>`, width: 'min'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},

      ],
      actions: [
         {
            name: 'Advertisement',
            type: 'info',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(ManageAdsComponent);
               const config: IAdManagerConfig = {
                  adType: 'exam-analysis',
                  isModal: true,
                  parent: dataset[index].id,
                  title: dataset[index].exam_name

               };
               modalRef.componentInstance.setConfig(config);

            }
         },
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].exam_name + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/exam-analysis/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe((res: any) => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public categoryTree = [];

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         main_course_id: new UntypedFormControl('', Validators.compose([])),
         exam_name: new UntypedFormControl('', Validators.compose([])),
         subject_name: new UntypedFormControl('', Validators.compose([])),
         exam_year: new UntypedFormControl('', Validators.compose([])),
         description: new UntypedFormControl('', Validators.compose([])),
         pdf: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         bdrt: new UntypedFormControl('', Validators.compose([])),
         //add
      });

      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl()
      });
   }

   loadTree() {
      this.api.post('admin/free-resource-course/get-tree', {})
         .subscribe((res: any) => this.categoryTree = res);
   }

   ngOnInit() {
      this.loadPage();
      this.loadTree();
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/exam-analysis/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   saveBdrt() {
      this.loader.start();
      this.api.post('admin/page/save', this.pageForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
         });
   }

   loadPage() {
      this.loader.start();
      this.api.get('admin/page/' + this.pageId, {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.pageForm.patchValue(res);
         });
   }

}
