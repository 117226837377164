import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
   selector: 'app-cdn-image',
   templateUrl: './cdn-image.component.html',
   styleUrls: ['./cdn-image.component.scss']
})
export class CdnImageComponent implements AfterViewInit, OnChanges {
   @Input() src;
   @Input() width = 100;
   @Input() height = 0;

   public finalUrl;

   constructor() {
   }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.process();
      });
   }
/**/
   process() {
      if (this.src)
         this.finalUrl = 'https://ik.imagekit.io/aqcg4zaiu7s/sadda-punjab/' + this.src.replace('public/', '') + '?tr=fo-auto,w-' + this.width + ',h-' + this.height;
      else
         this.finalUrl = 'https://ik.imagekit.io/aqcg4zaiu7s/sadda-punjab/t.jpg?tr=fo-auto,w-' + this.width + ',h-' + this.height;
   }

   ngOnChanges(changes: SimpleChanges): void {
      this.process();
   }
}
