<button class="btn btn-primary mb-2" (click)="f.click()">{{label?label:'Select Images'}}</button>
<input (change)="upload($event)" type="file" accept=".jpg,.jpeg,.png" #f multiple>
<div *ngIf="progress" class="progress" [style.width.%]="progress"></div>

<div class="thumb-list" *ngIf="!standAlone">
    <div class="row">
        <div class="col-4 mb-2" *ngFor="let img of value">
            <img [src]="photoService.exact(img,100,100)" alt="">
            <a class="text-danger btn-block text-transform" (click)="remove(img)">remove</a>
        </div>
    </div>

</div>
