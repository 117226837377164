import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {fchmod} from 'fs';
import {ApiService} from '../../services/api.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {finalize} from 'rxjs/operators';

@Component({
   selector: 'app-manage-settings',
   templateUrl: './manage-settings.component.html',
   styleUrls: ['./manage-settings.component.scss']
})
export class ManageSettingsComponent implements OnInit {

   public form: UntypedFormGroup;

   constructor(public api: ApiService, public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         social: new UntypedFormGroup({
            facebook: new UntypedFormControl(),
            twitter: new UntypedFormControl(),
            instagram: new UntypedFormControl(),
            youtube: new UntypedFormControl(),
            whatsapp: new UntypedFormControl(),
            telegram: new UntypedFormControl(),
            linkedin: new UntypedFormControl(),
         }),
         contact: new UntypedFormGroup({
            address: new UntypedFormControl(),
            phone: new UntypedFormControl(),
            email: new UntypedFormControl()
         }),
         copyright: new UntypedFormControl(),
         test_gateways: new UntypedFormGroup({
            online: new UntypedFormControl(),
            offline: new UntypedFormControl(),
         }),
         tax: new UntypedFormGroup({
            programs: new UntypedFormControl()
         })
      });
   }

   ngOnInit() {
      this.api.post('admin/settings', {key: 'settings'})
         .subscribe(res => {
            this.form.patchValue(res);
         });
   }

   save() {
      this.loader.start();

      this.api.post('admin/settings/save', {key: 'settings', value: this.form.value})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe(res => {

         });
   }
}
