import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManageTestsListingComponent} from './manage-tests-listing.component';
import {XsLaravelDataTableModule} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.module';
import {QuestionPaperDisplayModule} from '../../../../projects/question-paper-display/src/lib/question-paper-display.module';
import {RouterModule} from '@angular/router';
import {SafePipeModule} from 'safe-pipe';
import {NgbDropdownMenu} from '@ng-bootstrap/ng-bootstrap';
import {MenuModule} from 'primeng/menu';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {NookUiModule} from '../../nook-ui/nook-ui.module';
import {SidebarModule} from 'primeng/sidebar';
import {CheckboxGroupModule} from '../../../../projects/checkbox-group/src/lib/checkbox-group.module';
import {AppModule} from '../../app.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule} from '@angular/forms';
import {DialogModule} from 'primeng/dialog';

@NgModule({
   declarations: [ManageTestsListingComponent],
   imports: [
      CommonModule,
      NookUiModule,
      SafePipeModule,
      XsLaravelDataTableModule,
      QuestionPaperDisplayModule,
      ConfirmationPopoverModule,
      MenuModule,
      SidebarModule,
      CheckboxGroupModule,
      RouterModule.forChild([
         {path: '', component: ManageTestsListingComponent}
      ]),
      AutoCompleteModule,
      FormsModule,
      DialogModule
   ]
})
export class TestListingModule {}
