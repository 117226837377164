import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

interface IBdrt {
    title: string;
    url: string;
    descriptor: string;
    banner: string;
    type: string;
}

@Component({
    selector: 'app-bdrt',
    templateUrl: './bdrt.component.html',
    styleUrls: ['./bdrt.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BdrtComponent),
            multi: true
        }
    ]
})
export class BdrtComponent implements OnInit, ControlValueAccessor {
    @Input() hideContent = false;
    public value: IBdrt;
    public form: UntypedFormGroup;
    public propagate = (arg) => {
    };

    constructor() {
        this.form = new UntypedFormGroup({
            title: new UntypedFormControl(),
            descriptor: new UntypedFormControl(),
            descriptor_link: new UntypedFormControl(),
            content: new UntypedFormControl(),
            banner: new UntypedFormControl(),
            layout: new UntypedFormControl(),
        });
    }

    ngOnInit() {
        this.form.valueChanges.subscribe(res => {
            this.propagate(this.form.value);
        });
    }

    registerOnChange(fn: any): void {
        this.propagate = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: IBdrt): void {
        if (obj) {
            this.form.patchValue(obj);
        }
    }

}
