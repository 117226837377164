import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../BaseComponent';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-gallery',
   templateUrl: './manage-gallery.component.html',
   styleUrls: ['./manage-gallery.component.scss']
})
export class ManageGalleryComponent extends BaseComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/gallery/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'title', type: 'text', width: ''},
         {
            name: 'Type',
            key: 'type',
            type: 'select',
            filterKey: 'type',
            selectOptions: of([
               {label: 'Image', value: 'image'},
               {label: 'Youtube Video', value: 'youtube-video'},
               {label: 'Video Upload', value: 'video-upload'},
            ]),
            render: (val: string) => {
               return val ? val.toUpperCase() : '';
            }
         },
         {
            name: 'Status', key: 'status', type: 'select', width: 'min',
            selectOptions: of([
               {label: 'Publish', value: 'publish'},
               {label: 'Draft', value: 'draft'},
            ])
         },
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST'},
      ],

   };
   public form: UntypedFormGroup;
   public pageId = 12;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      super(toastr, api, auth, router, modal, loader);
      this.loadPage();

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         title: new UntypedFormControl('', Validators.compose([Validators.required])),
         video_url: new UntypedFormControl('', Validators.compose([])),
         featured_data: new UntypedFormControl('', Validators.compose([Validators.required])),
         type: new UntypedFormControl('', Validators.compose([Validators.required])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
      });
   }

   ngOnInit() {
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({
         status: 'publish',
         type: 'image'
      });
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/gallery/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   edit(item) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(this.addNewModal);
   }

   delete(item, index) {
      this.loader.start();
      this.api.post('admin/gallery/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.table.removeItemAtIndex(index);
         });

   }
}
