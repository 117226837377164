import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function confirmPassword(control: AbstractControl) {
  const password = control.get('password').value;
  const confirmPassword = control.get('confirm_password').value;
  if (password != confirmPassword) {
    control.get('confirm_password').setErrors({passwordsNotMatch: true})
    control.get('confirm_password').markAsTouched()
    control.get('confirm_password').markAsDirty()
  } else {
    control.get('confirm_password').setErrors(null)
    control.get('confirm_password').markAsTouched()
    control.get('confirm_password').markAsDirty()
  }
  return null;
}
