<div class="row front-preview" *ngIf="order">
   <div class="col-sm-12">
      <h5>Applied For : <strong>{{order.program.name}}</strong></h5>
   </div>
   <div class="col-sm-8">

      <div class="simple-label"><label class="mr-3">Name: </label>{{application.name}}</div>
      <div class="simple-label"><label class="mr-3">Date of birth: </label>{{application.date_of_birth}}</div>
      <div class="simple-label"><label class="mr-3">Gender: </label>{{application.gender}}</div>
      <div class="simple-label"><label class="mr-3">Category: </label>{{application.category}}</div>
      <div class="simple-label"><label class="mr-3">Handicapped: </label>{{application.handicapped}}</div>
      <div class="simple-label"><label class="mr-3">Father's name: </label>{{application.father_name}}</div>
      <div class="simple-label"><label class="mr-3">Father's Occupation: </label>{{application.father_occupation}}</div>
      <div class="simple-label"><label class="mr-3">Mother's Occupation: </label>{{application.mother_name}}</div>
      <div class="simple-label"><label class="mr-3">AADHAAR Number: </label>{{application.aadhaar_number}}</div>
   </div>
   <div class="col-sm-4">
      <img [src]="photoService.resize(application.profile_image,150,150) " alt="">
   </div>

   <div class="col-sm-12"><h5 class="a-preview">Contact Details</h5></div>
   <div class="col-sm-4">Mobile Number 1: {{application.mobile_1}}</div>
   <div class="col-sm-4">Mobile Number 2: {{application.mobile_2}}</div>
   <div class="col-sm-4">Landline: {{application.landline}}</div>
   <div class="col-sm-12">Email: {{application.email}}</div>


   <div class="col-sm-6">
      <div class="address-area">
         <h5>Permanent Address</h5>
         <div> {{ application.p_address}}</div>
         <div>Landmark:{{application.p_landmark}}</div>
         <div>Pincode: {{application.p_pincode}}</div>
         <div>City: {{application.p_city}}</div>
         <div>District:{{application.p_district}}</div>
         <div>State: {{application.p_state}}</div>
      </div>
   </div>
   <div class="col-sm-6">
      <div class="address-area">
         <h5>Correspondence Address</h5>
         {{ application.c_address}}
         <div>Landmark:{{application.c_landmark}}</div>
         <div>Pincode: {{application.c_pincode}}</div>
         <div>City: {{application.c_city}}</div>
         <div>District:{{application.c_district}}</div>
         <div>State: {{application.c_state}}</div>
      </div>

   </div>
   <div class="col-sm-12"><a class="btn btn-primary btn-block" *ngIf="application.proof" target="_blank" [href]="url.url(application.proof)"> View Address Proof</a>
   </div>
   <div class="col-sm-12">
      <h5>Educational Qualifications</h5>
   </div>
   <div class="col-sm-12">
      <div class="text-uppercase  font-weight-bold mb-2">Graduate: {{application.graduate ? 'YES' : 'NO'}} <span style="margin-right: 30px;"></span>
         Undergraduate: {{application.graduate ? 'NO' : 'YES'}} </div>
      <div class="q-title">MATRIC</div>
      <!-- MATRIC  -->
      <div class="row">
         <div class="col-lg-3 col-sm-6 col-12">
            <label>Board: <strong>{{application.matric_board}}</strong></label>
         </div>
         <div class="col-lg-3 col-sm-6 col-12">
            <label>Pass Year: <strong>{{application.matric_pass_year}}</strong></label>
         </div>
         <div class="col-lg-3 col-sm-6 col-12">
            <label>Pass Percentage: <strong>{{application.matric_percentage}}%</strong></label>
         </div>
         <div class="col-lg-3 col-sm-6 col-12">
            <label>Certificate: <strong>{{application.matric_certificate ? 'YES' : 'NO' }} </strong></label>
            <a *ngIf="application.matric_certificate" target="_blank" [href]="url.url(application.matric_certificate)"> View </a>
         </div>
      </div>


      <ng-container *ngIf="application.graduate==1">
         <div class="q-title">Graduation - <strong>{{application.graduation_stream}}</strong></div>
         <div class="row">
            <div class="col-12">
               <label style="margin-bottom: 2px;">University : </label>
               <span class="font-weight-bold">{{application.graduation_university}}</span>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
               <label style="margin-bottom: 2px;">Pass Year : </label>
               <span class="font-weight-bold">{{application.graduation_pass_year }}</span>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
               <label style="margin-bottom: 2px;">Pass Percentage : </label>
               <span class="font-weight-bold">{{application.graduation_percentage }}%</span>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
               <label style="margin-bottom: 2px;">Certificate : </label>
               <span class="font-weight-bold">{{application.graduation_certificate ? 'YES' : 'NO' }}</span>
               <a *ngIf="application.graduation_certificate" target="_blank" [href]="url.url(application.graduation_certificate)"> View </a>

            </div>

         </div>
      </ng-container>
      <ng-container *ngIf="application.graduate!=1">
         <div class="q-title">POST MATRIC (+2)  {{application.post_matric != 1 ? 'appearing' : ''}} </div>
         <div class="row">
            <div class="col-12">
               <label>Board: <strong>{{application.post_matric_board}}</strong></label>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
               <label>Pass Year: <strong>{{application.post_matric_pass_year}}</strong></label>
            </div>

            <ng-container *ngIf="application.post_matric!=1">
               <div class="col-lg-3 col-sm-6 col-12">
                  <label>Pass Percentage: <strong>{{application.post_matric_percentage}}%</strong></label>
               </div>
               <div class="col-lg-3 col-sm-6 col-12">
                  <label>Certificate: <strong>{{application.post_matric_certificate ? 'YES' : 'NO'}} </strong></label>
                  <a *ngIf="application.post_matric_certificate" target="_blank" [href]="url.url(application.post_matric_certificate)"> View </a>

               </div>

            </ng-container>
         </div>

         <ng-container *ngIf="application.post_matric!=1">

            <div class="q-title">Graduation (appearing) - {{application.graduation_appearing_stream}}</div>
            <div class="row">
               <div class="col-lg-6 col-sm-6 col-12">
                  <label>University: <strong>{{application.graduation_appearing_university}}</strong></label>
               </div>
               <div class="col-lg-3 col-12">
                  <label>Pass Year: <strong>{{application.graduation_appearing_university}}</strong></label>
               </div>
            </div>
         </ng-container>


      </ng-container>
   </div>

   <div class="col-sm-12">
      <h5> Signature</h5>
      <img [src]="photoService.resize(application.sign,150,150) " alt="">
   </div>

   <div class="col-sm-12">
      <a class="btn btn-primary mb-4 btn-block" *ngIf="application.pcs_application_form" target="_blank" [href]="url.url(application.pcs_application_form)"> View PCS application Form</a>
<a class="btn btn-primary mb-4 btn-block" *ngIf="application.bio_data_sheet" target="_blank" [href]="url.url(application.bio_data_sheet)"> View Bio Data Sheet</a>


   </div>
</div>
