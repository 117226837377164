<div class="d-flex mb-3">
    <button (click)="file.click()" class="btn  mr-2 btn-info" target="_blank">{{label}} <span *ngIf="progress">{{progress + '%'}}</span></button>
    <ng-container *ngIf="value">
        <a target="_blank" ngbTooltip="View File" style="cursor: pointer" [href]="api.getFileUrl(value)" class="btn btn-primary" target="_blank"><i class="fa  fa-eye"></i></a>
        <button ngbTooltip="Clear" style="cursor: pointer" (click)="clear()" class="btn btn-danger" target="_blank"><i class="fa  fa-times  "></i></button>
    </ng-container>
</div>


<input [accept]="formats" type="file" (change)="iconUpload($event)" #file class="d-none">
