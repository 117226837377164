import {Component, Input, OnInit} from '@angular/core';

@Component({
   selector: 'lib-question-paper-display',
   templateUrl: 'question-paper-display.html',
   styleUrls: ['style.scss']
})
export class QuestionPaperDisplayComponent implements OnInit {

   @Input() test;
   public legend = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

   constructor() { }

   ngOnInit(): void {
   }

}
