import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManageTestCouponComponent} from './manage-test-coupon.component';
import {XsLaravelDataTableModule} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderModule, NgxUiLoaderService} from 'ngx-ui-loader';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {NookUiModule} from '../../nook-ui/nook-ui.module';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {AutoCompleteModule} from 'primeng/autocomplete';

@NgModule({
   declarations: [ManageTestCouponComponent],
    imports: [
        CommonModule,
        XsLaravelDataTableModule,
        NgbModalModule,
        RouterModule.forChild([{
            path: '',
            component: ManageTestCouponComponent
        }]),
        NgxUiLoaderModule,
        ReactiveFormsModule,
        NookUiModule,
        CalendarModule,
        DialogModule,
        ToggleButtonModule,
        SelectButtonModule,
        ConfirmationPopoverModule,
        AutoCompleteModule

    ]
})
export class ManageTestCouponModule {}
