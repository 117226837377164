import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AuthService} from '../services/auth.service';
import {finalize} from 'rxjs/operators';
import {confirmPassword} from '../_shared/validators/confirm-password';
import {ErrorTailorModule} from '@ngneat/error-tailor';

@Component({
   selector: 'app-update-password',
   standalone: true,
   imports: [CommonModule, ReactiveFormsModule, ErrorTailorModule],
   template: `
       <div class="container py-10">
           <div class="grid grid-cols-2 prose max-w-none gap-4">

               <div class="border p-4">
                   <h2 class="mt-0 uppercase">Update Password</h2>
                   <form [formGroup]="passwordForm" errorTailor (ngSubmit)="savePassword()">
                       <div class="form-group  mb-4">
                           <label for="" class="form-label">Current Password</label>
                           <input type="text" formControlName="current_password" class="form-control">
                       </div>
                       <div class="form-group  mb-4">
                           <label for="" class="form-label">New Password</label>
                           <input type="text" formControlName="password" class="form-control"
                                  (change)="passwordForm.markAllAsTouched()"
                                  [controlErrors]="{
              minlength:'Password is short (minimum 6 characters)'
              }">
                       </div>
                       <div class="form-group  mb-4">
                           <label for="" class="form-label">Confirm Password</label>
                           <input type="text" formControlName="confirm_password" class="form-control">
                       </div>

                       <button type="submit" style="background-color: green;" class="btn mt-4 px-4 py-2 bg-green-500 text-white rounded">Update Password
                       </button>
                   </form>
               </div>
           </div>
       </div>
   `,
   styles: []
})
export class UpdatePasswordComponent {

   constructor(public api: ApiService, public loader: NgxUiLoaderService, public authService: AuthService) {
      this.loader.start();
      this.getMyProfile();
   }


   public form = new FormGroup({
      name: new FormControl<string>('', [Validators.required]),
      profile_image: new FormControl<string>('', [Validators.required]),
   })
   public passwordForm = new FormGroup({
      current_password: new FormControl<string>('', [Validators.required]),
      password: new FormControl<string>('', [Validators.required, Validators.minLength(6)]),
      confirm_password: new FormControl<string>('', [Validators.required]),
   }, confirmPassword)

   private getMyProfile() {
      this.api.get('admin/my-profile', {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.form.patchValue(res);
         })
   }

   saveProfile() {
      if (this.form.invalid) {
         this.form.markAllAsTouched();
         return;
      }
      this.loader.start();
      this.api.post('admin/save-profile', this.form.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.getMyProfile();
         })
   }

   savePassword() {
      if (this.passwordForm.invalid) return;
      this.loader.start();
      this.api.post('admin/update-password', this.passwordForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.passwordForm.reset();
         })
   }
}
