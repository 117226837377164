<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Current Affair</h3>
   <div>
      <button class="btn btn-success mr-2" (click)="add(bdrt)"> EDIT BDRT</button>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-primary btn-sm" (click)="edit(item)">Edit</button>
         <button class="btn btn-secondary btn-sm" [routerLink]="['comments']" [queryParams]="{title:item.title_name,id:item.id}">Comments
            <span *ngIf="item.unread_comments>0" class="badge badge-danger">{{item.unread_comments}}</span></button>
         <button class="btn btn-info btn-sm" (click)="manageAds(item)">Manage Ads</button>
         <button class="btn btn-danger btn-sm" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Current Affair</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id" class="form-control">
         <div class="row">
            <div class="col-sm-9">
               <div class="row">

                  <div class="col-sm-12">
                     <div class="section">BDRT</div>
                     <app-bdrt formControlName="bdrt"></app-bdrt>
                  </div>

                  <div class="col-6">
                     <div class="form-group">
                        <label for="">TITLE NAME</label>
                        <input type="text" formControlName="title_name" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label for="">SUB TITLE</label>
                        <input type="text" formControlName="sub_title" class="form-control">
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="form-group">
                        <label for="">Content</label>
                        <app-editor formControlName="desc"></app-editor>
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label for="">NEWS LINK</label>
                        <input type="text" formControlName="read_more_url" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label for="">TOPIC DATE</label>
                        <app-prime-date-time-picker formControlName="created_at"></app-prime-date-time-picker>
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label class="d-block">STATUS</label>
                        <app-status formControlName="status"></app-status>
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label class="d-block">Protected</label>
                        <app-toggle-switch label1="Protected" label0="Unprotected" formControlName="protected"></app-toggle-switch>
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label for="">SORT ORDER</label>
                        <input type="text" formControlName="sort_order" class="form-control">
                     </div>
                  </div>


               </div>
            </div>
            <div class="col-sm-3">
               <label for="" class="d-block">Featured Image</label>
               <app-photo-uploader formControlName="featured_image" dir="current-affairs"></app-photo-uploader>

               <div class="section">Categories</div>
               <app-current-affair-category-selector formControlName="category"></app-current-affair-category-selector>
            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
<ng-template #bdrt let-modal="">
   <div class="modal-header">
      <h5 class="modal-title"></h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="bdrtForm">

         <app-bdrt formControlName="bdrt"></app-bdrt>

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{bdrtForm.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
