import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, DefaultValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
   selector: 'app-date-picker',
   template: `
      <div class="d-flex">
         <div class="control-group">
            <label class="d-block">{{label}}</label>

            <div class="d-flex">
               <div class="input-group">
                  <input
                     [maxDate]="maxDate"
                     [minDate]="minDate"
                     placeholder="d/m/yyyy" [(ngModel)]="date" (dateSelect)="selected()" ngbDatepicker #dp="ngbDatepicker" type="email" class="form-control">
                  <div class="input-group-append">
                     <button *ngIf="date" class="btn btn-outline-secondary text-danger" type="button" ngbTooltip="Clear" (click)="clearField()"><i class="fa fa-times"></i></button>
                     <button class="btn btn-outline-secondary calendar" type="button" (click)="dp.toggle()"><i class="fa fa-calendar"></i></button>
                  </div>
               </div>
               <div class="ml-3" *ngIf="timepicker">
                  <form [formGroup]="form">
                     <ngb-timepicker formControlName="time" [spinners]="false" [meridian]="true"></ngb-timepicker>
                  </form>
               </div>
            </div>
         </div>
      </div>

   `,
   styleUrls: ['./date-picker.component.scss'],
   providers: [{
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
   }, DatePipe]
})
export class DatePickerComponent implements ControlValueAccessor {

   @Input() label;
   @Input() format = null;
   @Input() maxDate;
   @Input() minDate;
   @Input() timepicker = false;

   public date;
   public time;

   public parsedValue = null;

   public form;
   public emit = (_any) => {
   };

   constructor(public datePipe: DatePipe) {
      this.form = new UntypedFormGroup({
         time: new UntypedFormControl()
      });
      this.form.valueChanges.subscribe(res => {
         this.selected();
      });

   }

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {
      if (obj) {
         if (this.timepicker === false) {
            this.date = obj;
            return;
         }

         // date is need in m-d-yyyy and out put is in yyyy-mm-dd
         const indianTime = this.datePipe.transform(obj, 'yyyy-MM-dd hh:mm:ss', '530');
         const dateAndTime = indianTime.split(' ');
         const time = dateAndTime[1].split(':');
         const date = dateAndTime[0].split('-');
         this.date = date[2] + '-' + date[1] + '-' + date[0];

         setTimeout(() => {
            this.form.patchValue({
               time: {hour: 0 + +time[0], minute: 0 + +time[1], second: 0 + +time[2]}
            });
            this.selected();
         });
      }
   }

   selected() {
      const t = {hour: '0', minute: '0', second: '0'};
      const ft = this.form.value.time;

      if (ft) {
         t.hour = ('00' + ft.hour).slice(-2);
         t.minute = ('00' + ft.minute).slice(-2);
         t.second = ('00' + ft.minute).slice(-2);
      }

      if (!this.date) {
         this.emit(null);
         this.parsedValue = null;
         return;
      }

      this.parsedValue = this.timepicker ? this.date + ' ' + t.hour + ':' + t.minute + ':' + '00' : this.date;
      this.emit(this.parsedValue);
   }

   clearField() {
      this.date = null;
      this.selected();
   }
}
