import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-manage-contact-page',
    templateUrl: './manage-contact-page.component.html',
    styleUrls: ['./manage-contact-page.component.scss']
})
export class ManageContactPageComponent implements OnInit {

    public page;
    public form: UntypedFormGroup;
    public pageId = 12;

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public loader: NgxUiLoaderService) {

        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(this.pageId),
            bdrt: new UntypedFormControl(),
            meta: new UntypedFormGroup({
                associate_title: new UntypedFormControl(),
                associate_content: new UntypedFormControl(),
                callback_title: new UntypedFormControl(),
                callback_content: new UntypedFormControl(),
                callback_accept_message: new UntypedFormControl(),
                concern_title: new UntypedFormControl(),
                concern_content: new UntypedFormControl(),
                happiness_title: new UntypedFormControl(),
                happiness_content: new UntypedFormControl(),
                partner_title: new UntypedFormControl(),
                partner_content: new UntypedFormControl(),
                touch_title: new UntypedFormControl(),
                touch_address: new UntypedFormControl(),
                touch_phone: new UntypedFormControl(),
                touch_email: new UntypedFormControl(),
            })
        });

        this.api.get('admin/page/' + this.pageId, {}).subscribe(res => {
            this.form.patchValue(res);
        });
    }

    ngOnInit(): void {

    }

    save() {
        this.api.post('admin/page/save', this.form.value).subscribe(res => {

        });
    }
}
