import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';

import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-top-ranker-category',
   templateUrl: './manage-top-ranker-category.component.html',
   styleUrls: ['./manage-top-ranker-category.component.scss']
})
export class ManageTopRankerCategoryComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public formErrors;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/top-ranker-category/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'name', type: 'text',},
         {name: 'Created', key: 'created_at', type: 'dateIST'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
      ]
   }; //
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         sort_order: new UntypedFormControl()
      });
   }


   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/top-ranker-category/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         }, (e) => {
            this.formErrors = e.errors;
         });
   }


   edit(item, index) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(this.addNewModal);
   }

   delete(item, index) {
      this.loader.start();
      this.api.post('admin/top-ranker-category/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(index);
         });
   }
}
