import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
   selector: 'app-editor',
   templateUrl: './editor.component.html',
   styleUrls: ['./editor.component.scss'],
   providers: [
      {
         multi: true,
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => EditorComponent),
      }
   ]
})
export class EditorComponent implements ControlValueAccessor, OnDestroy {

   public froala;
   public value = '';
   public emit = (_: any) => {
   };

   constructor(public auth: AuthService) {
   }

   public options = {
      key: 'AV:4~?3xROKLJKYHROLDXDR@d2YYGR_Bc1A8@5@4:1B2D2F2F1?1?2A3@1C1',//
      toolbarButtons: {
         moreText: {
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'insertTable', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOLSimple', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote', 'insertLink', 'insertImage', 'insertVideo', 'fontAwesome', 'embedly', 'insertHR', 'selectAll', 'fullscreen'],
            buttonsVisible: 100
         }
      },
      attribution: false,
      toolbarSticky: false,
      fontFamily: {
         HindiFont: 'Hindi Font',
         Asees: 'Asses',
         AnmolLipi: 'Anmol',
         raavi: 'Raavi Lipi',
         'Times New Roman': 'Times New Roman',
         'Open Sans': 'Open sans',
         Arial: 'Arial',
         'Roboto,sans-serif': 'Roboto',
         'Oswald,sans-serif': 'Oswald',
         'Montserrat,sans-serif': 'Montserrat',
         '\'Open Sans Condensed\',sans-serif': 'Open Sans Condensed'
      },
      fontFamilySelection: true,
      imageUploadParam: 'icon',
      imageUploadURL: environment.endpoint + 'admin/upload-image',
      requestHeaders: {
         Authorization: 'Bearer ' + this.auth.user.api_token,
      },
      minHeight: 200,
      heightMax: 600,
   };

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {

   }

   writeValue(obj: any): void {
      if (obj) {
         this.value = obj;
      }
   }

   changed() {
      this.emit(this.value);
   }

   public initialize(initControls) {
      this.froala = initControls;
      setTimeout(() => {
         initControls.initialize();
      }, 10);

   }

   ngOnDestroy() {
      this.froala.destroy();
   }

   reinit() {
      this.froala.initialize();
   }
}
