import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ICheckBoxItem} from '../interfaces/ICheckBoxItem';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
   selector: 'lib-checkbox-group',
   templateUrl: 'checkbox.html',
   styleUrls: ['style.scss'], providers: [{
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
   }]
})

export class CheckboxGroupComponent implements ControlValueAccessor {

   @Input() items: ICheckBoxItem[] = [{label: 'x', value: 'b'}];
   @Input() label: string;
   public value = [];

   public emit = (_any) => {
   };

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj): void {
      if (obj) {
         this.value = obj;
      }
   }

   updateValue() {
      this.emit(this.value);
   }

   updated(v) {
      const index = this.value.indexOf(v);
      index > -1 ? this.value.splice(index, 1) : this.value.push(v);
      this.value = [...this.value];
      this.updateValue();
   }
}
