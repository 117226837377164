import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-icon-uploader',
    templateUrl: './icon-uploader.component.html',
    styleUrls: ['./icon-uploader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IconUploaderComponent),
            multi: true
        }
    ]
})
export class IconUploaderComponent implements OnInit, ControlValueAccessor {
    @Input()
    public icon: string;
    public filePath;
    public progress = 0;
    @Output() uploaded = new EventEmitter();
    public propagateChange = (_: any) => {
    };

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public loader: NgxUiLoaderService) {

    }

    writeValue(obj: any): void {
       // console.log(obj);
        if (obj) {
            this.filePath = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {

    }

    setDisabledState?(isDisabled: boolean): void {

    }

    ngOnInit() {
    }

    iconUpload($event) {
        const icon = $event.target.files[0];
        const formData = new FormData();
        formData.append('icon', icon);
        this.api.upload('admin/icon-upload', formData).subscribe((res: any) => {
            if (res.status === 'progress') {
                this.progress = res.message;

            } else {
                if (res.files) {
                    this.progress = 0;
                    this.propagateChange(res.files.icon);
                    this.filePath = res.files.icon;
                    this.uploaded.emit({
                        path: res.files.icon,
                        url: res.files.url
                    });
                }

            }
        });
    }

}
