<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="121px" height="30px"
     viewBox="0 30 100 30"
>
   <g transform="translate(14.285714285714286 50)">
      <circle cx="0" cy="0" r="6" fill="#e15b64">
         <animateTransform attributeName="transform" type="scale" begin="-0.3086419753086419s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                           dur="0.7407407407407407s" repeatCount="indefinite"></animateTransform>
      </circle>
   </g>
   <g transform="translate(28.571428571428573 50)">
      <circle cx="0" cy="0" r="6" fill="#f8b26a">
         <animateTransform attributeName="transform" type="scale" begin="-0.24691358024691354s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                           dur="0.7407407407407407s" repeatCount="indefinite"></animateTransform>
      </circle>
   </g>
   <g transform="translate(42.857142857142854 50)">
      <circle cx="0" cy="0" r="6" fill="#abbd81">
         <animateTransform attributeName="transform" type="scale" begin="-0.18518518518518515s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                           dur="0.7407407407407407s" repeatCount="indefinite"></animateTransform>
      </circle>
   </g>
   <g transform="translate(57.142857142857146 50)">
      <circle cx="0" cy="0" r="6" fill="#81a3bd">
         <animateTransform attributeName="transform" type="scale" begin="-0.12345679012345677s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                           dur="0.7407407407407407s" repeatCount="indefinite"></animateTransform>
      </circle>
   </g>
   <g transform="translate(71.42857142857143 50)">
      <circle cx="0" cy="0" r="6" fill="#e15b64">
         <animateTransform attributeName="transform" type="scale" begin="-0.061728395061728385s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                           dur="0.7407407407407407s" repeatCount="indefinite"></animateTransform>
      </circle>
   </g>
   <g transform="translate(85.71428571428571 50)">
      <circle cx="0" cy="0" r="6" fill="#f8b26a">
         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="0.7407407407407407s"
                           repeatCount="indefinite"></animateTransform>
      </circle>
   </g>
</svg>
