import {AfterViewChecked, AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Constants} from '../../Constants';
import {PhotoService} from '../../services/photo.service';
import {BaseComponent} from '../BaseComponent';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-expert',
   templateUrl: './manage-expert.component.html',
   styleUrls: ['./manage-expert.component.scss']
})
export class ManageExpertComponent extends BaseComponent {

   @ViewChild('addNew') addNewModal;
   @ViewChild('mpic') pic: TemplateRef<any>;
   @ViewChild('table') table;
   public editorOptions = Constants.editorOptions;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/teacher/list?type=expert';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'name', type: 'text',},
         {name: 'Qualification', key: 'qualification',},
         {name: 'Created', key: 'created_at', type: 'dateIST'},
         {name: 'Status', key: 'status', type: 'text', width: 'min'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);
            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/teacher/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe(() => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ],
      sortFn: (item) => {
         this.toastr.info('Updating..', null, {
            timeOut: 800
         });
         this.api.post('admin/teacher/save', item)
            .pipe(finalize(() => {
               this.loader.stop();
            }))
            .subscribe((res: any) => {
               this.toastr.success('Sort Order Updated', null, {
                  timeOut: 800
               });
            });
         return void (0);
      }
   };

   public form: UntypedFormGroup;
   public pageForm;
   public pageId = 15;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public photoService: PhotoService,
               public loader: NgxUiLoaderService) {
      super(toastr, api, auth, router, modal, loader);
      this.loadPage();

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         status: new UntypedFormControl(),
         description: new UntypedFormControl(),
         featured_image: new UntypedFormControl(),
         sort_order: new UntypedFormControl(),
         qualification: new UntypedFormControl(),
         type: new UntypedFormControl()
      });

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({status: 'publish'});
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.form.patchValue({type: 'expert'});
      this.api.post('admin/teacher/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

}
