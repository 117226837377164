<div class="row">
   <ng-container *ngFor="let i of looper">
      <div class="col-sm-6">
         <div class="form-group">
            <label for="">DELIVERY CHARGES ({{i + 1}})</label>
            <div class="input-group">
               <div class="input-group-prepend">
                  <span class="input-group-text">INR</span>
               </div>
               <input (input)="update()" appNumberOnly [(ngModel)]="value['delivery_'+(i+1)]" type="text" class="form-control">

            </div>
         </div>

      </div>
      <div class="col-sm-6">
         <div class="form-group">
            <label for="">PACKAGE WEIGHT ({{i + 1}})</label>
            <input (input)="update()" [(ngModel)]="value['weight_'+(i+1)]" type="text" class="form-control"></div>
      </div>


   </ng-container>
</div>
