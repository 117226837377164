<div class="page-title d-flex justify-content-between align-items-center">
    <h3>Manage Suggested Books</h3>
    <div>
        <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>
        <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
    </div>
</div>
<xs-laravel-data-table #table
                       [endpoint]="endpoint"
                       [config]="tableConfig"
></xs-laravel-data-table>

<ng-template #addNew let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} SuggestedBook</h5>
        <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <input type="hidden" formControlName="id" class="form-control">

            <div class="row">


                <div class="col-6">
                    <div class="form-group">
                        <label for="">SUBJECT</label>
                        <input type="text" formControlName="subject" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">BOOK NAME</label>
                        <input type="text" formControlName="name" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">AUTHOR NAME</label>
                        <input type="text" formControlName="author" class="form-control">
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label for="">PUBLISHER NAME</label>
                        <input type="text" formControlName="publisher" class="form-control">
                    </div>
                </div>


                <div class="col-12">
                    <div class="form-group">
                        <label for="">COURSE</label>
                        <app-main-category-selector formControlName="main_course_id" [tree]="tree"></app-main-category-selector>

                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="">BOOK IMAGE</label>
                        <app-photo-uploader dir="suggested-books" formControlName="image"></app-photo-uploader>
                    </div>
                </div><!--add-->
                <div class="col-sm-12"></div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="" class="d-block">STATUS</label>
                        <app-status formControlName="status"></app-status>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label for="">SORT ORDER</label>
                        <input appNumberOnly type="text" formControlName="sort_order" class="form-control">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
    </div>
</ng-template>


<ng-template #bdrt let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Teachers BDRT</h5>
        <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="pageForm">
            <app-bdrt formControlName="bdrt"></app-bdrt>
            <input type="hidden" formControlName="id" class="form-control">
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="saveBdrt()" class="btn btn-primary">{{pageForm.value.id ? 'Update' : 'Save'}}</button>
    </div>
</ng-template>
