import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
   selector: 'app-toggle-switch',
   templateUrl: './toggle-switch.component.html',
   styleUrls: ['./toggle-switch.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToggleSwitchComponent),
   }]
})
export class ToggleSwitchComponent implements ControlValueAccessor {

   @Input() label0 = 'OFF';
   @Input() label1 = 'ON';
   @Input() value0 = 0;
   @Input() value1 = 1;
   @Input() debug = false;

   public value: number;

   public emit = (_: any) => {

   };

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {

      setTimeout(() => {

         this.value = obj ? obj : this.value0;

      }, 10);

   }

   change() {
      if (this.debug) {
         //  console.log('CHANGE EVENT FIRED');
         // console.log(this.value0);
         //  console.log(this.value1);
      }
      this.value = (this.value === this.value0) ? this.value1 : this.value0;
      if (this.debug) {
         //  console.log('After change value ' + this.value);
      }
      this.emitValue();
   }

   emitValue() {
      this.emit(this.value);
   }
}
