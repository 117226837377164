import {Component, Input, OnInit} from '@angular/core';
import {NoticeCountService} from '../services/notice-count.service';

@Component({
   selector: 'app-notice-count',
   templateUrl: './notice-count.component.html',
   styleUrls: ['./notice-count.component.scss']
})
export class NoticeCountComponent implements OnInit {
   @Input() type;

   constructor(public noticeCountService: NoticeCountService) { }

   ngOnInit(): void {
   }

}
