import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAdManagerConfig, ManageAdsComponent} from '../manage-ads/manage-ads.component';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-notification',
   templateUrl: './manage-notification.component.html',
   styleUrls: ['./manage-notification.component.scss']
})
export class ManageNotificationComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/notification/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Title', key: 'title', type: 'text',},
         {
            name: 'Type', key: 'type', type: 'select', filterKey: 'type',
            selectOptions: of([
               {label: 'Running', value: 'running'},
               {label: 'We in news', value: 'we-in-news'},
               {label: 'Exams in news', value: 'exams-in-news'}
            ])

         },
         {name: 'Status', key: 'status', type: 'text', width: 'min'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST'}
      ],
      actions: [
         {
            name: 'Advertisements',
            type: 'info',
            fn: (dataset, index) => {

               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(ManageAdsComponent);
               const config: IAdManagerConfig = {
                  adType: 'notification',
                  isModal: true,
                  parent: dataset[index].id,
                  title: dataset[index].title

               };
               modalRef.componentInstance.setConfig(config);

            }
         }, {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/notification/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe((res: any) => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         title: new UntypedFormControl('', Validators.compose([Validators.required])),
         sub_title: new UntypedFormControl('', Validators.compose([])),
         content: new UntypedFormControl('', Validators.compose([])),
         url: new UntypedFormControl('', Validators.compose([])),
         type: new UntypedFormControl('', Validators.compose([Validators.required])),
         status: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         featured_image: new UntypedFormControl('', Validators.compose([])),
         bdrt: new UntypedFormControl('', Validators.compose([])),
         //add
      });
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({status: 'publish'});
      this.modal.open(addNew);
   }

   save() {
      if (this.form.invalid) {

         for (const field in this.form.controls) {
            const control = this.form.get(field);
            control.markAsTouched();
         }

         return;
      }
      this.loader.start();
      this.api.post('admin/notification/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }
}
