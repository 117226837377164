import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-previous-papers-stage',
   templateUrl: './manage-previous-papers-stage.component.html',
   styleUrls: ['./manage-previous-papers-stage.component.scss']
})
export class ManagePreviousPapersStageComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/previous-papers-stage/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {
            name: 'ID',
            key: 'id',
            type: 'text',
            width: 'min'
         }

      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/previous-papers-stage/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe(() => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public stageTree = [];
   public categoryTree = [];
   public loadingStages = false;
   public stagesFlat = [];

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         main_course_id: new UntypedFormControl('', Validators.compose([])),
         name: new UntypedFormControl('', Validators.compose([])),
         parent: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         //add
      });

      this.getTree();
   }

   getTree() {
      this.api.post('admin/free-resource-course/get-tree', {})
         .subscribe((res: any) => this.categoryTree = res);
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/previous-papers-stage/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.loadStages();
            this.form.patchValue({
               name: '',
               id: null,
               sort_order: ''
            });
         });
   }

   _flatten(tree, append = '') {
      tree.forEach((item) => {
         this.stagesFlat.push({label: append + item.name, value: item.id});
         if (item.childs) {
            this._flatten(item.childs, append + '--');
         }
      });

   }

   loadStages() {
      if (this.form.value.main_course_id) {
         this.loadingStages = true;

         this.api.post('admin/previous-papers-stage/get-tree', {main_course_id: this.form.value.main_course_id})
            .pipe(finalize(() => this.loadingStages = false))
            .subscribe((res: any) => {
               this.stageTree = res;
               this.stagesFlat = [];
               this._flatten(this.stageTree);
            });
      }
   }

   edit($event: any) {
      this.form.patchValue($event);
   }

   delete(item: any) {
      swal.fire({
         title: 'Delete :' + item.name + '?',
         icon: 'question',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/previous-papers-stage/delete', {id: item.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.loadStages();
               });
         }
      });

   }

   cancelUpdate() {
      this.form.patchValue({
         main_course_id: this.form.value.main_course_id,
         id: null,
         name: null,
         parent: null
      });
   }
}
