import {NgModule} from '@angular/core';
import {QuestionPaperDisplayComponent} from './question-paper-display.component';
import {SectionHeaderComponent} from './section-header/section-header.component';
import {SafePipeModule} from 'safe-pipe';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
   declarations: [QuestionPaperDisplayComponent, SectionHeaderComponent],
   imports: [
      SafePipeModule,
      CommonModule,
   ],
   exports: [QuestionPaperDisplayComponent]
})
export class QuestionPaperDisplayModule {}
