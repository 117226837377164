import {Component, OnInit} from '@angular/core';
import {PhotoService} from '../../services/photo.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ApiService} from '../../services/api.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {finalize} from 'rxjs/operators';

@Component({
   selector: 'app-manage-homepage',
   templateUrl: './manage-homepage.component.html',
   styleUrls: ['./manage-homepage.component.scss']
})
export class ManageHomepageComponent implements OnInit {

   public lookingFor: UntypedFormGroup;
   public programForm: UntypedFormGroup;
   public popUpForm: UntypedFormGroup;
   public config = {
      app_banners: [],
      banners: [],
      side_banners: [],
      slider_color: '#000',
      menu_color: '#000',
      top_phone: '',
      telegram_link: '',
      looking_for: [],
      programs: [],
      all_programs: '',
      expertise: '',
      popups: [],
   };
   public banners = [];
   public updateMode = false;
   public selectedIndex = null;

   constructor(
      public toastr: ToastrService,
      public auth: AuthService,
      public router: Router,
      public photoService: PhotoService,
      public api: ApiService,
      public loader: NgxUiLoaderService
   ) {

      this.lookingFor = new UntypedFormGroup({
         title: new UntypedFormControl('', Validators.required),
         sub: new UntypedFormControl('', Validators.required),
         link: new UntypedFormControl('', Validators.required),
         icon: new UntypedFormControl('', Validators.required),
         index: new UntypedFormControl()
      });
      this.programForm = new UntypedFormGroup({
         title: new UntypedFormControl('', Validators.required),
         sub: new UntypedFormControl('', Validators.required),
         link: new UntypedFormControl('', Validators.required),
         icon: new UntypedFormControl('', Validators.required),
         index: new UntypedFormControl()
      });

      this.popUpForm = new UntypedFormGroup({
         link: new UntypedFormControl('', Validators.required),
         icon: new UntypedFormControl('', Validators.required),
         index: new UntypedFormControl()
      });
   }

   ngOnInit() {
      this.loader.start();
      this.api.post('admin/settings', {key: 'home'})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.config = {...this.config, ...res};
         });
   }

   appBannerUploaded($event: any) {
      this.config.app_banners = this.config.app_banners.concat($event);
   }

   bannerUploaded($event: any) {
      this.config.banners = this.config.banners.concat($event);
   }

   sideBannerUploaded($event: any) {
      this.config.side_banners = this.config.side_banners.concat($event);
   }

   appBannerDrop(event) {
      moveItemInArray(this.config.app_banners, event.previousIndex, event.currentIndex);
   }

   bannerDrop(event) {
      moveItemInArray(this.config.banners, event.previousIndex, event.currentIndex);
   }

   sideBannerDrop(event) {
      moveItemInArray(this.config.side_banners, event.previousIndex, event.currentIndex);
   }

   addItem() {
      if (!this.config.looking_for) {
         this.config.looking_for = [];
      }
      if (this.lookingFor.value.index != null) {
         this.config.looking_for[this.lookingFor.value.index] = this.lookingFor.value;
      } else {
         this.config.looking_for = this.config.looking_for.concat(this.lookingFor.value);
      }
      this.lookingFor.reset();
      this.save();
   }

   edit(item: any, index) {
      this.lookingFor.patchValue(item);
      this.lookingFor.patchValue({index});
   }

   editProgram(item: any, index) {
      this.programForm.patchValue(item);
      this.programForm.patchValue({index});
   }

   addProgramItem() {
      if (!this.config.programs) {
         this.config.programs = [];
      }
      if (this.programForm.value.index != null) {
         this.config.programs[this.programForm.value.index] = this.programForm.value;
      } else {
         this.config.programs = this.config.programs.concat(this.programForm.value);
      }

      this.programForm.reset();
      this.save();
   }

   addPopItem() {
      if (!this.config.popups) {
         this.config.popups = [];
      }
      if (this.popUpForm.value.index != null) {
         this.config.popups[this.popUpForm.value.index] = this.popUpForm.value;
      } else {
         this.config.popups.push(this.popUpForm.value);
      }
      this.popUpForm.reset();
      this.save();
   }

   editPopUp(item: any, idx: number) {
      this.popUpForm.patchValue(item);
   }

   sort(event, popups: any[]) {
      moveItemInArray(popups, event.previousIndex, event.currentIndex);
   }

   save() {
      this.loader.start();
      this.api.post('admin/settings/save', {key: 'home', value: this.config})
         .subscribe(res => {
            this.loader.stop();
         }, err => {
            this.toastr.error('Error saving settings');
         });
   }
}
