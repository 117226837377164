<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Enquiry Concerns</h3>
   <div>
      <button class="btn btn-info" (click)="displayExport=true">Export</button>
   </div>
</div>
<p-dialog header="Export Data" [(visible)]="displayExport" modal="true" [closable]>
   <div style="height: 400px;">
      <form [formGroup]="exportForm">
         <div class="row">
            <div class="col-6">
               <label for="">From</label>
               <app-date-picker formControlName="from" [(ngModel)]="exportFrom"></app-date-picker>
            </div>
            <div class="col-6">
               <label for="">To</label>
               <app-date-picker formControlName="to" [(ngModel)]="exportTo"></app-date-picker>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label for="">Type</label>
                  <select class="form-control" formControlName="type">
                     <option value="all">All</option>
                     <option value="read">Read</option>
                     <option value="unread">Unread</option>
                  </select>
               </div>
            </div>
         </div>
      </form>
   </div>
   <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="Export" (click)="export()"></p-button>
   </ng-template>
</p-dialog>


<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-info" (click)="view(item)">Details</button>

         <button pTooltip="Mark as read" tooltipPosition="top" *ngIf="item.status == 'pending'" (click)="markAsRead(item,true)" class="btn btn-sm btn-info"><i class="fa fa-eye"></i></button>
         <button pTooltip="Mark as un-read" tooltipPosition="top" *ngIf="item.status !== 'pending'" (click)="markAsRead(item)" class="btn btn-sm btn-warning"><i class="fa fa-eye-slash"></i></button>
         <button pTooltip="Delete" tooltipPosition="top" mwlConfirmationPopover class="btn btn-sm btn-danger mr-1" (confirm)="delete(item,idx)"><i class="fa fa-times"></i></button>
      </div>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>

</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} EmailConcern</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<ng-template #detail let-modal>
   <div class="modal-header">
      <h5 class="modal-title">Detail</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="row">
         <div class="col-sm-6">
            <div class="detail">
               <div>
                  <label class="d-block" for="">Name</label>
                  {{selectedItem.user_name}}
               </div>
               <div>
                  <label class="d-block" for="">Email</label>
                  {{selectedItem.user_email}}
               </div>
               <div>
                  <label class="d-block" for="">Phone</label>
                  {{selectedItem.user_phone_no }}
               </div>
               <div>
                  <label class="d-block" for="">Date</label>
                  {{selectedItem.created_at|date:'medium':'530'}}
               </div>
               <div>
                  <label class="d-block" for="">Details</label>
                  {{selectedItem.user_description }}
               </div>


            </div>
         </div>
         <div class="col-sm-6">
            <textarea [(ngModel)]="message" rows="10" class="form-control"></textarea>
            <button (click)="sendEmail(selectedItem)" class="btn btn-sm btn-block btn-primary">Send Mail & mark as read</button>
         </div>
      </div>

   </div>
   <div class="modal-footer">
      <button (click)="markAsRead(selectedItem)" class="btn btn-sm btn-info">Mark as {{selectedItem.read_at != null ? 'Unread' : 'Read'}}</button>
   </div>
</ng-template>


