<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Top Ranker Categories</h3>
   <div>
      <button class="btn btn-primary mr-2 btn-icon" (click)="add(addNew)"> Add New</button>
      <button class="btn btn-secondary btn-icon" routerLink="/dashboard/top-ranker"> Manage Top Rankers</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template lt-cell-template field="status" let-item>
      <span class="tbl-status" [ngClass]="'status-'+item.status">{{item.status}}</span>
   </ng-template>
   <ng-template lt-cell-template field="actions" let-item let-idx="index">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="edit(item,idx)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
   <ng-template field="ranker_name" let-idx="index" lt-cell-template="" let-item="">

      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.ranker_img" width="80" height="80" class="mr-2"></app-cdn-image>
         {{item.ranker_name}}
      </div>

   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Top Ranker Category</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
         <app-form-errors [formErrors]="formErrors"></app-form-errors>
         <div class="row">
            <div class="col-sm-8">
               <div class="form-group">
                  <label>Name</label>
                  <input formControlName="name" type="text" class="form-control">
               </div>
               <div class="form-group">
                  <label>Sort Order</label>
                  <input appNumberOnly formControlName="sort_order" type="text" class="form-control">
               </div>
            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

