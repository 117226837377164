<div class="question-paper position-relative" *ngIf="test">
   <ng-container *ngFor="let q of test.questions;let idx=index">
      <app-section-header [index]="idx+1" [sections]="test.sections"></app-section-header>
      <div *ngIf="q.depends_on" [innerHTML]="q.comp.question|safe:'html'" style="background-color: #f1efef;padding: 10px;"></div>

      <ol class="p-0 ml-4" [start]="idx+1" style="list-style: decimal;">
         <li class="border-bottom pt-3 pb-3">
            <div [innerHTML]="q.question|safe:'html'"></div>
            <ol class="options" style="list-style: upper-alpha;padding: 0 18px;" *ngIf="q.type.indexOf('mc')> -1">
               <li [ngClass]="{'correct-answer': q?.answer?.trim() === legend[idx]}" *ngFor="let o of q.options;let idx=index"
                   [innerHTML]="o.option|safe:'html'">
               </li>
            </ol>

            <ng-container *ngIf="q.type.indexOf('match')> -1">
               <div class="mt-3" [innerHTML]="q.options[0].option | safe:'html'"></div>
               <div class="text-success mt-2 font-weight-bold" *ngIf="q.answer"> Answer :{{q.answer.trim()}}</div>
            </ng-container>


            <ng-container *ngIf="q.details">
               <h4 class="text-uppercase font-weight-bold dark-bg mt-2 p-2" style="font-size: 14px;background-color: #00b7ff;">Answer Details</h4>
               <div [innerHTML]="q.details|safe:'html'"></div>
            </ng-container>
         </li>
      </ol>
   </ng-container>
   <div *ngIf="test?.questions?.length==0" class="alert alert-info">No questions found.</div>
</div>
