import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import * as lod from 'lodash';

@Component({
   selector: 'app-manage-book-coupon-batch',
   templateUrl: './manage-book-coupon-batch.component.html',
   styleUrls: ['./manage-book-coupon-batch.component.scss'],
})
export class ManageBookCouponBatchComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/book-coupon-batch/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'name', type: 'text'},
         {name: 'Tests Count', key: 'test_count', type: 'text', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST'},
         {name: 'Status', key: 'status', width: 'min'},
      ],

   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public showAddModal = false;
   public tests = [];
   public dropDownSettings = {
      singleSelection: false,
      text: 'Select Fields',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Search Fields',
      enableSearchFilter: true,
      badgeShowLimit: 5,
      appendTo: 'body',
      groupBy: 'category'
   };
   public searchResults = [];
   public selectedTests = [];
   public selectedTestIds = [];

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public cdr: ChangeDetectorRef,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl('', Validators.required),
         test_count: new UntypedFormControl(4, Validators.required),
         tests: new UntypedFormControl(),
         active_from: new UntypedFormControl(),
         active_to: new UntypedFormControl(),
         status: new UntypedFormControl(),
         description: new UntypedFormControl(),
      });

   }

   ngOnInit() {

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/book-coupon-batch/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   edit(item, index) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      this.selectedTests = this.selectedItem.tests;
      this.showAddModal = true;
   }

   delete(item, index) {
      this.loader.start();
      this.api.post('admin/book-coupon-batch/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.table.removeItemAtIndex(index);
         });
   }

   search(s) {

      this.api.post('admin/programme/list', {name: s.query, type: 'test'})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.searchResults = res.data;
            this.cdr.detectChanges();
         });
   }

   selected($event: any) {
      this.loader.start();
      this.api.post('admin/test/list?id=' + $event.id, {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.tests = res;
            this.cdr.detectChanges();
         });
   }

   addTest(test: any) {
      const index = lod.findIndex(this.selectedTests, {id: test.id});
      if (index > -1) {
         this.selectedTests.splice(index, 1);
         this.selectedTests = [...this.selectedTests];
      } else {
         this.selectedTests = [...this.selectedTests, ...[test]];
      }
      this.form.patchValue({tests: this.selectedTests});
   }

   removeTest(t: any) {
      const index = lod.findIndex(this.selectedTests, {id: t.id});
      this.selectedTests.splice(index, 1);
      this.selectedTests = [...this.selectedTests];
   }

   isSelected(selectedTests, id) {
      return (lod.findIndex(selectedTests, {id}) > -1);
   }

   saveBatch() {
      this.loader.start();
      this.api.post('admin/book-coupon-batch/save', this.form.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.showAddModal = false;
            this.form.reset();
            this.table.loadData();
         });
   }
}
