import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {XsLaravelDataTableComponent} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.component';
import {PhotoService} from '../../services/photo.service';
import {AddEditTransactionComponent} from './add-edit-transaction/add-edit-transaction.component';
import * as lo from 'lodash';
import {NoticeCountService} from '../../services/notice-count.service';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {STUDENT_CATEGORY} from '../../constants/student-category';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-test-order',
   templateUrl: './manage-test-order.component.html',
   styleUrls: ['./manage-test-order.component.scss']
})
export class ManageTestOrderComponent implements AfterViewInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table: XsLaravelDataTableComponent;
   @ViewChild('details') detailsModal;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/test-order/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'User', key: 'user.name', type: 'text', focus: true},
         // {name: 'Email', key: 'user.email', type: 'text',},
         {name: 'Coupon', key: 'coupon', type: 'text', width: 'min'},
         {name: 'Paid', key: 'amount_paid', type: 'amount', width: 'min'},
         {name: 'Date', key: 'created_at', type: 'dateIST',},
         {name: 'Updated', key: 'updated_at', type: 'dateIST',},
         {
            name: 'Mode', key: 'payment_mode', width: 'min', type: 'select', selectOptions: of([
               {label: 'Online', value: 'online'},
               {label: 'Offline', value: 'offline'},
            ])
         },
         {name: 'Viewed', key: 'viewed', type: 'status', width: 'min'},
         {
            name: 'Status', key: 'status', type: 'select', width: 'min', selectOptions: of([
               {label: 'Paid', value: 'paid'},
               {label: 'Partial', value: 'partial'},
               {label: 'Pending', value: 'pending'},
               {label: 'Cancelled', value: 'cancelled'},
            ])
         },
      ],
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public newTransactionForm: UntypedFormGroup;
   public displayExport = false;
   public exportForm: UntypedFormGroup;

   public userDetails: UntypedFormGroup;
   public studentCategories = STUDENT_CATEGORY;
   public batchResults: any = [];
   public batchName = null;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public activatedRoute: ActivatedRoute,
               public modal: NgbModal,
               public cdRef: ChangeDetectorRef,
               public photoService: PhotoService,
               public noticeCountService: NoticeCountService,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
      });
      this.noticeCountService.clear('testOrder');
      this.exportForm = new UntypedFormGroup({
         from: new UntypedFormControl(),
         to: new UntypedFormControl(),
         batch: new UntypedFormControl(),
         status: new UntypedFormControl('')
      });
      this.userDetails = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         email: new UntypedFormControl(),
         mobile1: new UntypedFormControl(),
         dob: new UntypedFormControl(),
         gender: new UntypedFormControl(),
         father_name: new UntypedFormControl(),
         category: new UntypedFormControl(),
         handicapped: new UntypedFormControl(),
         mobile2: new UntypedFormControl(),
         address: new UntypedFormControl(),
         city: new UntypedFormControl(),
         pincode: new UntypedFormControl(),
         district: new UntypedFormControl(),
         state: new UntypedFormControl(),
         profile_image: new UntypedFormControl()
      });
      this.activatedRoute.queryParams.subscribe(res => {
         if (!res.id) {
            this.endpoint = environment.endpoint + 'admin/test-order/list';
            this.batchName = null;
            return;
         }
         this.endpoint = environment.endpoint + 'admin/test-order/list?id=' + res.id;
         this.batchName = {name: res.name};
      });
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/test-order/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   ngAfterViewInit(): void {
   }

   viewOrderDetails(item) {
      this.selectedItem = item;
      //  console.log(this.selectedItem);
      if (this.selectedItem.user.application_detail) this.userDetails.patchValue(this.selectedItem.user.application_detail);
      this.userDetails.patchValue({id: this.selectedItem.user.id});
      const modalRef = this.modal.open(this.detailsModal);//
   }

   deleteOrder(item) {
      swal.fire({
         title: 'Delete : #Order' + item.id + '?',
         icon: 'question',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/test-order/delete', {id: item.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe((res: any) => {
                  this.table.loadData();
               });
         }

      });

   }

   editTransaction(transaction) {
      this.newTransactionForm.patchValue(transaction);
   }

   deleteTransaction(transaction) {
      swal.fire({
         title: 'Delete : #Transaction ' + transaction.id + '?',
         icon: 'question',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/test-order-transaction/delete', {id: transaction.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe((res: any) => {
                  this.selectedItem = res.data;
                  this.table.loadData();
               });
         }

      });
   }

   addTransaction(transaction = null) {
      const ref = this.modal.open(AddEditTransactionComponent, {size: 'md'});
      ref.componentInstance.testOrderId = this.selectedItem.id;
      ref.componentInstance.orderItems = this.selectedItem.items;
      if (transaction) {
         ref.componentInstance.transaction = transaction;
      }
      ref.result.then(res => {
         if (res) {
            this.selectedItem = res.data;
            this.table.loadData();
         }
      });
   }

   openExpiryUpdater(popover: NgbPopover, i) {
      if (popover.isOpen()) popover.close();
      popover.open({item: i, popover});

   }

   updateExpiryDate(item: any, d, popover) {
      this.loader.start();
      this.api.post('admin/test-order-item/save', {expiry_date: d, id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            lo.assign(item, res.data);
            popover.close();
         });
   }

   markAsRead(item: any) {
      this.loader.start();
      const action = item.read_at === null ? 'mark-as-read' : 'mark-as-unread';
      this.api.post('admin/test-order/' + action, {id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            item.read_at = action === 'mark-as-read' ? 'x' : null;
         });
   }

   export() {
      this.loader.start();
      this.api.post('admin/test-order/export', this.exportForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }

   updateApplication() {
      this.loader.start();
      this.api.post('admin/user/update-application', this.userDetails.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {

         });
   }

   searchBatch($event: any) {
      // this.loader.start();
      this.api.post('admin/programme/searchTestSeries', {term: $event.query, ts: true})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.batchResults = res;
         });
   }

   downloadApplication(item: any) {
      this.loader.start();
      this.api.post('admin/test-order/download', {id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }

   filterByBatch($event: any) {
      console.log($event);
      this.router.navigate(['dashboard/manage/test-orders'], {
         queryParams: {
            id: $event.id,
            name: $event.name
         }
      });
   }

   clearBatch() {
      this.router.navigate(['dashboard/manage/test-orders']);
      this.batchName = null;
      this.endpoint = environment.endpoint + 'admin/test-order/list';

   }
}
