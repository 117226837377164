<form [formGroup]="form">
   <div class="row">
      <div class="col-6">
         <div class="form-group">
            <label>User</label>
            <div class="input-group">
               <input type="text" readonly formControlName="userName" class="form-control">
               <div class="input-group-append">
                  <button class="btn btn-primary" (click)="pickUser()">Select User</button>
               </div>
            </div>
         </div>
      </div>

      <div class="col-6">
         <div class="form-group">
            <label>PROGRAMME</label>
            <select formControlName="program_id" class="form-control custom-select">
               <option *ngFor="let p of programs" [value]="p.id">{{p.name}} ({{p.batch_code}})</option>
            </select>
         </div>
      </div>

      <div class="col-6">
         <div class="form-group">
            <label class="d-block" for="">Application Status</label>
            <app-status formControlName="status" [statusList]="['Cancelled','Paid','Pending','Partial','Failed']"></app-status>
         </div>
      </div>
      <div class="col-6" *ngIf="form.value.status=='partial'">
         <div class="form-group">
            <label class="d-block" for="">Due Date for Payment</label>
            <input class="form-control" (focus)="dueDate.toggle()" (dateSelect)="dueDate.close()" #dueDate="ngbDatepicker" ngbDatepicker formControlName="partial_payment_due_date"/>
         </div>
      </div>

      <div class="col-12"></div>
      <div class="col-6">
         <div class="form-group">
            <label for="">Amount Payable</label>
            <input appNumberOnly type="text" formControlName="order_total" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label for="">Discount</label>
            <input appNumberOnly type="text" formControlName="order_coupon_discount" class="form-control">
         </div>
      </div>
      <div class="col-12">
         <div class="section">Personal Details</div>
      </div>

      <div class="col-4">
         <div class="form-group">
            <label>NAME</label>
            <input type="text" formControlName="name" class="form-control">
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>DATE OF BIRTH</label>
            <input ngbDatepicker #dob="ngbDatepicker"
                   (focus)="dob.open()"
                   (dateSelect)="dob.close()"
                   [autoClose]="true"
                   type="text"
                   formControlName="date_of_birth"
                   class="form-control">
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>GENDER</label>
            <select formControlName="gender" class="form-control">
               <option value="male">Male</option>
               <option value="female">Female</option>
               <option value="third_gender">Third Gender</option>
            </select>
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>FATHER NAME</label>
            <input type="text" formControlName="father_name" class="form-control">
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>FATHER OCCUPATION</label>
            <input type="text" formControlName="father_occupation" class="form-control">
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>MOTHER NAME</label>
            <input type="text" formControlName="mother_name" class="form-control">
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>CATEGORY</label>
            <select type="text" formControlName="category" class="form-control custom-select">
               <option *ngFor="let c of  category" [value]="c.value">{{c.label}}</option>
            </select>
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>AADHAAR NUMBER</label>
            <input type="text" formControlName="aadhaar_number" class="form-control">
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>HANDICAPPED</label>
            <app-toggle-switch formControlName="handicapped" value0="no" value1="yes" label0="No" label1="Yes"></app-toggle-switch>
         </div>
      </div>
      <!-- End personal Details -->
      <div class="col-12">
         <div class="section">Contact Details</div>
      </div>

      <div class="col-6">
         <div class="form-group">
            <label>MOBILE 1</label>
            <input type="text" formControlName="mobile_1" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>MOBILE 2</label>
            <input type="text" formControlName="mobile_2" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>LANDLINE</label>
            <input type="text" formControlName="landline" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>EMAIL</label>
            <input type="text" formControlName="email" class="form-control">
         </div>
      </div>
      <div class="col-sm-6">

         <div class="row">
            <div class="col-12">
               <div class="section">PERMANENT ADDRESS</div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>ADDRESS</label>
                  <input type="text" formControlName="p_address" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>LANDMARK</label>
                  <input type="text" formControlName="p_landmark" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>CITY</label>
                  <input type="text" formControlName="p_city" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>POST OFFICE</label>
                  <input type="text" formControlName="p_post_office" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>PINCODE</label>
                  <input type="text" formControlName="p_pincode" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>DISTRICT</label>
                  <input type="text" formControlName="p_district" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label>STATE</label>
                  <input type="text" formControlName="p_state" class="form-control">
               </div>
            </div>
         </div>
      </div>

      <div class="col-sm-6">
         <div class="row">
            <div class="col-12">
               <div class="section">Correspondence Address</div>
               <div class="col-12">
                  <div class="form-group">
                     <label>ADDRESS</label>
                     <input type="text" formControlName="c_address" class="form-control">
                  </div>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <label>LANDMARK</label>
                     <input type="text" formControlName="c_landmark" class="form-control">
                  </div>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <label>CITY</label>
                     <input type="text" formControlName="c_city" class="form-control">
                  </div>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <label>POST OFFICE</label>
                     <input type="text" formControlName="c_post_office" class="form-control">
                  </div>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <label>PINCODE</label>
                     <input type="text" formControlName="c_pincode" class="form-control">
                  </div>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <label>DISTRICT</label>
                     <input type="text" formControlName="c_district" class="form-control">
                  </div>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <label>STATE</label>
                     <input type="text" formControlName="c_state" class="form-control">
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="section">Qualification</div>
      </div>
      <!-- -->
      <div class="col-12">
         <div class="form-group">
            <label>GRADUATE</label>
            <app-toggle-switch formControlName="graduate" label0="No" label1="Yes"></app-toggle-switch>
         </div>
      </div>
      <div class="col-sm-12">
         <h5 class="q-section">Matric</h5>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>MATRIC BOARD</label>
            <input type="text" formControlName="matric_board" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>MATRIC PASS YEAR</label>
            <input appNumberOnly type="text" formControlName="matric_pass_year" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>MATRIC PERCENTAGE</label>
            <input appNumberOnly type="text" formControlName="matric_percentage" class="form-control">
         </div>
      </div>
      <div class="col-6">
         <div class="form-group">
            <label>MATRIC CERTIFICATE</label>
            <app-doc-uploader formControlName="matric_certificate"></app-doc-uploader>
         </div>
      </div>
      <!-- end matic -->


      <ng-container *ngIf="form.value.graduate===0">
         <div class="col-12"><h4 class="q-section">Post Matric</h4></div>
         <div class="col-12">
            <div class="form-group mt-4">
               <app-toggle-switch label0="Appearing" label1="Passed" formControlName="post_matric"></app-toggle-switch>
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>POST MATRIC STREAM</label>
               <select type="text" formControlName="post_matric_stream" class="form-control custom-select">
                  <option *ngFor="let s of postMatricStreams" [value]="s.value">{{s.label}}</option>
               </select>
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>POST MATRIC BOARD</label>
               <input type="text" formControlName="post_matric_board" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>POST MATRIC_PASS_YEAR</label>
               <input appNumberOnly maxlength="4" type="text" formControlName="post_matric_pass_year" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>POST MATRIC_PERCENTAGE</label>
               <input type="text" appNumberOnly formControlName="post_matric_percentage" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>POST MATRIC_CERTIFICATE</label>
               <app-doc-uploader formControlName="post_matric_certificate"></app-doc-uploader>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="form.value.graduate===1">
         <div class="col-sm-12">
            <h5 class="q-section">Graduation</h5>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION STREAM</label>
               <select type="text" formControlName="graduation_stream" class="custom-select form-control">
                  <option *ngFor="let s of graduationStreams" [value]="s.value">{{s.label}}</option>
               </select>
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION UNIVERSITY</label>
               <input type="text" formControlName="graduation_university" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION PASS_YEAR</label>
               <input type="text" formControlName="graduation_pass_year" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION PERCENTAGE</label>
               <input type="text" formControlName="graduation_percentage" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION CERTIFICATE</label>
               <app-doc-uploader formControlName="graduation_certificate"></app-doc-uploader>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="form.value.post_matric">
         <div class="col-12">
            <h4 class="q-section">Graduation Appearing</h4>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION APPEARING_STREAM</label>
               <select type="text" formControlName="graduation_appearing_stream" class="form-control custom-select">
                  <option *ngFor="let s of graduationStreams" [value]="s.value">{{s.label}}</option>
               </select>
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION APPEARING_UNIVERSITY</label>
               <input type="text" formControlName="graduation_appearing_university" class="form-control">
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label>GRADUATION APPEARING_PASS_YEAR</label>
               <input type="text" formControlName="graduation_appearing_pass_year" class="form-control">
            </div>
         </div>
      </ng-container>

      <div class="col-12">
         <div class="section">Documents</div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>PROFILE IMAGE</label>
            <app-doc-uploader formControlName="profile_image"></app-doc-uploader>
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>PROOF</label>
            <app-doc-uploader formControlName="proof"></app-doc-uploader>
         </div>
      </div>
      <div class="col-4">
         <div class="form-group">
            <label>SIGN</label>
            <app-doc-uploader formControlName="sign"></app-doc-uploader>
         </div>
      </div><!--add-->


   </div>
</form>
