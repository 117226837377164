<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Question Reports</h3>
   <div>

   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="user.name" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image class="mr-2" [src]="item.user.profile_image" [width]="60" height="60"></app-cdn-image>
         <div>
            {{item.user.name}}
            <small class="d-block">{{item.user.email}}</small>
         </div>
      </div>
   </ng-template>
   <ng-template field="comment" let-idx="index" lt-cell-template="" let-item="">
      {{item.comment |slice:0:100}}
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-info" (click)="details(item)">Details</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title"></h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <div class="row">
            <div class="col-sm-7">
               <div class="question">
                  <h3>{{selectedItem.question.test[0].name}}</h3>
                  <div class="section">Question</div>
                  <div [innerHTML]="selectedItem.question.question|safeHtmlPipe"></div>
                  <div class="section mt-3">Options</div>
                  <ol>
                     <li *ngFor="let o of selectedItem.question.options" [innerHTML]="o.option|safeHtmlPipe"></li>
                  </ol>
                  <div class="section mt-3">Answer & Details</div>
                  <div>
                     Answer : <strong>{{selectedItem.question.answer}}</strong>
                     <div [innerHTML]="selectedItem.question.details"></div>
                  </div>
               </div>
            </div>
            <div class="col-sm-5">
               <div class="section">Submitted By</div>
               <div> User: {{selectedItem.user.name}}</div>
               <div> Email: {{selectedItem.user.email}}</div>
               <div> Phone: {{selectedItem.user.phone_no}}</div>
               <div class="section mt-3">Comment</div>
               {{selectedItem.comment}}
            </div>
         </div>

      </form>
   </div>


</ng-template>


