import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class PhotoService {

   public url;

   constructor() {
      this.url = environment.website + '/ir';
   }

   public correctPath(path) {
      if (path) {
         return path.replace('public', 'storage');
      }
      return path;
   }

   resize(path, width, height = 0, ratio = 1) {
      path = this.correctPath(path);
      path = path?.replace('storage/', '');
      return `https://ik.imagekit.io/aqcg4zaiu7s/sadda-punjab/${path}?tr=fo-auto,lo-true,q-100,w-${width},h-${height}`;
      return `${this.url}/resize?path=${path}&&w=${width}&&h=${height}&&ratio=${ratio}`;
   }

   fit(path, width, height = 0, ratio = 1) {
      path = this.correctPath(path);

      if (height == null) {
         height = 0;
      }
      return `${this.url}/resize?path=${path}&&w=${width}&&h=${height}&&ratio=${ratio}`;
   }

   exact(path, width, height) {
      path = this.correctPath(path);

      return `${this.url}/fit?path=${path}&&w=${width}&&h=${height}`;
   }

}
