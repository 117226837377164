<div class="d-flex h-100 align-middle">

    <div id="login" class="align-self-center">
        <form [formGroup]="formLogin" (submit)="submitLoginForm()">
            <img src="assets/logo.jpg" class="img-fluid mb-5">
            <div *ngIf="showLoginMessage" class="alert alert-warning">You need to be logged in.</div>
            <div *ngIf="error" class="alert alert-danger">Invalid Credentials.</div>

            <div class="form-group">
                <label for="">Email</label>
                <input type="text"
                       class="form-control" formControlName="email">

            </div>
            <div class="form-group">
                <label for="">Password</label>
                <input type="password"
                       class="form-control" formControlName="password">

            </div>
            <button type="submit" class="btn btn-primary btn-lg btn-block
    ">Login
            </button>
        </form>
    </div>
</div>
