<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Notices</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>

<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="general" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.general==1?'No':'Yes'"></app-badge>
   </ng-template>
   <ng-template field="sent" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.sent==1?'Sent':'Pending'"></app-badge>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Notification</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id" class="form-control">

         <div class="form-group">
            <label for="" class="form-label">Title</label>
            <input type="text" formControlName="title" class="form-control">
         </div>
         <div class="form-group">
            <label for="" class="form-label">Summary</label>
            <input type="text" formControlName="summary" class="form-control">
         </div>
         <div class="form-group">
            <label for="" class="form-label">Content</label>
            <app-editor formControlName="body"></app-editor>
         </div>
         <div class="form-group">
            <label for="" class="form-label">General Notification</label>
            <app-toggle-switch label0="No" label1="Yes" formControlName="general"></app-toggle-switch>
         </div>
         <div class="form-group my-4">
            <label for="">Select Targets</label>
            <app-notice-targets formControlName="config"></app-notice-targets>
         </div>
         <div class="form-group">
            <label for="" class="form-label">Sending time (leave empty to send now )</label>
            <app-prime-date-time-picker formControlName="send_time"></app-prime-date-time-picker>
         </div>
         <div class="form-group">
            <label for="" class="form-label">Status</label>
            <p-selectButton formControlName="status" [options]="[{label:'Draft',value:'draft'},{label:'Publish',value:'publish'}]"></p-selectButton>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
