import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
   selector: 'app-marking-control',
   templateUrl: './marking-control.component.html',
   styleUrls: ['./marking-control.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MarkingControlComponent)
   }]
})
export class MarkingControlComponent implements ControlValueAccessor {

   public blankItem = {range: null, marks: null};
   public items: any[] = [this.blankItem];
   public emit = (_any) => {
   };

   constructor() { }

   registerOnChange(fn: any): void { this.emit = fn; }

   registerOnTouched(fn: any): void { }

   writeValue(obj: any): void {
      if (obj) { this.items = obj; }
   }

   addNew() {
      this.items.push({range: null, marks: null});
      this.items = [...this.items];
      this.emitValue();
   }

   remove(idx: number) {
      this.items.splice(idx, 1);
      this.emitValue();
   }

   emitValue() { this.emit(this.items); }
}
