import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-course-tree-selection',
    templateUrl: './course-tree-selection.component.html',
    styleUrls: ['./course-tree-selection.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CourseTreeSelectionComponent),
            multi: true
        }
    ]
})
export class CourseTreeSelectionComponent implements OnInit, ControlValueAccessor {

    public value = [];

    constructor(public api: ApiService) {
        this.api.post('admin/course/get-tree', {}).subscribe((res: any) => {
            this.mainCourses = res;
            if (!Array.isArray(this.value)) {
                this.value = [];
            }
        });

    }

    public mainCourses = [];
    propagateChange = (_: any) => {
    };

    ngOnInit() {

    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    select(id: any) {

        const index = this.value.indexOf(id);
        if (index > -1) {
            this.value.splice(index, 1);
            this.value = [].concat(this.value);
          //  console.log('remove');
        } else {
            this.value = this.value.concat(id);
          //  console.log('add');
        }

        this.propagateChange(this.value);
    }
}
