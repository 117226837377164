<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Enquiry Callbacks</h3>
   <div>
      <button class="btn btn-info" (click)="displayExport=true">Export</button>
   </div>
</div>
<p-dialog header="Export Data" [(visible)]="displayExport" modal="true" [closable]>
   <div style="height: 400px;">
      <form [formGroup]="exportForm">
         <div class="row">
            <div class="col-6">
               <label for="">From</label>
               <app-date-picker formControlName="from"></app-date-picker>
            </div>
            <div class="col-6">
               <label for="">To</label>
               <app-date-picker formControlName="to"></app-date-picker>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label for="">Type</label>
                  <select class="form-control" formControlName="type">
                     <option value="all">All</option>
                     <option value="read">Read</option>
                     <option value="unread">Unread</option>
                  </select>
               </div>
            </div>
         </div>
      </form>
   </div>
   <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="Export" (click)="export()"></p-button>
   </ng-template>
</p-dialog>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">

   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>

   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button (click)="markAsRead(item)" *ngIf="item.status=='pending'" tooltipPosition="left" pTooltip="Mark as processed" class="btn btn-success btn-sm"><i class="fa fa-check"></i></button>
         <button (click)="markAsRead(item,true)" *ngIf="item.status!='pending'" tooltipPosition="left" pTooltip="Mark as pending" class="btn btn-warning btn-sm"><i class="fa fa-eye-slash"></i>
         </button>
         <button class="btn btn-danger btn-sm" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} EnquiryCallback</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>


