import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
   selector: 'app-status',
   templateUrl: './status.component.html',
   styleUrls: ['./status.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: forwardRef(() => StatusComponent),
      }
   ]
})
export class StatusComponent implements ControlValueAccessor, AfterViewInit {

   public listOfItems = [];
   @Input() statusList = ['Draft', 'Publish'];
   @Output() statusChanged = new EventEmitter();
   @Input() public label;
   @Input() public size;
   @Input() public status;

   public propagateChanges = (_: any) => {
   };

   ngAfterViewInit() {
      this.listOfItems = this.statusList.map((e) => {
         return {
            label: e, value: e.toLowerCase()
         }
      })
   }

   registerOnChange(fn: any): void {
      this.propagateChanges = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: string): void {
      this.status = obj ? obj : 'publish';
      this.changed();
   }

   changed(e = null) {
      setTimeout(() => {
         this.propagateChanges(this.status);
         //   console.log('fired');
      }, 1);
      this.statusChanged.emit(this.status);
   }

}
