import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAdManagerConfig, ManageAdsComponent} from '../manage-ads/manage-ads.component';
import {PhotoService} from '../../services/photo.service';
import *  as _ from 'lodash';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-publication',
   templateUrl: './manage-publication.component.html',
   styleUrls: ['./manage-publication.component.scss']
})
export class ManagePublicationComponent {
   @ViewChild('addNew') addNewModal;

   @ViewChild('table') table;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/publication/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Photo', key: 'featured_image', type: 'image', width: 'min'},
         {name: 'Title', key: 'book_title', type: 'text', width: ''},
         {name: 'Price', key: 'price', width: 'min'},
         {name: 'Discounted-price', key: 'discount_price', width: 'min',},
         {name: 'Shipping', key: 'shipping', type: 'boolean', filterKey: 'shipping', width: 'min'},
         {name: 'Status', key: 'status', type: 'status', width: 'min'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'}
      ]
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;
   public adManagerForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public photoService: PhotoService,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         author: new UntypedFormControl('', Validators.compose([])),
         unique_id: new UntypedFormControl('', Validators.compose([])),
         book_title: new UntypedFormControl('', Validators.compose([])),
         introduction: new UntypedFormControl('', Validators.compose([])),
         book_description: new UntypedFormControl('', Validators.compose([])),
         preface: new UntypedFormControl('', Validators.compose([])),
         translator: new UntypedFormControl('', Validators.compose([])),
         foreword: new UntypedFormControl('', Validators.compose([])),
         product_id: new UntypedFormControl('', Validators.compose([])),
         product_format: new UntypedFormControl('', Validators.compose([])),
         publication_date: new UntypedFormControl('', Validators.compose([])),
         publication_month: new UntypedFormControl('', Validators.compose([])),
         publication_year: new UntypedFormControl('', Validators.compose([])),
         language_publication: new UntypedFormControl('', Validators.compose([])),
         price: new UntypedFormControl('', Validators.compose([])),
         discount_price: new UntypedFormControl('', Validators.compose([])),
         weight: new UntypedFormControl('', Validators.compose([])),
         no_of_pages: new UntypedFormControl('', Validators.compose([])),
         edition: new UntypedFormControl('', Validators.compose([])),
         publisher_name: new UntypedFormControl('', Validators.compose([])),
         book_isbn: new UntypedFormControl('', Validators.compose([])),
         sample_pdf: new UntypedFormControl('', Validators.compose([])),
         featured_image: new UntypedFormControl('', Validators.compose([])),
         read_more_url: new UntypedFormControl('', Validators.compose([])),
         descriptor: new UntypedFormControl('', Validators.compose([])),
         multiple_preview_image: new UntypedFormControl('', Validators.compose([])),
         interested_title_one: new UntypedFormControl('', Validators.compose([])),
         interested_url_one: new UntypedFormControl('', Validators.compose([])),
         disclamier: new UntypedFormControl('', Validators.compose([])),
         amazon_link: new UntypedFormControl('', Validators.compose([])),
         flipkart_link: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         key_point_1: new UntypedFormControl('', Validators.compose([])),
         key_point_2: new UntypedFormControl('', Validators.compose([])),
         key_point_3: new UntypedFormControl('', Validators.compose([])),
         key_point_4: new UntypedFormControl('', Validators.compose([])),
         dimension: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         interested_title_two: new UntypedFormControl('', Validators.compose([])),
         interested_url_two: new UntypedFormControl('', Validators.compose([])),
         interested_title_three: new UntypedFormControl('', Validators.compose([])),
         interested_url_three: new UntypedFormControl('', Validators.compose([])),
         sale: new UntypedFormControl('', Validators.compose([])),
         sale_price: new UntypedFormControl('', Validators.compose([])),
         sale_start_date: new UntypedFormControl('', Validators.compose([])),
         sale_end_date: new UntypedFormControl('', Validators.compose([])),
         shipping: new UntypedFormControl('', Validators.compose([])),
         max_order_quantity: new UntypedFormControl('', Validators.compose([])),
         shipping_details: new UntypedFormControl('', Validators.compose([])),
         stock: new UntypedFormControl('', Validators.compose([])),
         banner_image: new UntypedFormControl('', Validators.compose([])),
         bdrt: new UntypedFormControl('', Validators.compose([])),
         courses: new UntypedFormControl('', Validators.compose([])),
         //add
      });
      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl()
      });

      this.adManagerForm = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         field_id: new UntypedFormControl('', Validators.compose([])),
         advertise_type: new UntypedFormControl('', Validators.compose([])),
         image: new UntypedFormControl('', Validators.compose([])),
         url: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),

      });

      this.loadPage();

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/publication/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   saveBdrt() {
      this.loader.start();
      this.api.post('admin/page/save', this.pageForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
         });
   }

   loadPage() {
      this.loader.start();
      this.api.get('admin/page/10', {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.pageForm.patchValue(res);
         });
   }

   delete(item: any) {
      this.loader.start();
      this.api.post('admin/publication/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.table.loadData();
         });
   }

   edit(item) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      if (this.selectedItem.courses) {
         const courses = _.map(this.selectedItem.courses, (it) => {
            return it.id;
         });
         //     console.log(courses);
         this.form.patchValue({courses});

      }
      const modalRef = this.modal.open(this.addNewModal);
   }

   ads(item) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(ManageAdsComponent);
      const config: IAdManagerConfig = {
         adType: 'book',
         isModal: true,
         parent: item.id,
         title: item.book_title

      };
      modalRef.componentInstance.setConfig(config);
   }
}
