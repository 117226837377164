import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, SelectControlValueAccessor} from '@angular/forms';

@Component({
    selector: 'app-main-category-selector',
    templateUrl: './main-category-selector.component.html',
    styleUrls: ['./main-category-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MainCategorySelectorComponent)
        }
    ]
})
export class MainCategorySelectorComponent implements ControlValueAccessor {

    @Output() selected = new EventEmitter();
    @Input() tree;
    @Input() value;

    public propagateChange = (_) => {
    };

    constructor() {
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    changed($event) {
        this.value = $event.target.value;
        this.propagateChange(this.value);
        this.selected.emit(this.value);
    }
}
