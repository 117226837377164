import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {PhotoService} from '../../services/photo.service';

@Component({
    selector: 'app-manage-suggested-book',
    templateUrl: './manage-suggested-book.component.html',
    styleUrls: ['./manage-suggested-book.component.scss']
})
export class ManageSuggestedBookComponent implements OnInit {
    @ViewChild('addNew') addNewModal;
    @ViewChild('table') table;
    public pageId = 42;
    public selectedItem;
    public endpoint = environment.endpoint + 'admin/suggested-book/list';
    public tableConfig: ILaravelDataTableConfig = {
        fields: [
            {name: 'ID', key: 'id', type: 'text', width: 'min'},
            {name: 'Book Image', key: 'image', type: 'image', width: 'min'},
            {
                name: 'Course', key: 'course.course_name', type: 'select',
                filterKey: 'main_course_id',
                selectOptions: this.api.endExamFilter()
            },
            {name: 'Book Name', key: 'name'},
            {name: 'Publisher Name', key: 'publisher'},
            {name: 'Status', key: 'status', type: 'status', width: 'min'},
            {name: 'Sort', key: 'sort', type: 'sort', width: 'min'}
        ],
        actions: [
            {
                name: 'Edit',
                type: 'primary',
                fn: (dataset, index) => {
                    this.selectedItem = dataset[index];
                    this.form.patchValue(this.selectedItem);
                    const modalRef = this.modal.open(this.addNewModal);

                }
            },
            {
                name: 'Delete',
                type: 'danger',
                fn: (dataset, index) => {
                    swal.fire({
                        title: 'Delete :' + dataset[index].title + '?',
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                    }).then(res => {
                        if (res.value) {
                            this.loader.start();
                            this.api.post('admin/suggested-book/delete', {id: dataset[index].id})
                                .pipe(finalize(() => {
                                    this.loader.stop();
                                }))
                                .subscribe((res: any) => {
                                    dataset.splice(index, 1);
                                });
                        }
                    });
                }
            }
        ]
    };
    public form: UntypedFormGroup;
    public pageForm: UntypedFormGroup;
    public tree = [];

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public modal: NgbModal, public photoService: PhotoService,
                public loader: NgxUiLoaderService) {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl('', Validators.compose([])),
            main_course_id: new UntypedFormControl(),
            name: new UntypedFormControl('', Validators.compose([])),
            author: new UntypedFormControl('', Validators.compose([])),
            publisher: new UntypedFormControl('', Validators.compose([])),
            desc: new UntypedFormControl('', Validators.compose([])),
            sort_order: new UntypedFormControl('', Validators.compose([])),
            status: new UntypedFormControl('', Validators.compose([])),
            image: new UntypedFormControl('', Validators.compose([])),
            subject: new UntypedFormControl('', Validators.compose([])),

        });
        this.pageForm = new UntypedFormGroup({
            id: new UntypedFormControl(),
            bdrt: new UntypedFormControl()
        });
    }

    ngOnInit() {
        this.loadTree();
        this.loadPage();
    }

    loadTree() {
        this.api.post('admin/course/get-tree', {}).subscribe((res: any) => {
            this.tree = res;
        });
    }

    add(addNew: TemplateRef<any>) {
        this.selectedItem = null;
        this.form.reset();
        this.modal.open(addNew, {size: 'lg'});
    }

    save() {
        this.loader.start();
        this.api.post('admin/suggested-book/save', this.form.value)
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.modal.dismissAll();
                this.table.loadData();
            });
    }

    saveBdrt() {
        this.loader.start();
        this.api.post('admin/page/save', this.pageForm.value)
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.modal.dismissAll();
            });
    }

    loadPage() {
        this.loader.start();
        this.api.get('admin/page/' + this.pageId, {})
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.pageForm.patchValue(res);
            });
    }

}
