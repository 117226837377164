import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {filter, map} from 'rxjs/operators';

@Component({
   selector: 'app-notice-targets',
   templateUrl: './notice-targets.component.html',
   styleUrls: ['./notice-targets.component.scss'],
   providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoticeTargetsComponent
   }]
})
export class NoticeTargetsComponent implements OnInit, ControlValueAccessor {
   public onChange: (_) => {};
   public interestedPrograms$: Observable<any> = this.api.get('models/interested-programs', {});
   public program$: Observable<any> = this.api.get('models/programs', {}).pipe(
      map((data: any) => data.filter(item => !item.is_test_series))
   );
   public testSeriesProgram$: Observable<any> = this.api.get('models/programs', {}).pipe(
      map((data: any) => data.filter(item => item.is_test_series))
   );
   public config = {
      users: [],
      queries: [],
      programs: [],
      testSeries: []
   }

   constructor(public api: ApiService) {
   }

   ngOnInit(): void {
   }

   sendValue() {
      this.onChange(this.config);
   }

   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {
      if (obj) this.config = {...obj};

   }

}
