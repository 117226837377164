import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {XsLaravelDataTableModule} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.module';
import {NookUiModule} from '../../nook-ui/nook-ui.module';
import {RouterModule} from '@angular/router';
import {ManageTestOrderComponent} from './manage-test-order.component';
import {NgbDropdownModule, NgbNavModule, NgbPopover, NgbPopoverModule, NgbTooltip, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SubPaymentComponent} from './sub-payment/sub-payment.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddEditTransactionComponent} from './add-edit-transaction/add-edit-transaction.component';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {AutoCompleteModule} from 'primeng/autocomplete';

@NgModule({
   declarations: [ManageTestOrderComponent, SubPaymentComponent, AddEditTransactionComponent],
   imports: [
      CommonModule,
      XsLaravelDataTableModule,
      NgbDropdownModule,
      NgbNavModule,
      NookUiModule,
      NgbTooltipModule,
      NgbPopoverModule,
      RouterModule.forChild([{
         path: '',
         component: ManageTestOrderComponent
      }]),
      FormsModule,
      ReactiveFormsModule,
      ButtonModule,
      DialogModule,
      AutoCompleteModule
   ]
})

export class TestOrderModule {}
