import {Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-question-reports',
   templateUrl: './manage-question-reports.component.html',
   styleUrls: ['./manage-question-reports.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class ManageQuestionReportsComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/question-reports/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'User', key: 'user.name', type: 'text'},
         {
            name: 'Type', key: 'type', type: 'select',
            selectOptions: this.api.post('admin/question-reports/types', {})
         },
         {name: 'Comment', key: 'comment', type: 'text'},
         {name: 'Date', key: 'created_at', type: 'dateIST', width: 'min'},

      ],

   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      //  console.log('test');
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
      });

      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
      });
   }

   ngOnInit() {

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/question-reports/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   delete(item: any) {
      this.loader.start();
      this.api.post('admin/question-reports/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.loadData();
         });
   }

   details(item: any) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(this.addNewModal);
   }
}
