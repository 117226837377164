import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserPickerComponent} from '../user-picker/user-picker.component';

@Component({
   selector: 'app-application-editor',
   templateUrl: './application-editor.component.html',
   styleUrls: ['./application-editor.component.scss'],
   providers: [DatePipe]
})
export class ApplicationEditorComponent implements OnInit, AfterViewInit {

   @Input() order;
   @Output() updated = new EventEmitter();

   public form: UntypedFormGroup;
   public category = [
      {label: 'General', value: 'general'},
      {label: 'SC', value: 'sc'},
      {label: 'ST', value: 'st'},
      {label: 'OBC', value: 'obc'},
      {label: 'others', value: 'Others'},
   ];
   public postMatricStreams = [
      {label: 'Medical', value: 'Medical'},
      {label: 'Non Medical', value: 'Non Medical'},
      {label: 'Commerce', value: 'Commerce'},
      {label: 'Arts', value: 'Arts'},
   ];

   public graduationStreams = [
      {label: 'BA', value: 'BA'},
      {label: 'BCOM', value: 'BCOM'},
      {label: 'BSC (Medical)', value: 'BSC (Medical)'},
      {label: 'BSC (Non-Medical)', value: 'BSC (Non-Medical)'},
      {label: 'BALLB', value: 'BALLB'},
      {label: 'BCOM LLB', value: 'BCOM LLB'},
      {label: 'MBBS', value: 'MBBS'},
      {label: 'BTECH', value: 'BTECH'},
      {label: 'OTHERS', value: 'OTHERS'},
   ];

   public programs = [];

   constructor(public api: ApiService, public datePipe: DatePipe, public modal: NgbModal) {
      this.form = new UntypedFormGroup({
         userName: new UntypedFormControl(),
         programName: new UntypedFormControl(),
         id: new UntypedFormControl('', Validators.compose([])),
         user_id: new UntypedFormControl('', Validators.compose([])),
         program_id: new UntypedFormControl('', Validators.compose([])),
         name: new UntypedFormControl('', Validators.compose([])),
         date_of_birth: new UntypedFormControl('', Validators.compose([])),
         gender: new UntypedFormControl('', Validators.compose([])),
         father_name: new UntypedFormControl('', Validators.compose([])),
         mother_name: new UntypedFormControl('', Validators.compose([])),
         father_occupation: new UntypedFormControl('', Validators.compose([])),
         category: new UntypedFormControl('', Validators.compose([])),
         handicapped: new UntypedFormControl('', Validators.compose([])),
         aadhaar_number: new UntypedFormControl('', Validators.compose([])),
         p_address: new UntypedFormControl('', Validators.compose([])),
         p_landmark: new UntypedFormControl('', Validators.compose([])),
         p_city: new UntypedFormControl('', Validators.compose([])),
         p_post_office: new UntypedFormControl('', Validators.compose([])),
         p_pincode: new UntypedFormControl('', Validators.compose([])),
         p_district: new UntypedFormControl('', Validators.compose([])),
         p_state: new UntypedFormControl('', Validators.compose([])),
         c_address: new UntypedFormControl('', Validators.compose([])),
         c_landmark: new UntypedFormControl('', Validators.compose([])),
         c_city: new UntypedFormControl('', Validators.compose([])),
         c_post_office: new UntypedFormControl('', Validators.compose([])),
         c_pincode: new UntypedFormControl('', Validators.compose([])),
         c_district: new UntypedFormControl('', Validators.compose([])),
         c_state: new UntypedFormControl('', Validators.compose([])),
         mobile_1: new UntypedFormControl('', Validators.compose([])),
         mobile_2: new UntypedFormControl('', Validators.compose([])),
         landline: new UntypedFormControl('', Validators.compose([])),
         email: new UntypedFormControl('', Validators.compose([])),
         graduate: new UntypedFormControl('', Validators.compose([])),
         post_matric: new UntypedFormControl('', Validators.compose([])),
         matric_stream: new UntypedFormControl('', Validators.compose([])),
         matric_board: new UntypedFormControl('', Validators.compose([])),
         matric_pass_year: new UntypedFormControl('', Validators.compose([])),
         matric_percentage: new UntypedFormControl('', Validators.compose([])),
         matric_certificate: new UntypedFormControl('', Validators.compose([])),
         graduation_stream: new UntypedFormControl('', Validators.compose([])),
         graduation_university: new UntypedFormControl('', Validators.compose([])),
         graduation_pass_year: new UntypedFormControl('', Validators.compose([])),
         graduation_percentage: new UntypedFormControl('', Validators.compose([])),
         graduation_certificate: new UntypedFormControl('', Validators.compose([])),
         post_matric_stream: new UntypedFormControl('', Validators.compose([])),
         post_matric_board: new UntypedFormControl('', Validators.compose([])),
         post_matric_pass_year: new UntypedFormControl('', Validators.compose([])),
         post_matric_percentage: new UntypedFormControl('', Validators.compose([])),
         post_matric_certificate: new UntypedFormControl('', Validators.compose([])),
         graduation_appearing_stream: new UntypedFormControl('', Validators.compose([])),
         graduation_appearing_university: new UntypedFormControl('', Validators.compose([])),
         graduation_appearing_pass_year: new UntypedFormControl('', Validators.compose([])),
         profile_image: new UntypedFormControl('', Validators.compose([])),
         proof: new UntypedFormControl('', Validators.compose([])),
         sign: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         //add
         partial_payment_due_date: new UntypedFormControl(),
         order_total: new UntypedFormControl(),
         order_coupon_discount: new UntypedFormControl()
      });
      this.form.valueChanges.subscribe(res => {
         this.updated.emit(this.form.value);
      });
   }

   ngOnInit(): void {
      this.loadPrograms();
   }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.form.patchValue(this.order.application);
         const partialPaymentDueDate = this.datePipe.transform(this.order.partial_payment_due_date, 'd-M-yyyy', '530');
         this.form.patchValue({
            userName: this.order.user.email,
            status: this.order.status,
            partial_payment_due_date: partialPaymentDueDate,
            order_total: this.order.order_total,
            order_coupon_discount: this.order.order_coupon_discount,
            program_id: this.order.program_id
         });
      });
   }

   public loadPrograms() {
      this.api.post('admin/programme/program-picker', {}).subscribe((res: any) => {
         this.programs = res;
      });
   }

   pickUser() {
      let m = this.modal.open(UserPickerComponent, {size: 'sm'});
      m.result.then(res => {
         if (res) {
            this.form.patchValue({
               user_id: res.id,
               userName: res.email
            });
         }
      });
   }
}
