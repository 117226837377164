import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import * as _ from 'lodash';

@Component({
    selector: 'app-main-course-node',
    templateUrl: './main-course-node.component.html',
    styleUrls: ['./main-course-node.component.scss']
})
export class MainCourseNodeComponent implements AfterViewInit {
    @Input() tree;
    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() statusChanged = new EventEmitter();
    @Output() manageItems = new EventEmitter();
    @Input() sortEndpoint;
    @Input() studentCourse = false;
    public shown = [];

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public loader: NgxUiLoaderService) {

    }

    ngAfterViewInit() {
    }

    drop(event, arr) {
        moveItemInArray(arr, event.previousIndex, event.currentIndex);
        const sortedList = [];
        this.tree[event.currentIndex].sort = event.currentIndex;
        this.tree.forEach((item, index) => {
            sortedList.push({id: item.id, sort: 1 + index, name: item.course_name});
        });

        this.sortEndpoint = this.sortEndpoint ? this.sortEndpoint : 'admin/main-course/sort';
        this.api.post(this.sortEndpoint, {sortedList}).subscribe(res => {

        });
    }

    updateOrderOnServer() {

    }

    showPanel(id: number) {
        const itemIndex = this.shown.indexOf(id);
        if (itemIndex > -1) {
            this.shown.splice(itemIndex, 1);
        } else {
            this.shown.push(id);
        }
    }
}
