import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
   selector: 'app-program-category-selector',
   templateUrl: './program-category-selector.component.html',
   styleUrls: ['./program-category-selector.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: forwardRef(() => ProgramCategorySelectorComponent)
      }
   ]
})
export class ProgramCategorySelectorComponent implements ControlValueAccessor {

   constructor() {
   }

   public value = [];
   $types = [

      // {name: 'Online Programs', value: 16},
      // {name: 'Distance Learning Programs', value: 18},
      // {name: 'Current Affairs Programs', value: 19},
      // {name: 'Answer Writing Practice Programs', value: 20},
      // {name: 'Study Material', value: 21},

      {name: 'Classroom Programs', value: 14},
      {name: 'Test Series Programs', value: 15},
      {name: 'Interview Guidance Programs', value: 17},
      {name: 'Online/Distance Learning', value: 22},
   ];
   public propagateChange = (_) => {
   };

   registerOnChange(fn: any): void {
      this.propagateChange = fn;
   }

   registerOnTouched(fn: any): void {
   }

   setDisabledState(isDisabled: boolean): void {
   }

   writeValue(obj: any): void {
      if (Array.isArray(obj)) {
         this.value = obj;
      }
   }

   change(val) {
      const index = this.value.indexOf(val);
      if (index > -1) {
         this.value.splice(index, 1);
      } else {
         this.value.push(val);
         this.value = [].concat(this.value);
      }

      this.propagateChange(this.value);
   }
}
