import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {type} from 'os';
import {DatePipe} from '@angular/common';
import {PhotoService} from '../../services/photo.service';
import * as lod from 'lodash';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-book-coupon',
   templateUrl: './manage-book-coupon.component.html',
   styleUrls: ['./manage-book-coupon.component.scss'],
   providers: [DatePipe]
})
export class ManageBookCouponComponent implements OnInit {
   @ViewChild('detail') detail;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public selectedIndex;
   public endpoint = environment.endpoint + 'admin/book-coupon/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Code', key: 'code', type: 'text', width: 'min'},
         {name: 'Batch', key: 'batch_name', sortable: true, filterKey: '#book_coupon_batches.name'},
         {name: 'Created', key: 'created_at', type: 'dateIST'}
      ],
   };
   public form: UntypedFormGroup;
   public showAddNewModal = false;
   public showBatchModal = false;
   public couponBatches = [];
   public showEditCouponModal = false;
   public editForm: UntypedFormGroup;
   public showExportModal = false;
   public exportForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router, public datePipe: DatePipe,
               public modal: NgbModal, public photoService: PhotoService,
               public loader: NgxUiLoaderService) {

      this.form = new UntypedFormGroup({
         no_of_coupons: new UntypedFormControl('', Validators.required),
         batch_id: new UntypedFormControl('', Validators.required)
      });

      this.editForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         code: new UntypedFormControl(),
         batch_id: new UntypedFormControl(),
         usage_limit: new UntypedFormControl()
      });

      this.exportForm = new UntypedFormGroup({
         batch_id: new UntypedFormControl('', Validators.required),
         status: new UntypedFormControl(),
         from: new UntypedFormControl(),
         to: new UntypedFormControl()
      });
   }

   ngOnInit() {
      this.loadCouponBatches();
   }

   loadCouponBatches() {
      this.loader.start();
      this.api.post('admin/book-coupon-batch/dropdown', {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {this.couponBatches = res;});
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/book-coupon/bulk', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
            this.showAddNewModal = false;
         });
   }

   delete(item, idx) {

      this.loader.start();
      this.api.post('admin/book-coupon/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(idx);
         });
   }

   viewDetails(item) {
      this.selectedItem = item;
      this.modal.open(this.detail);
   }

   edit(item, idx) {
      this.selectedIndex = idx;
      this.selectedItem = item;
      this.editForm.patchValue(item);
      this.showEditCouponModal = true;
   }

   processEdit() {
      this.loader.start();
      this.api.post('admin/book-coupon/save', this.editForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.showEditCouponModal = false;
            lod.merge(this.selectedItem, res.data);
         });
   }

   export() {
      this.loader.start();
      this.api.post('admin/book-coupon/export', this.exportForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }
}
