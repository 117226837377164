<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Books</h3>
   <div>
      <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>

      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>

<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="ads(item)">Advertisement</button>
         <button class="btn btn-sm btn-info" (click)="edit(item)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Publication</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">

         <form [formGroup]="form">
            <input type="hidden" formControlName="id" class="form-control">

            <div class="row">
               <div class="col-sm-12">
                  <div class="hidden d-none">
                     <div class="section">BDRT</div>
                     <app-bdrt formControlName="bdrt"></app-bdrt>
                  </div>
                  <div class="row">
                     <div class="col-sm-8">
                        <div class="section">Book</div>
                        <div class="row">
                           <div class="col-4">
                              <div class="form-group">
                                 <label>BOOK TITLE</label>
                                 <input type="text" formControlName="book_title" class="form-control">
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="form-group">
                                 <label>BOOK ID</label>
                                 <input type="text" formControlName="unique_id" class="form-control">
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="form-group">
                                 <label>SORT ORDER</label>
                                 <input type="text" appNumberOnly formControlName="sort_order" class="form-control">
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <label>COURSES</label>
                                 <app-course-tree-selection formControlName="courses"></app-course-tree-selection>

                              </div>
                           </div><!--selction-->

                           <div class="col-6">
                              <div class="form-group">
                                 <label>KEY POINT 1</label>
                                 <input type="text" formControlName="key_point_1" class="form-control">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label>KEY POINT 2</label>
                                 <input type="text" formControlName="key_point_2" class="form-control">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label>KEY POINT 3</label>
                                 <input type="text" formControlName="key_point_3" class="form-control">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label>KEY POINT 4</label>
                                 <input type="text" formControlName="key_point_4" class="form-control">
                              </div>
                           </div>

                           <div class="col-sm-12">
                              <div class="section">Vital Info</div>
                              <div class="row">
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>AUTHOR</label>
                                       <input type="text" formControlName="author" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>EDITION</label>
                                       <input type="text" formControlName="edition" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>ISBN</label>
                                       <input type="text" formControlName="book_isbn" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>PUBLISHED BY</label>
                                       <input type="text" formControlName="publisher_name" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>PRODUCT FORMAT</label>
                                       <select formControlName="product_format" class="form-control">
                                          <option value="Hardbound">Hardbound</option>
                                          <option value="Paperback"> Paperback</option>
                                       </select>

                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>NO OF PAGES</label>
                                       <input type="text" appNumberOnly formControlName="no_of_pages" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>PUBLICATION MONTH</label>
                                       <input type="text" formControlName="publication_month" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>PUBLICATION YEAR</label>
                                       <input type="text" formControlName="publication_year" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>WEIGHT</label>
                                       <input type="text" formControlName="weight" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>LANGUAGE</label>
                                       <input type="text" formControlName="language_publication" class="form-control">
                                    </div>
                                 </div>
                              </div>

                           </div>


                           <div class="col-12">
                              <div class="section">Book Details</div>
                              <div class="row">
                                 <div class="col-12">
                                    <div class="form-group">
                                       <label>BOOK DESCRIPTION</label>
                                       <app-editor formControlName="book_description"></app-editor>
                                    </div>
                                 </div>

                                 <div class="col-sm-12">
                                    <div class="form-group">
                                       <label>Contents</label>
                                       <app-editor formControlName="introduction"></app-editor>
                                    </div>
                                 </div>
                                 <div class="col-sm-12">
                                    <label for="" class="d-block"> Sample PDF</label>
                                    <app-doc-uploader formats=".pdf" formControlName="sample_pdf" directory="book/sample-pdf"></app-doc-uploader>
                                 </div>
                              </div>
                           </div>
                           <div class="col-sm-12">
                              <div class="section">Pricing</div>
                              <div class="row">

                                 <div class="col-4">
                                    <div class="form-group">
                                       <label>MRP</label>
                                       <input appNumberOnly type="text" formControlName="price" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-4">
                                    <div class="form-group">
                                       <label>DISCOUNTED PRICE</label>
                                       <input appNumberOnly type="text" formControlName="discount_price" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-12 mt-3 mb-3">
                                    <label for="" class="d-block">Sale</label>
                                    <app-toggle-switch formControlName="sale"></app-toggle-switch>
                                 </div>
                                 <ng-container *ngIf="form.value.sale">
                                    <div class="col-sm-4">
                                       <div class="form-group">
                                          <label>SALE PRICE</label>
                                          <input appNumberOnly type="text" formControlName="sale_price" class="form-control">
                                       </div>
                                    </div>
                                    <div class="col-sm-4">
                                       <div class="form-group">
                                          <label>SALE START DATE</label>
                                          <div class="input-group mb-3">
                                             <input readonly #sale_start_date="ngbDatepicker" (focus)="sale_start_date.toggle()" ngbDatepicker type="text"
                                                    formControlName="sale_start_date" class="form-control">
                                             <div class="input-group-append" (click)="sale_start_date.toggle()">
                                                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                             </div>
                                          </div>
                                       </div>

                                    </div>
                                    <div class="col-sm-4">
                                       <div class="form-group">
                                          <label>SALE END DATE</label>

                                          <div class="input-group mb-3">
                                             <input readonly #sale_end_date="ngbDatepicker" ngbDatepicker type="text" formControlName="sale_end_date" class="form-control">
                                             <div class="input-group-append" (click)="sale_end_date.toggle()">
                                                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </ng-container>

                              </div>
                           </div>
                           <div class="col-sm-12">
                              <div class="section">Third Party</div>
                              <div class="row">
                                 <div class="col-12">
                                    <div class="form-group">
                                       <label>DISCLAMIER</label>
                                       <input type="text" formControlName="disclamier" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="form-group">
                                       <label>AMAZON LINK</label>
                                       <input type="text" formControlName="amazon_link" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="form-group">
                                       <label>FLIPKART LINK</label>
                                       <input type="text" formControlName="flipkart_link" class="form-control">
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div class="col-sm-12">
                              <div class="section">Shipping</div>
                              <div class="row">
                                 <div class="col-sm-12 mt-3 mb-3">
                                    <label for="" class="d-block"> Shipping</label>
                                    <app-toggle-switch formControlName="shipping"></app-toggle-switch>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>STOCK AVAILABLE</label>
                                       <input appNumberOnly type="text" formControlName="stock" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <label>MAXIMUM ORDER QUANTITY</label>
                                       <input appNumberOnly type="text" formControlName="max_order_quantity" class="form-control">
                                    </div>
                                 </div>
                                 <div class="col-sm-12">
                                    <app-shipping-input formControlName="shipping_details" [quantity]="form.value.max_order_quantity"></app-shipping-input>
                                 </div>
                              </div>
                           </div>


                           <div class="col-6">
                              <div class="form-group">
                                 <label class="d-block">STATUS</label>
                                 <app-status type="text" formControlName="status"></app-status>
                              </div>
                           </div>


                        </div>
                     </div>
                     <div class="col-sm-4">

                        <label for="" class="d-block mt-4">THUMBNAIL IMAGE</label>
                        <app-photo-uploader dir="publications" formControlName="featured_image"></app-photo-uploader>

                        <div class="form-group mt-4">
                           <label>EXTRA PREVIEW IMAGES</label>
                           <app-multi-pic-uploader formControlName="multiple_preview_image"></app-multi-pic-uploader>
                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </form>

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <app-bdrt formControlName="bdrt"></app-bdrt>
         <input type="hidden" formControlName="id" class="form-control">
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
