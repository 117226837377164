export class Constants {
   static status = [
      'Publish',
      'Draft',
      'Private'
   ];
   static courseCategory = {
      classroom_programs: 'Classroom Programs,',
      test_series_programs: 'Test Series Programs',
      online_programs: 'Online Programs',
      interview_guidance_programs: 'Interview Guidance Programs',
      distance_learning_programs: 'Distance Learning Programs',
      current_affair_programs: 'Current Affair Programs',
      answer_writing_practice_programs: 'Answer Writing Practice Programs',
      study_material: 'Study Material'

   };
   static category = [
      {label: 'General', value: 'general'},
      {label: 'SC', value: 'sc'},
      {label: 'ST', value: 'st'},
      {label: 'OBC', value: 'obc'},
      {label: 'others', value: 'Others'},
   ];
   static editorOptions = {
      heightMin: 200,
      fontFamily: {
         Asees: 'Asses',
      },
   };
}
