<div class="wrap">
    <ul cdkDropList (cdkDropListDropped)="drop($event,tree)">
        <li cdkDrag *ngFor="let i of tree;let idx=index">
            <div>
                <div class="placeholder" *cdkDragPlaceholder></div>
                <div *cdkDragPreview class="preview">{{i.course_name}}</div>
                <div class="d-flex align-items-center">
                    <i class="fa fa-sort mr-2"> </i>
                    <span class="name">{{i.course_name}}</span>
                    <div class="actions">
                        <!--                    <button (click)="showPanel(i.id)" class="btn btn-sm btn-secondary mr-2">{{shown.indexOf(i.id) > -1 ? 'Hide' : 'Show'}} Sub Courses</button>-->
                        <button *ngIf="studentCourse && i.sub_courses.length==0" class="btn btn-sm btn-secondary ml-3 mr-3" (click)="manageItems.emit(i)"> Manage Content</button>

                        <app-status size="sm" [status]="i.status" (statusChanged)="statusChanged.emit({item:i, status:$event})"></app-status>
                        <button class="btn btn-sm btn-primary ml-3 mr-3" (click)="edit.emit(i)"><i class="fa fa-edit"></i> Edit</button>
                        <button class="btn btn-sm  btn-danger" (click)="delete.emit(i)"><i class="fa fa-times"></i> Delete</button>
                    </div>
                </div>
            </div>
            <app-main-course-node
                    [sortEndpoint]="sortEndpoint"
                    [studentCourse]="studentCourse"
                    [tree]="i.sub_courses"
                    (manageItems)="manageItems.emit($event)"
                    (edit)="edit.emit($event)"
                    (delete)="delete.emit($event)"
                    (statusChanged)="statusChanged.emit($event)"
            ></app-main-course-node>
        </li>
    </ul>
</div>
