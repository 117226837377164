<div class="tw-bg-blue-50  -tw-mt-4 -tw-ml-4 tw-p-4">
   <h2 class="tw-p-0 tw-m-0">Who we are</h2>
</div>

<label class="tw-mt-4 tw-font-bold">BDRT</label>
<app-bdrt [(ngModel)]="data.bdrt"></app-bdrt>

<label class="tw-mt-4 tw-font-bold">About Us</label>
<app-editor [(ngModel)]="data.about_us"></app-editor>

<label class="tw-mt-4 tw-font-bold">About Director</label>
<app-editor [(ngModel)]="data.about_director"></app-editor>

<label class="tw-mt-4 tw-font-bold" for="">Director Image</label>
<app-photo-uploader [(ngModel)]="data.director_image"></app-photo-uploader>

<label class="tw-mt-4 tw-font-bold">From the Director's desk</label>
<app-editor [(ngModel)]="data.director_message"></app-editor>

<a (click)="save()" class="mt-4 tw-px-4 tw-py-2 tw-inline-block tw-bg-green-500 tw-text-white">Save</a>
