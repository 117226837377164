<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage user</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="name" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.profile_image" [width]="100" height="100" style="margin-right: 8px;"></app-cdn-image>
         {{item.name}}
      </div>
   </ng-template>
   <ng-template field="app_installed" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.app_installed==1? 'YES':'NO'"></app-badge>
   </ng-template>
   <ng-template field="enabled" let-idx="index" lt-cell-template="" let-item="">
      <a [ngbTooltip]="item.enabled?'Lock':'Un-lock'" (click)="enableOrDisableUser(item)" class="btn"><i class="fa fa-lock" [class]="{'text-danger': !item.enabled}"></i></a>
   </ng-template>
   <ng-template field="referred_by" let-idx="index" lt-cell-template="" let-item="">
      {{item.referred_by?.name}}
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} user</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id" class="form-control">

         <div class="row">
            <div class="col-sm-9">
               <div class="row">
                  <div class="col-4">
                     <div class="form-group">
                        <label>NAME</label>
                        <input type="text" formControlName="name" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>GENDER</label>
                        <select formControlName="gender" class="form-control">
                           <option value="male">Male</option>
                           <option value="female">Female</option>
                           <option value="third_gender">Third Gender</option>
                        </select>
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>DATE OF BIRTH</label>
                        <input type="text" formControlName="date_of_birth" class="form-control">
                     </div>
                  </div>

                  <div class="col-4">
                     <div class="form-group">
                        <label>FATHER NAME</label>
                        <input type="text" formControlName="father_name" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>FATHER OCCUPATION</label>
                        <input type="text" formControlName="father_occupation" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>MOTHER NAME</label>
                        <input type="text" formControlName="mother_name" class="form-control">
                     </div>
                  </div>

                  <div class="col-12">
                     <div class="form-group">
                        <label>HANDICAPPED</label>
                        <app-toggle-switch label0="NO" label1="YES" formControlName="handicapped"></app-toggle-switch>

                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>AADHAAR NUMBER</label>
                        <input type="text" formControlName="aadhaar_number" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>CATEGORY</label>
                        <select formControlName="category" class="form-control">
                           <option value="general">General</option>
                           <option value="sc">SC</option>
                           <option value="st">ST</option>
                           <option value="obc">OBC</option>
                           <option value="others">Others</option>
                        </select>
                     </div>
                  </div>

                  <div class="col-4">
                     <div class="form-group">
                        <label>ROLE</label>
                        <select formControlName="role" class="form-control">
                           <option *ngFor="let r of roles" [value]="r.id">{{r.name}}</option>
                        </select>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="section">Password Update</div>
                     <div class="form-group">
                        <label>PASSWORD</label>
                        <input type="text" formControlName="password" class="form-control">
                     </div>
                  </div>
                  <div class="col-sm-12">
                     <div class="section">Contact Details</div>
                  </div>


                  <div class="col-4">
                     <div class="form-group">
                        <label>EMAIL</label>
                        <input type="text" formControlName="email" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>MOBILE 1</label>
                        <input type="text" formControlName="phone_no" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>MOBILE 2</label>
                        <input type="text" formControlName="mobile2" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <div class="form-group">
                        <label>LANDLINE</label>
                        <input type="text" formControlName="landline" class="form-control">
                     </div>
                  </div>
                  <div class="col-4">
                     <label for="">Phone Verified</label>
                     <app-toggle-switch label0="NO" label1="YES" formControlName="phone_status"></app-toggle-switch>
                  </div>
                  <div class="col-4">
                     <label for="">Email Verified</label>
                     <app-toggle-switch label0="NO" label1="YES" formControlName="email_status"></app-toggle-switch>
                  </div>

                  <div class="col-6">
                     <div class="section">Permanent Address</div>
                     <div class="form-group">
                        <label>LANDMARK</label>
                        <input type="text" formControlName="landmark" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>PERMANENT ADDRESS</label>
                        <input type="text" formControlName="permanent_address" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>CITY</label>
                        <input type="text" formControlName="city" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>POST OFFICE</label>
                        <input type="text" formControlName="post_office" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>PIN CODE</label>
                        <input type="text" formControlName="pin_code" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>DISTRICT</label>
                        <input type="text" formControlName="district" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>STATE</label>
                        <input type="text" formControlName="state" class="form-control">
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="section">Correspondence Address</div>
                     <div class="form-group">
                        <label>CORRESPONDENCE ADDRESS</label>
                        <input type="text" formControlName="correspondence_address" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>COR LANDMARK</label>
                        <input type="text" formControlName="cor_landmark" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>COR CITY</label>
                        <input type="text" formControlName="cor_city" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>COR POST_OFFICE</label>
                        <input type="text" formControlName="cor_post_office" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>COR PIN_CODE</label>
                        <input type="text" formControlName="cor_pin_code" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>COR STATE</label>
                        <input type="text" formControlName="cor_state" class="form-control">
                     </div>
                     <div class="form-group">
                        <label>COR DISTRICT</label>
                        <input type="text" formControlName="cor_district" class="form-control">
                     </div>
                  </div>

                  <div class="col-12">
                     <div class="section">Qualification</div>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>QUALIFICATION</label>
                        <input type="text" formControlName="qualification" class="form-control">
                     </div>
                  </div>


               </div>

            </div>
            <div class="col-sm-3">
               <div class="form-group">
                  <label>PROFILE IMAGE</label>
                  <app-photo-uploader dir="profile-pic" formControlName="profile_image"></app-photo-uploader>
               </div>
               <div class="form-group">
                  <label>SIGNATURE</label>
                  <app-photo-uploader dir="signs" formControlName="sign"></app-photo-uploader>
               </div>
               <div class="form-group">
                  <label>ID PROOF</label>
                  <app-photo-uploader dir="proofs" formControlName="id_proof"></app-photo-uploader>
               </div>

            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>


<ng-template #rowActions let-item="item">
   <div ngbDropdown class="d-inline-block">
      <button class="btn btn-sm btn-primary" ngbDropdownToggle>Actions</button>
      <div ngbDropdownMenu>
         <button (click)="editUser(item)" ngbDropdownItem><i class="fa fa-user mr-3"></i>Edit User</button>
         <div class="dropdown-divider"></div>
         <button (click)="restore(item)" i ngbDropdownItem class="text-success" *ngIf="item.deleted_at"><i class="fa fa-trash-restore mr-3"></i>Restore</button>
         <button (click)="deleteUser(item)" ngbDropdownItem class="text-danger"><i class="fa fa-times mr-3"></i>Delete</button>
      </div>
   </div>
</ng-template>
<ng-template #userStatus let-item="item">
   <a [ngbTooltip]="item.enabled?'Lock':'Un-lock'" (click)="enableOrDisableUser(item)" class="btn"><i class="fa fa-lock" [class]="{'text-danger': !item.enabled}"></i></a>
</ng-template>
