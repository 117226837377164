import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from './services/auth.service';
import * as lodash from 'lodash';

@Directive({
   selector: '[appPermissionCheck]'
})
export class PermissionCheckDirective implements OnInit {
   public permissions;

   @Input() set appPermissionCheck(permissions) {
      this.permissions = permissions;
   }

   constructor(public auth: AuthService,
               private templateRef: TemplateRef<any>,
               private viewContainer: ViewContainerRef) {

   }

   ngOnInit(): void {

      if (!Array.isArray(this.permissions)) {
         this.permissions = [this.permissions];
      }
      const intersection = lodash.intersection(this.permissions, this.auth.user.permissions);

      if (intersection.length > 0) {
         this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
         this.viewContainer.clear();
      }
   }

}
