<ng-container *ngIf="order">
   <div *ngIf="order.status.toLowerCase()=='paid'" class="alert alert-success">Payment Completed</div>
   <div *ngIf="order.status.toLowerCase()=='failed'" class="alert alert-danger">Payment Failed</div>
   <div *ngIf="order.status.toLowerCase()=='pending'" class="alert alert-warning">Payment Pending</div>
   <div class="section">Order Details</div>
   <div> Order ID: <strong>{{order.id}}</strong></div>
   <div> Order Date: <strong>{{order.created_at | date:'short'}}</strong></div>
   <div> User : <strong>{{order.user.name }} </strong></div>
   <div> User Email : <strong><a [href]="'mailto:'+order.user.email">{{order.user.email}}</a></strong></div>
   <div> User Phone : <strong><a [href]="'tel:'+order.user.phone_no">{{order.user.phone_no}}</a></strong></div>
   <div class="mb-3"></div>
   <div class="row">
      <div class="col-sm-6">
         <h5 class="section">Shipping Details</h5>
         <div><label for="">Address</label>{{order.shipping_address.address}}</div>
         <div><label for="">Landmark</label>{{order.shipping_address.landmark}}</div>
         <div><label for="">City/Town</label>{{order.shipping_address.city}}</div>
         <div><label for="">Post Office</label>{{order.shipping_address.po}}</div>
         <div><label for="">State</label>{{order.shipping_address.state}}</div>
         <div><label for="">Pincode</label>{{order.shipping_address.pincode}}</div>
      </div>
      <div class="col-sm-6">
         <h5 class="section">Billing Details</h5>
         <div><label for="">Address</label>{{order.billing_address.address}}</div>
         <div><label for="">Landmark</label>{{order.billing_address.landmark}}</div>
         <div><label for="">City/Town</label>{{order.billing_address.city}}</div>
         <div><label for="">Post Office</label>{{order.billing_address.po}}</div>
         <div><label for="">State</label>{{order.billing_address.state}}</div>
         <div><label for="">Pincode</label>{{order.billing_address.pincode}}</div>
      </div>
   </div>
   <div class="section mt-3">Order Items</div>
   <table class="table table-sm table-striped">
      <tbody>
      <tr>
         <td>S.no</td>
         <td>Name</td>
         <td>Price</td>
         <td>Quantity</td>
         <td>Net</td>
      </tr>
      <tr *ngFor="let item of order.items;let idx=index">
         <td>{{idx + 1}}</td>
         <td>{{item.product_name}}</td>
         <td>{{item.price|currency:'INR'}}</td>
         <td>{{item.quantity}}</td>
         <td>{{item.quantity * item.price |currency:'INR'}}</td>
      </tr>
      </tbody>
      <tr>
         <td colspan="4" class="text-right font-weight-bold">Sub Total</td>
         <td class="">{{order.order_subtotal|currency:'INR'}}</td>
      </tr>
      <tr *ngIf="-order.order_coupon_discount>0">
         <td colspan="4" class="text-right font-weight-bold">Discount</td>
         <td class="">{{order.order_coupon_discount|currency:'INR'}}</td>
      </tr>
      <tr *ngIf="order.order_shipping_charges>0">
         <td colspan="4" class="text-right font-weight-bold">Delivery Charges</td>
         <td class="">{{order.order_shipping_charges|currency:'INR'}}</td>
      </tr>
      <tr>
         <td colspan="4" class="text-right font-weight-bold"> Total</td>
         <td class="">{{order.order_total|currency:'INR'}}</td>
      </tr>
   </table>

   <div class="section">Transactions</div>
   <table class="table table-striped table-sm">
      <tbody>
      <tr>
         <td>S.no</td>

         <td>Amount</td>
         <td>Bank Reference number</td>
         <td>Bank/Card Name</td>
         <td>Status</td>
      </tr>
      <tr *ngFor="let t of order.transactions;let idx=index">
         <td>{{idx + 1}}</td>
         <td>{{t.amount|currency:'INR'}}</td>
         <td>{{t.bank_ref_no}}</td>
         <td>{{t.bank_or_card_name}}</td>
         <td>{{t.gateway_status}}</td>
      </tr>
      </tbody>
   </table>
</ng-container>
