<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Student Course Management</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="addCourse()"> Add New</button>
   </div>
</div>
<app-main-course-node [tree]="tree"
                      studentCourse="true"
                      (delete)="deleteCourse($event)"
                      (edit)="editCourse($event)"
                      (statusChanged)="statusChanged($event)"
                      (manageItems)="manageItems($event)"
                      sortEndpoint="admin/student-course/sort"></app-main-course-node>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">Add Course</h5>
      <button type="button" class="close" data-dismiss="modal" (click)="modal.close()" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="addCourseForm" (submit)="saveCourse()">
         <div class="form-group">
            <label for="">Course Name</label>
            <input type="text" formControlName="course_name" class="form-control">
         </div>

         <div class="form-group">
            <label for="">Parent Course</label>
            <select formControlName="parent" class="form-control">
               <option value="0">--No Parent--</option>
               <option *ngFor="let i of flatten" [value]="i.value">{{i.label}}</option>
            </select>
         </div>

         <div class="for-group mb-3">
            <label for="">Icon</label>
            <app-icon-uploader formControlName="icon"></app-icon-uploader>
         </div>
         <div class="form-group">
            <label for="" class="d-block">Status</label>
            <app-status formControlName="status"></app-status>
         </div>
      </form>

   </div>
   <div class="modal-footer  ">

      <button type="button" class="btn btn-secondary" (click)="modal.close()" data-dismiss="modal">Close</button>
      <button (click)="saveCourse()" type="button" class="btn btn-primary">{{addCourseForm.value.id ? 'Update' : 'Add'}}</button>

   </div>

</ng-template>
<ng-template #courseItems let-modal>
   <div class="modal-header">
      <h5 class="modal-title">Manage <strong>{{currentCourse.course_name}}</strong> items</h5>
      <button (click)="modal.close()" type="button" class="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>

   <div class="modal-body">
      <div class="row">
         <div class="col-sm-8">
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li *ngFor="let b of breadCrumbs;let i=index" class="breadcrumb-item" (click)="breadcrumbClick(b,i)">{{b.name}}</li>
               </ol>
            </nav>

            <xs-laravel-data-table
               (instance)="getInstance($event)"
               [endpoint]="endpoint"
               [config]="tableConfig"
            ></xs-laravel-data-table>


         </div>
         <div class="col-sm-4">
            <div class="section">{{addItemForm.value.id ? 'Update' : "Add New"}} Item</div>
            <form [formGroup]="addItemForm" (keydown.enter)="$event.preventDefault()">
               <input type="hidden" formControlName="student_item_id">

               <div class="form-group">
                  <label for="">Name</label>
                  <input formControlName="name" type="text" class="form-control">
               </div>

               <div class="form-group">
                  <label for="">Type</label>
                  <select formControlName="type" class="form-control">
                     <option value="folder">Folder</option>
                     <option value="document">Document/File</option>
                     <option value="link">Link</option>
                     <option value="video">Video</option>
                     <option value="youtube">Youtube Video</option>
                  </select>
               </div>

               <div class="form-group" *ngIf="addItemForm.value.type=='folder'">
                  <label class="d-block">Icon</label>
                  <app-icon-uploader formControlName="icon"></app-icon-uploader>
               </div>
               <ng-container *ngIf="addItemForm.value.type && addItemForm.value.type !='folder'">
                  <ng-container [ngSwitch]="addItemForm.value.type">
                     <div class="form-group" *ngSwitchCase="'youtube'">
                        <label class="d-block">Youtube Video Url</label>
                        <input class="form-control" formControlName="file"/>
                     </div>
                     <div class="form-group" *ngSwitchCase="'link'">
                        <label class="d-block">Link Url</label>
                        <input class="form-control" formControlName="file"/>
                     </div>

                     <div class="form-group" *ngSwitchDefault>
                        <label class="d-block">Upload File</label>
                        <app-doc-uploader endpoint="admin/upload-doc" formControlName="file"></app-doc-uploader>
                     </div>

                  </ng-container>

                  <div class="form-group">
                     <label for="" class="d-block">Available to all</label>
                     <app-toggle-switch formControlName="available_to_all" label0="NO" label1="YES"></app-toggle-switch>
                  </div>
                  <div class="form-group" [hidden]="addItemForm.value.available_to_all===1">
                     <label for="" class="d-block">Available for </label>
                     <app-program-picker formControlName="programs"></app-program-picker>
                  </div>

               </ng-container>

               <div class="row">
                  <div class="col-sm-6">
                     <div class="form-group">
                        <label for="" class="d-block">Sort Order</label>
                        <input class="form-control" formControlName="sort_order" appNumberOnly/>
                     </div>
                  </div>
                  <div class="col-sm-6">
                     <div class="form-group">
                        <label for="" class="d-block">Status</label>
                        <app-status formControlName="status"></app-status>
                     </div>
                  </div>
               </div>

               <button (click)="saveFile()" class="btn btn-block btn-success">{{addItemForm.value.id ? 'Update Item' : "Save Item"}}</button>
               <button (click)="resetForm()" class="btn btn-block btn-secondary mt-3" *ngIf="addItemForm.value.id">Cancel Update</button>
            </form>


         </div>
      </div>
   </div>


</ng-template>
<ng-template #actions let-item="item">
   <div class="d-flex">
      <button class="btn flex-fill btn-sm btn-secondary" (click)="open(item)" *ngIf="item.type==='folder'">Open</button>
      <button class="btn flex-fill btn-sm btn-primary" (click)="editItem(item)">Update</button>
      <button class="btn flex-fill btn-sm btn-danger" (click)="deleteItem(item)">Delete</button>
   </div>
</ng-template>
