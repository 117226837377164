import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-manage-email-setting',
    templateUrl: './manage-email-setting.component.html',
    styleUrls: ['./manage-email-setting.component.scss']
})
export class ManageEmailSettingComponent {

    public form: UntypedFormGroup;

    constructor(public toastr: ToastrService,
                public api: ApiService,
                public auth: AuthService,
                public router: Router,
                public loader: NgxUiLoaderService) {

        this.form = new UntypedFormGroup({
            concern: new UntypedFormGroup({
                get: new UntypedFormControl(),
                send: new UntypedFormControl(),
                user_email: new UntypedFormControl(),
                admin_email: new UntypedFormControl(),
                user_sms: new UntypedFormControl(),
                admin_sms: new UntypedFormControl(),
                subject: new UntypedFormControl(),
                message: new UntypedFormControl(),
            }),
            callback: new UntypedFormGroup({
                get: new UntypedFormControl(),
                send: new UntypedFormControl(),
                user_email: new UntypedFormControl(),
                admin_email: new UntypedFormControl(),
                subject: new UntypedFormControl(),
                message: new UntypedFormControl(),
            }),
            book_order: new UntypedFormGroup({
                get: new UntypedFormControl(),
                send: new UntypedFormControl(),
                user_email: new UntypedFormControl(),
                admin_email: new UntypedFormControl(),
                subject: new UntypedFormControl(),
                message: new UntypedFormControl(),
            }),
            forgot_password: new UntypedFormGroup({
                send: new UntypedFormControl(),
                subject: new UntypedFormControl()
            })
        });

        this.loadSettings();

    }

    save() {
        this.loader.start();
        this.api.post('admin/settings/save', {key: 'emails', value: this.form.value})
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {

            });

    }

    public loadSettings() {
        this.loader.start();
        this.api.post('admin/settings', {key: 'emails'})
            .pipe(finalize(() => {
                this.loader.stop();
            }))
            .subscribe((res: any) => {
                this.form.patchValue(res);
            });
    }
}
