<h1>Contact Page Settings</h1>
<form [formGroup]="form">
    <div>
        <div class="section">BDRT</div>
        <app-bdrt formControlName="bdrt"></app-bdrt>
    </div>
    <div formGroupName="meta">
        <div>
            <div class="section">Talk to our associate</div>
            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="associate_title">
            </div>
            <label class="d-block">Content</label>
            <app-editor formControlName="associate_content"></app-editor>
        </div>
        <div>
            <div class="section">Request a callback</div>
            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="callback_title">
            </div>
            <div class="form-group">
                <label class="d-block">Content</label>
                <app-editor formControlName="callback_content"></app-editor>
            </div>
            <div class="form-group">
                <label>Accept Message</label>
                <input type="text" class="form-control" formControlName="callback_accept_message">
            </div>
        </div>

        <div>
            <div class="section">Email Your concern</div>
            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="concern_title">
            </div>
            <label class="d-block">Content</label>
            <app-editor formControlName="concern_content"></app-editor>
        </div>
        <div>
            <div class="section">Share your happiness</div>
            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="happiness_title">
            </div>
            <label class="d-block">Content</label>
            <app-editor formControlName="happiness_content"></app-editor>
        </div>
        <div>
            <div class="section">Partner with us</div>
            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="partner_title">
            </div>
            <label class="d-block">Content</label>
            <app-editor formControlName="partner_content"></app-editor>
        </div>
        <div>
            <div class="section">Get in touch</div>
            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="touch_title">
            </div>
            <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control" formControlName="touch_address">
            </div>
            <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control" formControlName="touch_phone">
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" formControlName="touch_email">
            </div>

        </div>
    </div>
</form>
<div class="mb-5"></div>
<button class="btn   mt-4 btn-success btn-lg" (click)="save()" style="position: fixed;right: 10px;bottom: 10px;z-index: 100;"><i class="fa fa-save mr-2"></i>Save Settings</button>
