import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

import {ActivatedRoute, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {IMainCourse} from '../../interfaces/ICourseCollection';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {environment} from '../../../environments/environment';
import {XsLaravelDataTableComponent} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.component';
import Swal from 'sweetalert2';

@Component({
   selector: 'app-student-course-manager',
   templateUrl: './student-course-manager.component.html',
   styleUrls: ['./student-course-manager.component.scss']
})
export class StudentCourseManagerComponent implements OnInit {
   @ViewChild('addNew') addNew;
   @ViewChild('courseItems') courseItems;
   @ViewChild('actions') actionView;
   public courses: IMainCourse[] = [];
   public currentPage = 1;
   public totalItems: any;
   public opened = [];
   public breadCrumbs = [{name: 'Home', id: 0}];
   public currentCourseId = 0;
   public tree;
   public addCourseForm: UntypedFormGroup;
   public modalRef;
   public flatten = [];
   public addItemForm: UntypedFormGroup;
   public currentCourse;
   //
   public endpoint: string;
   public loading = false;
   public folders = [];
   public files = [];
   public currentId = 0;
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', width: 'min'},
         {name: 'Name', key: 'name'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
      ],
      actions: []
   };
   public dataTableRef: XsLaravelDataTableComponent;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public route: ActivatedRoute,
               public loader: NgxUiLoaderService,
               public modal: NgbModal) {

      this.addCourseForm = new UntypedFormGroup({
         id: new UntypedFormControl(''),
         course_name: new UntypedFormControl('', Validators.required),
         status: new UntypedFormControl('publish', Validators.required),
         parent: new UntypedFormControl('0', Validators.required),
         icon: new UntypedFormControl(),
      });
      this.addItemForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         type: new UntypedFormControl('folder'),
         icon: new UntypedFormControl(),
         student_course_id: new UntypedFormControl(),
         student_item_id: new UntypedFormControl(),
         status: new UntypedFormControl(),
         file: new UntypedFormControl(),
         programs: new UntypedFormControl(),
         available_to_all: new UntypedFormControl(1),
         sort_order: new UntypedFormControl()
      });
   }

   ngOnInit() {
      this.getTree();
   }

   getTree() {
      this.loader.start();
      this.api.get('admin/student-course-tree', {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.tree = res;
            this.flatten = [];
            this.flattenTree(this.tree);
         });
   }

   addCourse(course: IMainCourse = null, subCourse = false, parent = null) {
      if (parent) {
         this.opened = _.concat(this.opened, parent.id);
      }
      this.addCourseForm.reset({parent: 0});
      this.modalRef = this.modal.open(this.addNew);

   }

   deleteCourse(item) {
    //  console.log(item);
      Swal.fire({
         title: 'Confirm',
         text: 'Do you want to delete - ' + item.course_name + '?',
         showCancelButton: true,
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/student-courses/delete', {id: item.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.getTree();
               });
         }
      });

   }

   editCourse(item) {
      this.addCourseForm.patchValue(item);
      this.modalRef = this.modal.open(this.addNew);
   }

   manageItems(course: IMainCourse) {
      this.currentCourse = course;
      const modalRef = this.modal.open(this.courseItems, {size: 'lg', windowClass: 'big-modal'});
      this.endpoint = environment.endpoint + 'admin/student-item/list?parent=0&&student_course_id=' + this.currentCourse.id;
      this.currentId = null;

   }

   saveCourse() {
      this.loader.start();
      this.api.post('admin/student-courses/add', this.addCourseForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: IMainCourse) => {

            this.modalRef.close(res);
            this.getTree();

         });
   }

//add new course

   flattenTree(tree, append = '', firstIteration = true) {

      tree.forEach((item) => {
         this.flatten.push({label: append + item.course_name, value: item.id});
         if (item.sub_courses.length > 0) {
            this.flattenTree(item.sub_courses, '-' + append, false);
         }
      });
      return this.flatten;

   }

   statusChanged($event: any) {
      const item = $event.item;
      item.status = $event.status;
      this.api.post('admin/student-courses/add', item)
         .subscribe(res => {

         });
   }

   // manage items

   setRoot(id) {
      this.endpoint = environment.endpoint + 'admin/student-item/list?parent=' + id + '&&student_course_id=' + this.currentCourse.id;
      this.currentId = id;
      this.addItemForm.reset({type: this.addItemForm.value.type});
   }

   open(f: any, pushToBreadCrumb = true) {
      this.setRoot(f.id);
      if (pushToBreadCrumb) {
         this.breadCrumbs.push({
            name: f.name,
            id: f.id
         });
      }
   }

   breadcrumbClick(item, index) {
      this.breadCrumbs.splice(index + 1, 999);
      this.setRoot(item.id);
   }

   // course items
   getInstance($event: any) {
      this.dataTableRef = $event;
      this.dataTableRef.setActions(this.actionView);

   }

   editItem(item) {
      this.addItemForm.patchValue(item);
      if (item.type !== 'folder') {
         const selectedPrograms = [];

         item.programs.forEach((i: any) => {
            selectedPrograms.push(i.id);
         });
         this.addItemForm.patchValue({programs: selectedPrograms});
      }

   }

   deleteItem(item) {
    //  console.log(item);
      const message = item.type !== 'folder' ? 'Do you want to delete this item?' : 'Deleting this folder will also delete all the items contained in this folder.Do yo want to continue?';
      Swal.fire({
         title: 'Confirm',
         text: message,
         showCancelButton: true,
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/student-item/delete', {id: item.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.dataTableRef.loadData();
               });
         }
      });

   }

   saveFile() {
      this.addItemForm.patchValue({
         student_item_id: this.currentId ? this.currentId : 0,
         student_course_id: this.currentCourse.id,
      });

      this.loader.start();
      this.api.post('admin/student-items/save', this.addItemForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.dataTableRef.initTable();
            this.resetForm();
         });
   }

   resetForm() {
      this.addItemForm.reset({type: this.addItemForm.value.type, available_to_all: 1});
   }
}
