import {AfterViewChecked, AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Constants} from '../../Constants';
import {PhotoService} from '../../services/photo.service';
import {BaseComponent} from '../BaseComponent';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-interested-programs',
   templateUrl: './manage-interested-programs.html',
   styleUrls: ['./manage-interested-programs.scss']
})
export class ManageInterestedProgramsComponent extends BaseComponent {

   @ViewChild('addNew') addNewModal;
   @ViewChild('mpic') pic: TemplateRef<any>;
   @ViewChild('table') table;
   public editorOptions = Constants.editorOptions;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/interested-programs/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'name', type: 'text',},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);
            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/interested-programs/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe(() => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ],
      sortFn: (item) => {
         this.toastr.info('Updating..', null, {
            timeOut: 800
         });
         this.api.post('admin/interested-programs/save', item)
            .pipe(finalize(() => {
               this.loader.stop();
            }))
            .subscribe((res: any) => {
               this.toastr.success('Sort Order Updated', null, {
                  timeOut: 800
               });
            });
         return void (0);
      }
   };

   public form: UntypedFormGroup;
   public pageForm;
   public pageId = 15;

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public photoService: PhotoService,
      public loader: NgxUiLoaderService
   ) {
      super(toastr, api, auth, router, modal, loader);
      this.loadPage();

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         sort_order: new UntypedFormControl(),
      });

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({status: 'publish'});
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.form.patchValue({type: 'expert'});
      this.api.post('admin/interested-programs/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

}
