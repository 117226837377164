<div class="wrap">
    <ul>
        <ng-container [ngTemplateOutlet]="rowItem" [ngTemplateOutletContext]="{items: mainCourses}"></ng-container>
    </ul>
</div>

<ng-template #rowItem let-items="items">
    <li *ngFor="let item of items;let i=index">

        <ng-container *ngIf="item.sub_courses.length==0">
            <div class="custom-control custom-checkbox">
                <input (change)="select(item.id)" type="checkbox" class="custom-control-input" [id]="'c-'+item.id" [checked]="this.value.indexOf(item.id)>-1">
                <label class="custom-control-label" [for]="'c-'+item.id">{{item.course_name}}</label>
            </div>
        </ng-container>

        <ng-container *ngIf="item.sub_courses.length>0">
            <label class="font-weight-bold">{{item.course_name}}</label>
        </ng-container>


        <ul *ngIf="item.sub_courses.length>0">
            <ng-container [ngTemplateOutlet]="rowItem" [ngTemplateOutletContext]="{items: item.sub_courses}"></ng-container>
        </ul>
    </li>
</ng-template>
