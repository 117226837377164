<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Gallery</h3>
   <div>
      <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>

      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template lt-cell-template field="status" let-item>
      <span class="tbl-status" [ngClass]="'status-'+item.status">{{item.status}}</span>
   </ng-template>

   <ng-template field="title" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image class="mr-2" [src]="item.featured_data"></app-cdn-image>
         {{item.title}}
      </div>
   </ng-template>

   <ng-template lt-cell-template field="actions" let-item>
      <div class="spaced-btns">
         <button class="btn btn-primary btn-sm" (click)="edit(item)">Edit</button>
         <button class="btn btn-danger btn-sm" mwlConfirmationPopover>Delete</button>
      </div>
   </ng-template>

   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Gallery</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form"><input type="hidden" formControlName="id">

         <div class="row">

            <div class="col-sm-8">
               <div class="form-group">
                  <label for="">TITLE <span>*</span></label>
                  <input type="text" formControlName="title" class="form-control">
               </div>
               <div class="form-group">
                  <label for="">TYPE <span>*</span></label>
                  <select formControlName="type" class="form-control">
                     <option value="image">Image</option>
                     <option value="youtube-video">Youtube Video</option>
                     <option value="video-upload">Video Upload</option>
                  </select>
               </div>


               <div class="form-group" *ngIf="form.value.type=='youtube-video'">
                  <label class="d-block">VIDEO URL</label>
                  <input type="text" formControlName="video_url" class="form-control">
               </div>

               <div class="form-group" *ngIf="form.value.type=='video-upload'">
                  <label class="d-block">UPLOAD VIDEO</label>
                  <app-doc-uploader formats=".mp4" dir="gallery" formControlName="video_url"></app-doc-uploader>
               </div>


               <div class="row">
                  <div class="col-6">
                     <div class="form-group">
                        <label class="d-block" for="">STATUS</label>
                        <app-status formControlName="status"></app-status>
                     </div>
                  </div><!--add-->
                  <div class="col-6">
                     <div class="form-group">
                        <label for="">SORT ORDER</label>
                        <input type="text" appNumberOnly formControlName="sort_order" class="form-control">
                     </div>
                  </div>
               </div>

            </div>
            <div class="col-sm-4">
               <div class="form-group">
                  <label for="">FEATURED IMAGE</label>
                  <app-photo-uploader dir="gallery" width="any" height="any" formControlName="featured_data"></app-photo-uploader>
               </div>
            </div>


         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <app-bdrt formControlName="bdrt"></app-bdrt>
         <input type="hidden" formControlName="id" class="form-control">
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{pageForm.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
