import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IAdManagerConfig, ManageAdsComponent } from '../manage-ads/manage-ads.component';
import { ILaravelDataTableConfig } from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-exam-notification',
   templateUrl: './manage-exam-notification.component.html',
   styleUrls: ['./manage-exam-notification.component.scss']
})
export class ManageExamNotificationComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/exam-notification/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         { name: 'ID', key: 'id', type: 'text', width: 'min' },
         { name: 'Exam Name', key: 'exam_name' },
         {
            name: 'Course', key: 'course.course_name',
            type: 'select',
            filterKey: 'main_course_id',
            selectOptions: this.api.resourceEndExamFilter()
         },
         { name: 'Date Of Ad', key: 'exam_date' },
         { name: 'Last Date', key: 'last_date' },
         { name: 'Status', key: 'status', type: 'status', width: 'min' },
         { name: 'Sort', key: 'sort', type: 'sort', width: 'min' }
      ],
      actions: [
         {
            name: 'Advertisement',
            type: 'info',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(ManageAdsComponent);
               const config: IAdManagerConfig = {
                  adType: 'exam-notifications',
                  isModal: true,
                  parent: dataset[index].id,
                  title: dataset[index].exam_name

               };
               modalRef.componentInstance.setConfig(config);

            }
         },
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               const modalRef = this.modal.open(this.addNewModal);

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].exam_name + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/exam-notification/delete', { id: dataset[index].id })
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe(() => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;
   public courseTree = [];

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public loader: NgxUiLoaderService
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         main_course_id: new UntypedFormControl('', Validators.compose([])),
         exam_name: new UntypedFormControl('', Validators.compose([])),
         exam_date: new UntypedFormControl('', Validators.compose([])),
         exam_year: new UntypedFormControl('', Validators.compose([])),
         last_date: new UntypedFormControl('', Validators.compose([])),
         description: new UntypedFormControl('', Validators.compose([])),
         pdf: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         bdrt: new UntypedFormControl('', Validators.compose([])),
         pdf_link: new UntypedFormControl('', Validators.compose([])),
         // add
      });

   }

   ngOnInit() {
      this.loadTree();
   }

   loadTree() {
      this.api.post('admin/free-resource-course/get-tree', {}).subscribe((res: any) => this.courseTree = res);
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/exam-notification/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

}
