<div class="page-title d-flex justify-content-between align-items-center">
    <h3>Manage Previous Papers Stage</h3>
    <div>

    </div>
</div>

<div class="row">
    <div class="col-sm-8">
        <div class="alert alert-info" *ngIf="stageTree.length==0">Please select the exam to view its stages</div>
        <app-spinner *ngIf="loadingStages"></app-spinner>
        <app-previous-papers-stage-list
                (editEvt)="edit($event)"
                (deleteEvt)="delete($event)"
                [tree]="stageTree" *ngIf="!loadingStages || !stageTree"></app-previous-papers-stage-list>
    </div>
    <div class="col-sm-4">
        <div class="section" *ngIf="form.value.id>0">Update Item</div>
        <form [formGroup]="form">
            <input type="hidden" formControlName="id" class="form-control">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="" style="color: red">MAIN COURSE </label>
                        <app-main-category-selector (selected)="loadStages()" formControlName="main_course_id" [tree]="categoryTree"></app-main-category-selector>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="">PARENT STAGE</label>
                        <app-paper-stage-selector formControlName="parent" [tree]="stagesFlat" emptyLabel="No Parent"></app-paper-stage-selector>

                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="">{{form.value.parent > 0 ? 'CHILD ' : ''}}STAGE NAME</label>
                        <input type="text" formControlName="name" class="form-control">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="">SORT ORDER</label>
                        <input type="text" formControlName="sort_order" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="" class="d-block">STATUS</label>
                        <app-status formControlName="status"></app-status>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="d-flex">
                        <button class="btn  mr-3 btn-warning" (click)="cancelUpdate()" *ngIf="form.value.id">Cancel Update</button>
                        <button class="btn   btn-primary" (click)="save()">{{form.value.id ? 'Updated Stage' : 'Add New Stage'}}</button>
                    </div>


                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #addNew let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} PreviousPapersStage</h5>
        <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">


    </div>
    <div class="modal-footer">
        <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
    </div>
</ng-template>


