import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
   providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
   constructor(public auth: AuthService, public router: Router) {
   }

   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.auth.isUserAuthenticated()) {
         return true;
      }
      const options: NavigationExtras = {
         queryParams: {
            need_login: true
         }

      };
      this.router.navigate([''], options);

   }

}
