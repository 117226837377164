import {Component, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../services/api.service';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {NoticeCountService} from '../services/notice-count.service';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public noticeCountService: NoticeCountService,
               public loader: NgxUiLoaderService) {

   }

   logout() {
      this.loader.start();
      this.auth.logout();
      this.loader.stop();
      this.router.navigate(['']);
   }

   updatePassword() {
      this.router.navigate(['dashboard/update/password']).then();
   }
}
