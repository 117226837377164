import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Calendar} from 'primeng/calendar';

@Component({
   selector: 'app-prime-date-time-picker',
   templateUrl: './prime-date-time-picker.component.html',
   styleUrls: ['./prime-date-time-picker.component.scss'],
   providers: [{
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrimeDateTimePickerComponent),
   }, DatePipe]
})
export class PrimeDateTimePickerComponent implements ControlValueAccessor {
   @Input() format = 'dd/mm/yy';
   @Input() showTimes;
   public value;
   @ViewChild('p') picker: Calendar;
   public emit = (_any) => {};

   constructor(public datePipe: DatePipe) {
   }

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {
   }

   parseISOString(s) {
      const b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
   }

   writeValue(obj: any): void {
      if (obj) {
         this.value = this.parseISOString(obj);// this.datePipe.transform(obj, 'dd/MM/yyyy hh:mm aa', '530');

      } else {
         this.value = null;
      }
   }

   dateChanged() {
      this.emit(this.value);
   }
}
