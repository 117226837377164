<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Test Coupons</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="showAddCouponForm=true"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">

   <ng-template lt-cell-template let-item field="active">
      <span class="tbl-status" [ngClass]="{'status-active':item.active,'status-inactive':!item.active}">{{item.active ? 'ACTIVE' : 'INACTIVE'}}</span>
   </ng-template>
   <ng-template lt-cell-template let-item field="actions" let-idx="index">
      <button class="btn btn-sm btn-primary mr-1" (click)="edit(item,idx)">Edit</button>
      <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item,idx)">Delete</button>
   </ng-template>
   <ng-template lt-cell-template field="type" let-item>
      <span class="tbl-status" [ngClass]="'status-'+item.type">{{item.type}}</span>
   </ng-template>
   <ng-template field="active" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.active?'Yes':'No'"></app-badge>
   </ng-template>
   <ng-template field="code" let-idx="index" lt-cell-template="" let-item="">
      {{item.code}}
      <small class="d-block">{{item?.user?.email}}</small>
   </ng-template>

</xs-laravel-data-table>

<p-dialog [header]="(singleCoupon.value.id ? 'Update' : 'New')+ ' Test Coupon'" [(visible)]="showAddCouponForm" (onHide)="singleCoupon.reset()">
   <form [formGroup]="singleCoupon">
      <div class="form-group">
         <label for="">Type *</label>
         <p-selectButton formControlName="type" [options]="couponType"></p-selectButton>
      </div>
      <div class="row">
         <div class="col-6">
            <div class="form-group">
               <label for="">Coupon Code</label>
               <input type="text" [readOnly]="singleCoupon.value.id" formControlName="code" class="form-control">
               <small class="text-info">leave empty to generate automatically</small>
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label for="">Coupon Value *</label>
               <input type="text" class="form-control" formControlName="value" appNumberOnly>
            </div>
         </div>
      </div>
      <div class="row">

         <div class="col-6">
            <div class="form-group">
               <label for="">Valid From *</label>
               <app-prime-date-time-picker formControlName="valid_from"></app-prime-date-time-picker>
            </div>
         </div>
         <div class="col-6">
            <div class="form-group">
               <label for="">Valid To *</label>
               <app-prime-date-time-picker formControlName="valid_to"></app-prime-date-time-picker>
            </div>
         </div>
      </div>
      <div class="form-group">
         <label for="">User</label>
         <p-autoComplete appendTo="body"
                         dataKey="id"
                         field="email"
                         formControlName="user"
                         [forceSelection]="true"
                         [suggestions]="userResults"
                         (completeMethod)="search($event)">
            <ng-template let-user pTemplate="item">
               <div>{{user.email}}</div>
            </ng-template>

         </p-autoComplete>
      </div>
      <div class="row">
         <div class="col-sm-6">
            <div class="form-group">
               <label for="">Max Use</label>
               <input type="text" formControlName="max_use" appNumberOnly class="form-control">
            </div>
         </div>
         <div class="col-sm-6">
            <div class="form-group">
               <label for="">Active</label>
               <app-toggle-switch label1="Yes" label0="No" formControlName="active"></app-toggle-switch>
            </div>
         </div>
      </div>


   </form>
   <ng-template pTemplate="footer">
      <button pButton [disabled]="singleCoupon.invalid" icon="pi pi-check" [label]="singleCoupon.value.id? 'Update Coupon':'Add Coupon'" (click)="save()"></button>
   </ng-template>
</p-dialog>

