import {Component, forwardRef, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {finalize} from 'rxjs/operators';

@Component({
   selector: 'app-program-picker',
   templateUrl: './program-picker.component.html',
   styleUrls: ['./program-picker.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: forwardRef(() => ProgramPickerComponent)
      }
   ]
})
export class ProgramPickerComponent implements OnInit, ControlValueAccessor {
   public loading = false;
   public programs = [];
   public selected = [];
   public emit = (_res) => {
   };

   constructor(public api: ApiService) {
   }

   ngOnInit(): void {
      this.loading = true;
      this.api.post('admin/programme/program-picker', {})
         .pipe(finalize(() => this.loading = false))
         .subscribe((res: any) => {
            this.programs = res;
         });
   }

   itemClicked(item) {
      const index = this.selected.indexOf(item.id);
     // console.log(index);
      if (index > -1) {
         this.selected.splice(index, 1);
      } else {
         this.selected.push(item.id);
      }
      this.selected = [].concat(this.selected);
      this.emit(this.selected);
   }

   registerOnChange(fn: any): void {
      this.emit = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {
      this.selected = Array.isArray(obj) ? obj : [];
   }
}
