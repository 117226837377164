import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize} from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
   selector: 'app-manage-free-resource',
   templateUrl: './manage-free-resource.component.html',
   styleUrls: ['./manage-free-resource.component.scss']
})
export class ManageFreeResourceComponent implements OnInit {
   @ViewChild('addNew') addNew;
   public form: UntypedFormGroup;
   public tree;
   public shownPanels = [];
   private selectedItem: null;
   public modalRef;
   public updating = false;
   public pageForm: UntypedFormGroup;
   public studentPanelCourses = [];

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public loader: NgxUiLoaderService,
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         course_name: new UntypedFormControl(),
         sort: new UntypedFormControl(),
         status: new UntypedFormControl(),
         parent: new UntypedFormControl(''),
         bdrt: new UntypedFormControl(),
         visible_to: new UntypedFormControl(),
      });
   }

   ngOnInit() {
      this.getTree();
      this.studentCourseTree()
   }


   getTree() {
      this.api.post('admin/free-resource-course/get-tree', {}).subscribe((res: any) => {
         this.tree = res;
      });
   }

   drop(event, arr) {
      moveItemInArray(arr, event.previousIndex, event.currentIndex);
   }

   save() {

      this.updating = true;
      this.api.post('admin/free-resource-course/save', this.form.value)
         .pipe(finalize(() => {
            this.updating = false;
         }))
         .subscribe((res: any) => {
            this.modalRef.close();
            this.getTree();
         });

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modalRef = this.modal.open(addNew);
   }

   edit($event: any) {
      this.selectedItem = $event;
      setTimeout(() => {
         this.form.patchValue(this.selectedItem);
      });

      this.modalRef = this.modal.open(this.addNew);
   }

   delete($event: any) {
      //console.log($event);
      swal.fire({
         title: 'Delete :' + $event.course_name + '?',
         icon: 'question',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/free-resource-course/delete', {id: $event.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.getTree();
               });
         }
      });
   }

   statusUpdate($event: any) {
      this.loader.start();
      this.api.post('admin/free-resource-course/save', {id: $event.item.id, status: $event.status})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            $event.item.status = $event.status;
         });
   }


   private studentCourseTree() {
      this.api.get('admin/student-course-tree', {})
         .subscribe((res: any) => {
            this.studentPanelCourses = res;
         })
   }
}
