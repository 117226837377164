<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage AboutExam</h3>
   <div>
      <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig"></xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} AboutExam</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id">

         <div class="row">
            <div class="col-12 mb-5">
               <div class="section">BDRT</div>
               <app-bdrt hideContent="true" formControlName="bdrt"></app-bdrt>

            </div>
            <div class="col-6">
               <div class="form-group">
                  <label for="">END EXAM</label>
                  <app-main-category-selector [tree]="courseTree" formControlName="main_course_id"></app-main-category-selector>

               </div>
            </div>

            <div class="col-12">
               <div class="form-group">
                  <label for="">CONTENT</label>
                  <app-editor formControlName="content"></app-editor>
               </div>
            </div>


         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>


<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <app-bdrt formControlName="bdrt"></app-bdrt>
         <input type="hidden" formControlName="id" class="form-control">
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{pageForm.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
