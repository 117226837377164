<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Allot Tests <span class="text-success" *ngIf="program">- {{program.name}}</span></h3>
   <div>
      <div class="spaced-btns">
         <button class="btn btn-info" (click)="testPickerModal=true"> Add Existing</button>
         <button class="btn btn-primary" (click)="add(addNew)"> Add New</button>
      </div>
   </div>
</div>

<p-sidebar [(visible)]="testPickerModal" position="right" [style]="{width:'30em'}">
   <h4 class="border-bottom pb-3 mb-3">Test Picker</h4>

   <p-autoComplete multiple="true" [(ngModel)]="selectedTests" [suggestions]="tests" (completeMethod)="searchTest($event)">
      <ng-template let-test pTemplate="item">
         {{test.name}}
      </ng-template>
      <ng-template let-test pTemplate="selectedItem">
         <span style="font-size:18px">{{test.name}}</span>
      </ng-template>

   </p-autoComplete>

   <button class="btn btn-block mt-3 btn-success" [disabled]="selectedTests.length===0" (click)="addTestToTestSeries()">Add Tests</button>
</p-sidebar>

<xs-laravel-data-table *ngIf="endpoint" #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status">{{item.status}}</app-badge>
   </ng-template>
   <ng-template lt-cell-template field="negative_marking" let-item>
      <span class="tbl-status" [ngClass]="'status-'+item.negative_marking">{{item.negative_marking ? 'YES' : 'NO'}}</span>
   </ng-template>
   <ng-template lt-cell-template field="live_test" let-item>
      <span class="tbl-status" [ngClass]="'status-'+item.live_test">{{item.live_test ? 'YES' : 'NO'}}</span>
   </ng-template>
   <ng-template lt-cell-template field="actions" let-item let-index="index">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="menu.toggle($event);selectedTest=item">Menu</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover
                 popoverMessage="This will delete the test from everywhere.Please use remove option in menu to remove it from just this test series." (confirm)="delete(item,index)">Delete
         </button>
         <button class="btn btn-sm btn-warning" mwlConfirmationPopover (confirm)="duplicate(item,index)">Duplicate</button>
      </div>
      <p-menu [model]="items" #menu [popup]="true" appendTo="body"></p-menu>
   </ng-template>
   <ng-template field="sort_by" let-idx="index" lt-cell-template="" let-item="">
      <input (blur)="updateSort(item,$event)" [value]="item.sort_by" class="input form-control-sm form-control"/>
   </ng-template>
</xs-laravel-data-table>


<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Test</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <div class="form-group">
            <label>Name</label>
            <input type="email" class="form-control" formControlName="name">
         </div>
         <div class="form-group">
            <label>Sub Heading</label>
            <input class="form-control" type="text" formControlName="sub_heading"/>
         </div>

         <div class="form-group">
            <label>Number Of Questions</label>
            <input type="email" class="form-control" formControlName="no_of_questions">
         </div>
         <div class="form-group">
            <label>Syllabus</label>
            <app-editor formControlName="syllabus"></app-editor>
         </div>

         <div class="row">
            <div class=" col-4 form-group">
               <label>PDF Download</label>
               <app-toggle-switch formControlName="pdf_download"></app-toggle-switch>
            </div>
            <div class=" col-4 form-group">
               <label>Can Be Bought Separately</label>
               <app-toggle-switch formControlName="buy_enabled"></app-toggle-switch>
            </div>
         </div>
         <ng-container *ngIf="this.form.value.buy_enabled">
            <div class="section">Pricing</div>

            <div class="row mb-3">
               <div class="col-sm-3">
                  <label>Tax Rate (%)</label>
                  <input (change)="calculateTax()" appNumberOnly type="text" class="form-control" formControlName="tax_rate">
               </div>
               <div class="col-sm-3">
                  <label>Price</label>
                  <input (change)="calculateTax()" appNumberOnly type="text" class="form-control" formControlName="price">
               </div>
               <div class="col-sm-3">
                  <label>Discounted Price</label>
                  <input (change)="calculateTax()" appNumberOnly type="text" class="form-control" formControlName="sale_price">
               </div>
               <div class="col-sm-3">
                  <div class="alert alert-info">
                     Actual Price : {{priceWithoutTax}} <br>
                     GST : {{(priceWithTax - priceWithoutTax).toFixed(2)}}
                  </div>
               </div>
            </div>

         </ng-container>

         <div class="section">Marking</div>
         <app-marking-control formControlName="marking"></app-marking-control>

         <div class="section">Sections</div>
         <app-marking-control formControlName="sections"></app-marking-control>

         <div class="section">Negative Marking</div>
         <div class="form-group">
            <label class="d-block">Enable Negative Marking</label>
            <app-toggle-switch formControlName="negative_marking"></app-toggle-switch>
         </div>
         <div class="form-group" *ngIf="form.value.negative_marking">
            <label class="d-block">Negative Marking</label>
            <input class="form-control" formControlName="negative_marking_value"/>
         </div>

         <div class="section">Answer Settings</div>
         <div class="row">
            <div class="col-6">
               <div class="form-group">
                  <label>Display Answers</label>
                  <select class="form-control custom-select" formControlName="answers">
                     <option value="1">Immediate</option>
                     <option value="2">After Test Completion</option>
                     <option value="3">On Specific Date</option>
                  </select>
               </div>
            </div>
            <div class="col-6">
               <app-date-picker *ngIf="form.value.answers==3" [timepicker]="true" formControlName="answer_display_date" label="Date to display answers"></app-date-picker>
            </div>
         </div>
         <div class="form-group">
            <label>Answer Key Download </label>
            <app-toggle-switch formControlName="answer_pdf_download"></app-toggle-switch>
         </div>

         <lib-checkbox-group *ngIf="form.value.answer_pdf_download" formControlName="answer_pdf_config" label="Answer key Content" [items]="answerSheetConfigOptions"></lib-checkbox-group>
         <div class="section">Time Settings</div>

         <div class="row mb-4">
            <div class="col-sm-6">
               <label for="">Start Date</label>
               <app-prime-date-time-picker formControlName="start_date" showTimes="true"></app-prime-date-time-picker>

            </div>
            <div class="col-sm-6"><label for="">End Date</label>
               <app-prime-date-time-picker formControlName="end_date" showTimes="true"></app-prime-date-time-picker>

            </div>
         </div>

         <div class="form-group">
            <label>Live Test</label>
            <app-toggle-switch formControlName="live_test"></app-toggle-switch>
         </div>

         <ng-container *ngIf="!this.form.value.live_test">
            <div class="form-group mt-3">
               <label>Total Minutes</label>
               <input type="text" appNumberOnly class="form-control" formControlName="duration">
            </div>
            <div class="form-group">
               <label>No Of Attempts</label>
               <input type="text" appNumberOnly class="form-control" formControlName="attempts"/>
            </div>
         </ng-container>
         <div class="form-group">
            <label>Can View Complete Question Paper </label>
            <app-toggle-switch label0="No" label1="Yes" formControlName="can_view_question_paper"></app-toggle-switch>
         </div>
         <div class="form-group">
            <label>Can Download Question Paper PDF</label>
            <app-toggle-switch label0="No" label1="Yes" formControlName="download_pdf"></app-toggle-switch>
         </div>
         <div class="form-group">
            <label>Lock Answer In First Attempt </label>
            <app-toggle-switch formControlName="lock_answer"></app-toggle-switch>
         </div>
         <div class="form-group">
            <label>Switch Language</label>
            <app-toggle-switch formControlName="switch_language" label1="Enabled" label0="Disabled"></app-toggle-switch>
         </div>

         <div class="form-group">
            <label>Sort</label>
            <input type="email" appNumberOnly class="form-control" formControlName="sort">
         </div>


         <div class="form-group">
            <label class="d-block">Status</label>
            <app-status [statusList]="['draft','publish','future','expired']" formControlName="status"></app-status>
         </div>

         <div class="section">Question & Answer PDFs</div>
         <div class="form-group">
            <label for="">Question PDF</label>
            <app-doc-uploader directory="test-papers" endpoint="admin/upload-doc" formControlName="q_pdf"></app-doc-uploader>
         </div>
         <div class="form-group">
            <label for="">Answer PDF</label>
            <app-doc-uploader directory="test-papers-answers" endpoint="admin/upload-doc" formControlName="a_pdf"></app-doc-uploader>
         </div>
      </form>

   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <!--         <app-bdrt formControlName="bdrt"></app-bdrt>-->
         <input type="hidden" formControlName="id" class="form-control">


      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{pageForm.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
<ng-template #importPapers let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="importForm">
         <input type="hidden" formControlName="id">
         <app-doc-uploader formControlName="paper_english" label="Upload English Paper" formats=".zip" directory="zip" endpoint="/api/admin/zip-upload"></app-doc-uploader>
         <app-doc-uploader formControlName="paper_punjabi" label="Upload Punjabi Paper" formats=".zip" directory="zip" endpoint="/api/admin/zip-upload"></app-doc-uploader>
         <app-doc-uploader formControlName="paper_hindi" label="Upload Hindi Paper" formats=".zip" directory="zip" endpoint="/api/admin/zip-upload"></app-doc-uploader>


      </form>
   </div>
   <div class="modal-footer">
      <button (click)="importPapersClick()" class="btn btn-primary">Import Papers</button>
   </div>
</ng-template>
<ng-template #allotTestModal let-modal>
   <div class="modal-header">
      <h5 class="modal-title">Allot Test</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="input-group ">

      <input autocomplete="false" #suser type="text" class="p-4 form-control" placeholder="Email,phone or user id">
      <div class="input-group-append">
         <button class="btn btn-primary" (click)="allotTestClick(suser.value)">Allot Test To Users</button>
      </div>

   </div>
   <div *ngIf="allottedUsersLoading" class="alert-success alert text-center">Loading...</div>
   <div class="modal-body p-0">

      <div *ngIf="!allottedUsersLoading && usersAllottedToTest.length==0" class="text-center" style="padding: 100px;">No Users</div>
      <ul class="list-unstyled mb-0">
         <li class="p-3 d-flex align-items-center border-bottom" *ngFor="let u of usersAllottedToTest;let idx=index">
            <div class="flex-grow-1">
               <div class="font-weight-bold">{{u.user.name}}</div>
               <small style="margin-top: -3px;" class="d-block">{{u.user.email}} {{u.user.phone_no}}</small>
            </div>
            <a ngbTooltip="Remove" (click)="removeUserFromTest(u,idx)" class="mr-4"><i class="fa text-danger fa-times-circle"></i></a>
         </li>
      </ul>
   </div>
   <div class="modal-footer d-none">
      <button (click)="saveBdrt()" class="btn btn-primary">{{pageForm.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

<p-dialog [header]="selectedTest?.name" [(visible)]="displayQuestionSidebar">

   <p-header>
      <a *ngFor="let l of languages" class="btn text-uppercase"
         [ngClass]="{'btn-success text-white': selectedLanguage==l }"
         (click)="loadQuestions(l.toLocaleLowerCase())">{{l}}</a>
   </p-header>

   <div class="d-flex flex-column h-100">
      <div>

      </div>
      <div class="flex-auto">
         <div style="overflow: auto" class="h-100">
            <ng-container *ngIf="loadingQuestions">
               <app-spinner></app-spinner>
            </ng-container>
            <lib-question-paper-display *ngIf="selectedTest && !loadingQuestions" [test]="selectedTest"></lib-question-paper-display>
         </div>
      </div>
   </div>


</p-dialog>



