import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ILaravelDataTableConfig } from 'projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import { XsLaravelDataTableComponent } from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.component';

@Component({
   selector: 'app-manage-previous-paper',
   templateUrl: './manage-previous-paper.component.html',
   styleUrls: ['./manage-previous-paper.component.scss']
})
export class ManagePreviousPaperComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   @ViewChild('modalDataTable') modalDataTable: XsLaravelDataTableComponent;
   public modalRef: NgbModalRef;
   public categoryTree = [];
   public stageTree = [];
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/previous-paper/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         { name: 'ID', key: 'id', type: 'text', width: 'min' },
         {
            name: 'Course',
            key: 'course.course_name',
            type: 'select',
            filterKey: 'main_course_id',
            selectOptions: this.api.resourceEndExamFilter()

         },
         { name: 'Stage', key: 'stage.name' },
         {
            name: 'PDF', key: 'pdf', width: 'min',
            render: (val) => {
               if (typeof val === 'string') {
                  return ` <a target="_blank"   class="btn d-block btn-w-100 btn-sm btn-secondary" href="${this.api.getFileUrl(val)}">View File</a>`;
               }
            }
         },
         { name: 'Exam Year', key: 'exam_year', width: 'min' },
         { name: 'Status', key: 'status', type: 'status', width: 'min' },
         { name: 'Sort', key: 'sort', type: 'sort', width: 'min' },

      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               if (this.selectedItem.stage) {
                  this.form.patchValue({ stage_id: this.selectedItem.stage.id });
               }
               this.openAddNewForm();

            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/previous-paper/delete', { id: dataset[index].id })
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe((res: any) => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   //modal
   public endpoint2 = environment.endpoint + 'admin/previous-paper/list';

   public form: UntypedFormGroup;
   addNewOpen = false;

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public loader: NgxUiLoaderService
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         main_course_id: new UntypedFormControl('', Validators.compose([])),
         previous_papers_stage_id: new UntypedFormControl('', Validators.compose([])),
         exam_year: new UntypedFormControl('', Validators.compose([])),
         pdf: new UntypedFormControl('', Validators.compose([])),
         sort_order: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),

      });
      this.getTree();

   }

   getTree() {
      this.api.post('admin/free-resource-course/get-tree', {})
         .subscribe((res: any) => this.categoryTree = res);
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modalRef = this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/previous-paper/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {

            if (this.form.value.id > 0) {
               this.closeAddNewForm();
               this.table.loadData();
               return;
            }
            this.form.patchValue({
               exam_year: null,
               pdf: null,
               sort_order: null,
               status: 'publish',
               id: null
            });

            this.table.pushItem(res.data);
            // console.log(this.modalDataTable);
            // this.modalDataTable.pushItem(res.data);
            // this.modal.dismissAll();
            //this.table.loadData();
         });
   }

   courseSelected() {
      this.loader.start();
      const selectedCourseId = this.form.value.main_course_id;
      this.api.paperStageFilter(selectedCourseId)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.stageTree = res;
         });

      //set the filter for listing items in modal
      if (!this.form.value.id) {
         this.endpoint2 = this.endpoint + '?main_course_id=' + selectedCourseId;
         this.table.loadData();
      }

   }

   openAddNewForm() {
      this.addNewOpen = true;
      if (this.form.value.id) {
         this.courseSelected();
      }
   }

   closeAddNewForm() {
      this.addNewOpen = false;
      this.form.reset();
      this.endpoint2 = this.endpoint;
   }
}
