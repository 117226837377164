<div class="grid-container">

   <div class="menu d-flex flex-column">
      <div style="background-color: #fff;padding: 10px 0;">
         <img class="img-fluid" src="assets/logo.jpg" alt=""></div>

      <div class="menu-inner flex-grow-1">
         <ul>
            <li *appPermissionCheck="'manage_paid_material'"><a routerLinkActive="active" [routerLink]="['manage/paid-material']"><i class="fas  fa-book"></i>Manage Paid Materials</a></li>
            <li *appPermissionCheck="'manage_notices'"><a routerLinkActive="active" [routerLink]="['manage/notices']"><i class="fas  fa-book"></i>Manage Notices Orders</a></li>
            <li *appPermissionCheck="'manage_enrollments_orders'"><a routerLinkActive="active" [routerLink]="['manage/order']"><i class="fas  fa-book"></i>Enrollment Orders</a></li>
            <li *appPermissionCheck="'manage_test_series_orders'"><a routerLinkActive="active" [routerLink]="['manage/test-orders']"><i class="fa fa-shopping-bag"></i>Test Orders
               <app-notice-count type="testOrder"></app-notice-count>
            </a></li>
            <li *appPermissionCheck="'manage_book_orders'"><a routerLinkActive="active" [routerLink]="['manage','book-orders']"><i class="fas fa-cart-plus"></i>Book Orders</a></li>
         </ul>

         <ul>
            <li *appPermissionCheck="'manage_coupons'"><a routerLinkActive="active" [routerLink]="['manage/coupon']"><i class="fas  fa-book"></i> Books/Enrollment Coupons</a></li>
            <li *appPermissionCheck="'manage_test_series_coupons'"><a routerLinkActive="active" [routerLink]="['manage/test-coupons']"><i class="fa fa-tag"></i>Test Series Coupons</a></li>
         </ul>

         <ul>
            <li><a [routerLink]="['manage/contact-forms']"><i class="fa fa-phone"></i>Student Enquires</a></li>
            <ng-container *appPermissionCheck="'manage_users'">
               <li><a routerLinkActive="active" [routerLink]="['manage','users']"><i class="fa fa-user"></i>User Manager</a></li>
               <li><a routerLinkActive="active" [routerLink]="['manage/roles-and-permissions']"><i class="fa fa-server"></i>Roles & Permissions</a></li>
            </ng-container>
         </ul>


         <ul>
            <li><a *appPermissionCheck="'manage_home_page_settings'" routerLinkActive="active" [routerLink]="['manage','homepage']"><i class="fa fa-home"></i> Home Page Settings</a></li>
            <li *appPermissionCheck="'manage_top_rankers'"><a routerLinkActive="active" [routerLink]="['top-ranker']"><i class="fa fa-child"></i>Our Selections/Result</a></li>
            <li *appPermissionCheck="'manage_testimonials'"><a routerLinkActive="active" [routerLink]="['manage/testimonials']"><i class="fa  fa-quote-left"></i>Our Testimonials</a></li>

            <li class="d-none" *appPermissionCheck="'manage_notifications'"><a routerLinkActive="active" [routerLink]="['manage/notifications']"><i class="fa fa-bell"></i> Notifications</a></li>

            <li *appPermissionCheck="'manage_quick_links'"><a [routerLink]="['manage','quick-links']"><i class="fa fa-link"></i>Quick Links</a></li>
            <li *appPermissionCheck="'manage_gallery'"><a routerLinkActive="active" [routerLink]="['manage/gallery']"><i class="fas  fa-book"></i> Gallery</a></li>

         </ul>

         <ul>
            <li *appPermissionCheck="'manage_who_we_are'"><a routerLinkActive="active" [routerLink]="['manage','who-we-are']"><i class="fa  fa-user"></i>Who we are</a></li>
         </ul>

         <ul>
            <li *appPermissionCheck="'manage_books'"><a routerLinkActive="active" [routerLink]="['manage/books']"><i class="fa  fa-paperclip"></i>Our Books</a></li>
            <li *appPermissionCheck="'manage_book_reviews'"><a routerLinkActive="active" [routerLink]="['manage/comments/book']"><i class="fa fa-comments"></i>Book Reviews</a></li>
            <li *appPermissionCheck="'manage_book_coupons'"><a routerLinkActive="active" [routerLink]="['manage/book-coupons']"><i class="fa  fa-book"></i>Generate Book Coupons</a></li>
            <li *appPermissionCheck="'manage_book_coupons'"><a routerLinkActive="active" [routerLink]="['manage/book-coupon-batches']"><i class="fa  fa-book"></i>Book Coupon Batches</a></li>
            <li *appPermissionCheck="'manage_book_coupons'"><a routerLinkActive="active" [routerLink]="['manage/book-coupon-users']"><i class="fa  fa-book"></i>Book Coupon Users</a></li>
         </ul>


         <ul>
            <li *appPermissionCheck="'can_add_enquiry'"><a [routerLink]="['entry','enquiry-form']">Add Equiry Form</a></li>
            <li *appPermissionCheck="'manage_interested_programs'"><a [routerLink]="['manage/interested-programs']">Interested Program</a></li>
         </ul>
         <ul>
            <li *appPermissionCheck="'manage_test_series'"><a routerLinkActive="active" [routerLink]="['manage','group-test-series']" [queryParams]="{type:'test',cat:'group'}"><i
               class="fa fa-flask"></i> Group Of Tests</a></li>
            <li *appPermissionCheck="'manage_test_series'"><a routerLinkActive="active" [routerLink]="['manage','individual-test-series']" [queryParams]="{type:'test',cat:'single'}"><i
               class="fa fa-flask"></i> Individual
               Test</a></li>
            <li *appPermissionCheck="'manage_question_reports'"><a routerLinkActive="active" [routerLink]="['manage/question-reports']"><i class="fa fa-file"></i>Question Reports</a></li>


         </ul>
         <ul class="m-0">
            <li *appPermissionCheck="'manage_courses'"><a routerLinkActive="active" [routerLink]="['manage','courses']"><i class="fas fa-graduation-cap"></i>Course Manager</a></li>
            <li *appPermissionCheck="'manage_programs'"><a routerLinkActive="active" [routerLink]="['manage/programs']"><i class="fa fa-book"></i>Programmes</a></li>
            <li *appPermissionCheck="'manage_program_key_points'"><a routerLinkActive="active" [routerLink]="['manage','programme-key-points' ]"><i class="fa fa-book "></i>Programme
               Key Points</a></li>

         </ul>


         <ul>

            <li *appPermissionCheck="'manage_who_we_are'"><a routerLinkActive="active" [routerLink]="['manage','page','cat','8']"><i class="fa  fa-user"></i> Who we are </a></li>
            <li *appPermissionCheck="'manage_teachers'"><a routerLinkActive="active" [routerLink]="['manage/teacher']"><i class="fa   fa-users"></i> Teachers</a></li>
            <li *appPermissionCheck="'manage_experts'"><a routerLinkActive="active" [routerLink]="['manage/experts']"><i class="fa   fa-users"></i> Experts & Leaders</a></li>

         </ul>
         <ul class="mt-1">
            <li *appPermissionCheck="'manage_courses'"><a routerLinkActive="active" [routerLink]="['manage','free-resource-courses']"><i class="fas fa-graduation-cap"></i>Course Manager2</a></li>
            <li *appPermissionCheck="'manage_about_exams'"><a routerLinkActive="active" [routerLink]="['manage','about-exam']"><i class="fa fa-book "></i> About Exams</a></li>
            <li *appPermissionCheck="'manage_exam_analysis'"><a routerLinkActive="active" [routerLink]="['manage','exam-analysis']"><i class="fa fa-book "></i>Exam Analysis Exams</a></li>
            <li *appPermissionCheck="'manage_previous_papers'"><a routerLinkActive="active" [routerLink]="['manage','previous-papers']"><i class="fa fa-book "></i>Previous Papers</a></li>

            <li *appPermissionCheck="'manage_exam_notifications'"><a routerLinkActive="active" [routerLink]="['manage','exam-notifications']"><i class="fa fa-book "></i>Exam Notifications</a></li>
            <li *appPermissionCheck="'manage_syllabus'"><a routerLinkActive="active" [routerLink]="['manage','syllabus']"><i class="fa fa-book "></i>Syllabus</a></li>
         </ul>
         <ul>
            <li *appPermissionCheck="'manage_current_affair_categories'"><a routerLinkActive="active" [routerLink]="['manage/current-affairs/categories']"><i class="fas  fa-book"></i>Cur. Affair
               Category</a></li>
            <li *appPermissionCheck="'manage_current_affairs'"><a routerLinkActive="active" [routerLink]="['manage/current-affairs/list']"><i class="fas  fa-book"></i>Current Affair</a></li>
            <!--            <li *appPermissionCheck="'manage_current_affair_comments'"><a routerLinkActive="active" [routerLink]="['manage/comments/current-affair']"><i class="fas  fa-book"></i>Comments</a></li>-->
         </ul>
         <ul>
            <li *appPermissionCheck="'manage_contact_us'"><a routerLinkActive="active" [routerLink]="['manage/contact-page']"><i class="fa fa-globe"></i>Contact Us</a></li>
            <li *appPermissionCheck="'manage_enquiry_concerns'"><a routerLinkActive="active" [routerLink]="['manage/enquiry-concerns']"><i class="far fa-envelope"></i>Enquiry Concerns
               <app-notice-count type="concern"></app-notice-count>
            </a></li>
            <li *appPermissionCheck="'manage_enquiry_callbacks'"><a routerLinkActive="active" [routerLink]="['manage/enquiry-callbacks']"><i class="fa  fa-phone"></i>Enquiry Callbacks
               <app-notice-count type="callback"></app-notice-count>
            </a></li>
         </ul>

         <ul>

            <li *appPermissionCheck="'manage_general_settings'"><a routerLinkActive="active" [routerLink]="['manage/settings']"><i class="fas  fa-book"></i>General Settings</a></li>
            <li *appPermissionCheck="'manage_faq'"><a routerLinkActive="active" [routerLink]="['manage/faq']"><i class="fa  fa-question"></i> FAQ</a></li>
            <li *appPermissionCheck="'manage_pages'"><a routerLinkActive="active" [routerLink]="['manage/page']"><i class="fas  fa-book"></i> Page</a></li>

         </ul>


         <ng-container *ngIf="auth.user.role =='student-admin' || auth.user.role =='admin'">

            <ul>

               <li>
                  <a [routerLink]="['manage','student-courses',0]"><i class="fa fa-user"></i>Course Manager</a>
               </li>
               <li>
                  <a (click)="logout()"><i class="fa fa-user"></i> Logout</a>
               </li>

            </ul>


         </ng-container>
         <ul>
            <li><a (click)="updatePassword()">Update Password</a></li>
            <li><a (click)="logout()">Logout</a></li>
         </ul>
      </div>
   </div>
   <div class="content">
      <div class="p-3  h-100">
         <div class="position-relative h-100">
            <router-outlet></router-outlet>
         </div>
      </div>
   </div>
</div>


