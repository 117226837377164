<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Notification</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="title" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.featured_image" class="mr-2"></app-cdn-image>
         {{item.title}}
      </div>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Notification</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id" class="form-control">

         <div class="row">
            <div class="col-sm-12">

               <div class="row">
                  <div class="col-sm-12">
                     <app-bdrt formControlName="bdrt"></app-bdrt>
                  </div>
                  <div class="col-6">
                     <div class="form-group">
                        <label>TYPE</label>
                        <select formControlName="type" class="form-control">
                           <option value="running">Running</option>
                           <option value="we-in-news">We in news</option>
                           <option value="exams-in-news">Exams in news</option>
                        </select>
                        <div ngxErrors="type">
                           <div class="errors" ngxError="required" when="touched">Type is required</div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12">
                     <label for="" class="d-block">Image</label>
                     <app-photo-uploader formControlName="featured_image" dir="notification"></app-photo-uploader>
                  </div>
                  <div class="col-12">
                     <div class="form-group">
                        <label>MAIN HEADING</label>
                        <input type="text" formControlName="title" class="form-control">
                        <div ngxErrors="title">
                           <div class="errors" ngxError="required" when="touched">Title is required</div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12" *ngIf="form.value.type!='running'">
                     <div class="form-group">
                        <label>SUB HEADING</label>
                        <input type="text" formControlName="sub_title" class="form-control">

                     </div>
                  </div>
                  <div class="col-12">
                     <div class="form-group">
                        <label>URL</label>
                        <input type="text" formControlName="url" class="form-control">
                     </div>
                  </div>
               </div>
            </div>

         </div>
         <div class="row">
            <div class="col-12">
               <div class="form-group" *ngIf="form.value.type!='running'">
                  <label>CONTENT</label>
                  <app-editor formControlName="content"></app-editor>
               </div>
            </div>
            <div class="col-6">
               <div class="form-group">
                  <label class="d-block">STATUS</label>
                  <app-status formControlName="status"></app-status>
               </div>
            </div>
            <div class="col-6">
               <div class="form-group">
                  <label class="d-block">SORT ORDER</label>
                  <input appNumberOnly class="form-control" formControlName="sort_order">
               </div>
            </div>


         </div>


      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
