<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Page</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">

   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>

   <ng-template field="title" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image class="mr-2" [src]="item.bdrt.banner"></app-cdn-image>
         {{item.title}}
      </div>
   </ng-template>

</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Page</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <input type="hidden" formControlName="id">
         <app-bdrt formControlName="bdrt"></app-bdrt>

         <div class="form-group">
            <label>CONTENT</label>
            <app-editor formControlName="desc"></app-editor>
         </div>

         <div formGroupName="meta" *ngIf="selectedItem">
            <ng-container *ngFor="let field of selectedItem.fields">
               <div class="form-group">
                  <label for="" class="d-block">{{field.label}}</label>
                  <ng-container [ngSwitch]="field.type">
                     <ng-container *ngSwitchCase="'photo'">
                        <app-photo-uploader dir="pages" [formControlName]="field.key"></app-photo-uploader>
                     </ng-container>

                     <ng-container *ngSwitchCase="'rich-text'">
                        <app-editor [formControlName]="field.key"></app-editor>
                     </ng-container>
                  </ng-container>
               </div>
            </ng-container>
         </div>

         <div class="row">

            <div class="col-sm-6">
               <label class="d-block">Status</label>
               <app-status formControlName="status"></app-status>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="">Sort Order</label>
                  <input appNumberOnly type="text" class="form-control" formControlName="sort_order">
               </div>
            </div>
         </div>

      </form>
   </div>
   <div class="modal-footer justify-content-between">

      <div class="">

         <div class="custom-control custom-checkbox" *ngIf="!form.value.id">
            <input [(ngModel)]="close" type="checkbox" class="custom-control-input" id="customCheck" name="example1">
            <label class="custom-control-label" for="customCheck">Dont Close</label>
         </div>
      </div>

      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>

