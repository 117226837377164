<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Testimonials</h3>
   <div>
      <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>

   <ng-template field="title" let-idx="index" lt-cell-template="" let-item="">

      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.featured_image" class="mr-2"></app-cdn-image>
         {{item.title}}
      </div>

   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <div class="spaced-btns">
         <button class="btn btn-sm btn-primary" (click)="edit(item)">Edit</button>
         <button class="btn btn-sm btn-danger" mwlConfirmationPopover (confirm)="delete(item)">Delete</button>
      </div>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}}Testimonial</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <div class="row">
            <div class="col-sm-8">
               <div class="form-group">
                  <label for="">Name</label>
                  <input type="text" class="form-control" formControlName="title">
               </div>

               <div class="form-group">
                  <label for="">Rank</label>
                  <input type="text" class="form-control" formControlName="author_name">
               </div>


            </div>
            <div class="col-sm-4">
               <app-photo-uploader formControlName="featured_image"></app-photo-uploader>
            </div>
         </div>
         <div class="form-group">
            <label for="">Testimonial</label>
            <app-editor formControlName="content"></app-editor>
         </div>
         <div class="row">
            <div class="col-sm-6">
               <label for="" class="d-block">Status</label>
               <app-status formControlName="status"></app-status>
            </div>
            <div class="col-sm-6">
               <label for="" class="d-block">Sort Order</label>
               <input class="form-control" appNumberOnly formControlName="sort_order"/>
            </div>
         </div>

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <app-bdrt formControlName="bdrt"></app-bdrt>
         <input type="hidden" formControlName="id" class="form-control">
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
