import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {environment} from '../../../environments/environment';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Constants} from '../../Constants';
import swal from 'sweetalert2';
import {IAdManagerConfig, ManageAdsComponent} from '../manage-ads/manage-ads.component';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import Swal from 'sweetalert2';
import {XsLaravelDataTableComponent} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.component';
import * as lo from 'lodash';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import {DropdownSettings} from 'angular2-multiselect-dropdown/lib/multiselect.interface';
import {of} from 'rxjs';

@Component({
   selector: 'app-manage-programmes',
   templateUrl: './manage-programmes.component.html',
   styleUrls: ['./manage-programmes.component.scss']
})
export class ManageProgrammesComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table: XsLaravelDataTableComponent;

   public endpoint: any;
   public form: UntypedFormGroup;
   public selectedItem;
   public enableTaxChange = false;
   public taxes: any = {
      admissionTax: 0,
      admissionFee: 0,
      tutionTax: 0,
      tutionFee: 0,
      net: 0
   };

   public courseCategory = Constants.courseCategory;
   public courses = [];
   public modalRef: NgbModalRef;

   public config: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', type: 'text', key: 'id', width: 'min'},
         {name: 'Name', type: 'text', key: 'name'},
         {name: 'Batch', type: 'text', key: 'batch_code',},
         {
            name: 'End Course',
            type: 'select',
            sortable: false,
            selectOptions: this.api.get('admin/main-course-filter', {}),
            filterKey: 'courses.id:main_courses',
            key: 'courses',
            render: (v) => {
               const items = [];
               lo.forEach(v, i => items.push(i.course_name));
               return items.join(','); //
            }
         },
         {name: 'Created', key: 'created_at', type: 'dateIST'},
         {name: 'Available', type: 'boolean', key: 'available', width: 'min', filterKey: 'available'},
         {
            name: 'Status', key: 'status', type: 'select', width: 'min',
            selectOptions: of([
               {label: 'Publish', value: 'publish'},
               {label: 'Draft', value: 'draft'},
            ])
         },
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min',},
      ],
   };
   public type = null;
   @LocalStorage() routeDataMp;

   public testSeriesPage = false;
   public typeOfTest = null;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public activatedRoute: ActivatedRoute,
               public loader: NgxUiLoaderService) {
      this.endpoint = environment.endpoint + 'admin/programme/list';
      this.activatedRoute.queryParams.subscribe(res => {
         if (res.type && res.cat) {
            this.endpoint += '?type=' + res.type + '&cat=' + res.cat;
            this.testSeriesPage = true;
            this.typeOfTest = res.cat;
            this.type = res.type;
         } else {
            this.typeOfTest = null;
         }
      });
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         sub_heading: new UntypedFormControl(),
         // program_category: new FormControl(),
         category: new UntypedFormControl([]),
         course_id: new UntypedFormControl(),
         courses: new UntypedFormControl(),
         featured_image: new UntypedFormControl(),
         icon_image: new UntypedFormControl(),
         sample_brochure: new UntypedFormControl(),
         total_fee: new UntypedFormControl(),
         discounted_fee: new UntypedFormControl(),
         full_down_payment_discount: new UntypedFormControl(),
         partial_payment: new UntypedFormControl(),
         minimum_partial_payment: new UntypedFormControl(),
         days_to_pay_full_payment: new UntypedFormControl(),
         penalty: new UntypedFormControl(),
         penalty_threshold: new UntypedFormControl(),
         batch_code: new UntypedFormControl(),
         batch_year: new UntypedFormControl(),
         batch_time: new UntypedFormControl(),
         start_date: new UntypedFormControl(),
         key_point_1: new UntypedFormControl(),
         key_point_2: new UntypedFormControl(),
         key_point_3: new UntypedFormControl(),
         key_point_4: new UntypedFormControl(),
         key_point_5: new UntypedFormControl(),
         key_point_6: new UntypedFormControl(),
         key_point_7: new UntypedFormControl(),
         content: new UntypedFormControl(),
         available: new UntypedFormControl(),
         sort_order: new UntypedFormControl(),
         offline_payment: new UntypedFormControl(),
         online_payment: new UntypedFormControl(),
         test_offline: new UntypedFormControl(),
         test_online: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
         status: new UntypedFormControl(),

         tax_percentage: new UntypedFormControl(18),
         tution_fee: new UntypedFormControl(),
         admission_fee: new UntypedFormControl(),
         no_of_tests: new UntypedFormControl(),
         type_of_test: new UntypedFormControl(this.typeOfTest),
         programs: new UntypedFormControl(),
         upcoming: new UntypedFormControl(false),
         upcoming_date: new UntypedFormControl(),
      });

   }

   loadCategories() {
      this.api.post('admin/course/get-tree', {})
         .pipe(finalize(() => {
         }))
         .subscribe((res: any) => {
            this.courses = res;
         });
   }

   ngOnInit() {
      this.loadCategories();
      this.loadPrograms();
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({category: [], tax_percentage: 18});
      if (this.type === 'test') {
         const value = {type_of_test: this.typeOfTest, category: [15]};
         this.form.patchValue(value);
      }
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/programme/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   calculateTaxes() {
      // console.log('cal taxes');
      const admissionFee = this.form.value.admission_fee;
      const tutionFee = this.form.value.tution_fee;
      const tax = this.form.value.tax_percentage;

      const admissionBasePrice = +this.getBasePrice(admissionFee, tax);
      const tutionBasePrice = +this.getBasePrice(tutionFee, tax);

      this.taxes = {
         admissionTax: (admissionFee - admissionBasePrice).toFixed(2),
         admissionFee: this.getBasePrice(admissionFee, tax),
         tutionTax: (tutionFee - tutionBasePrice).toFixed(2),
         tutionFee: this.getBasePrice(tutionFee, tax),
         net: +admissionFee + +tutionFee
      };

      this.form.patchValue({
         total_fee: this.taxes.net
      });
   }

   getBasePrice(inclusiveOfTax, tax) {
      const v = (inclusiveOfTax / (100 + +tax)) * 100;
      return v.toFixed(2);
   }

   showAds(item, idx) {
      this.selectedItem = item;
      this.form.patchValue(this.selectedItem);
      const modalRef = this.modal.open(ManageAdsComponent);
      const config: IAdManagerConfig = {
         adType: 'program',
         isModal: true,
         parent: item.id,
         title: item.name
      };
   }

   duplicate(item) {

      Swal.fire({
         title: 'Duplicate Programme',
         showCancelButton: true,
         confirmButtonText: 'Duplicate Program',
         input: 'radio',
         inputValue: 'duplicate',
         inputOptions: {
            duplicate: 'Just Duplicate this program',
            delete: 'Duplicate and delete original program'
         },
         inputPlaceholder: 'I agree with the terms and conditions',
      }).then(res => {

         if (res.value) {
            this.loader.start();
            this.api.post('admin/programme/duplicate', {id: item.id, type: res.value})
               .pipe(finalize(() => this.loader.stop()))
               .subscribe(() => {
                  this.table.loadData();
               });
         }
      });

   }

   edit(_item, idx) {
      this.selectedItem = _item;
      this.form.reset();
      this.form.patchValue(this.selectedItem);

      const cid = [];
      const programCategory = [];

      this.selectedItem.courses.forEach(item => {
         cid.push(item.id);
      });
      this.selectedItem.category.forEach(item => {
         programCategory.push(item.id);
      });

      this.form.patchValue({courses: cid, category: programCategory});
      this.calculateTaxes();
      const ref = this.modal.open(this.addNewModal);
   }

   delete(item, idx) {

      this.loader.start();
      this.api.post('admin/programme/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(idx);
         });
   }

   openAllotTests(item: any) {
      const params = lo.pick(item, ['id', 'name']);
      this.router.navigate(['dashboard', 'manage', 'test-series', 'allot-tests'], {queryParams: params});
   }

   public programs = [];
   public dropdownSettings = {
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
   };

   loadPrograms(term = null) {
      this.api.post('admin/programme/search', {term}).subscribe((res: any) => {
         this.programs = [].concat(res);
      });
   }

   onSearch(evt: any) {

      this.loadPrograms(evt.target.value);
   }

   manageAds(data: any, item: any) {
      const modalRef = this.modal.open(ManageAdsComponent);
      const config: IAdManagerConfig = {
         adType: 'program',
         isModal: true,
         parent: item.id,
         title: item.name

      };
      modalRef.componentInstance.setConfig(config);
   }

}
