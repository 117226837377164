import {AfterViewChecked, AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Constants} from '../../Constants';
import {PhotoService} from '../../services/photo.service';
import {BaseComponent} from '../BaseComponent';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

@Component({
   selector: 'app-manage-teacher',
   templateUrl: './manage-contact-forms.component.html',
   styleUrls: ['./manage-contact-forms.component.scss']
})
export class ManageContactFormsComponent extends BaseComponent {
   public enquiryType = 'Website';
   public dialogVisible = false;
   @ViewChild('addNew') addNewModal;
   @ViewChild('mpic') pic: TemplateRef<any>;
   @ViewChild('table') table;
   public editorOptions = Constants.editorOptions;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/contact-form/list?type=Website';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'name', type: 'text', width: ''},
         {name: 'Phone', key: 'phone'},
         {name: 'Email', key: 'email'},
         {name: 'Programs', key: 'interested_in', filterKey: 'interestedPrograms.name:interested_programs'},
         {name: 'Message', key: 'message'},
         {name: 'Status', key: 'status', type: 'text', width: 'min',},
         {name: 'Created', key: 'created_at', type: 'dateIST'}
      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               this.dialogVisible = true;
            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].title + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/contact-form/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe(() => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ],
      sortFn: (item) => {
         this.toastr.info('Updating..', null, {
            timeOut: 800
         });
         this.api.post('admin/contact-form/save', item)
            .pipe(finalize(() => {
               this.loader.stop();
            }))
            .subscribe((res: any) => {
               this.toastr.success('Sort Order Updated', null, {
                  timeOut: 800
               });
            });
         return void (0);
      }
   };

   public form: UntypedFormGroup;
   public pageForm;
   public pageId = 30;

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public modal: NgbModal,
      public photoService: PhotoService,
      public loader: NgxUiLoaderService
   ) {
      super(toastr, api, auth, router, modal, loader);
      this.loadPage();

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         status: new UntypedFormControl(),
         reply: new UntypedFormControl(),
      });
      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl()
      });
      this.loadPage();
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({
         status: 'publish',
      });
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.form.patchValue({type: 'teacher'});
      this.api.post('admin/contact-form/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   enquiryTypeChanged() {
      this.endpoint = environment.endpoint + 'admin/contact-form/list?type=' + this.enquiryType;
   }
}
