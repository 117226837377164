import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ApiService} from '../../services/api.service';
import {finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Form, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LocalStorage} from 'ngx-webstorage';
import {XsLaravelDataTableComponent} from '../../../../projects/xs-laravel-data-table/src/lib/xs-laravel-data-table.component';

@Component({
   selector: 'app-paid-content',
   templateUrl: './paid-content.component.html',
   styleUrls: ['./paid-content.component.scss']
})
export class PaidContentComponent implements AfterViewInit {
   public folderTree = [{title: 'Home', id: 0}];
   @ViewChild('table') table: XsLaravelDataTableComponent;
   @ViewChild('addForm') form: any;
   public programs = [];
   @LocalStorage()
   public selectedProgram;
   public endpoint;
   public tableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'title', type: 'text'},
         {name: 'Type', key: 'type', type: 'text'},
         {name: 'Status', key: 'status', type: 'text'},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
         {name: 'Created', key: 'created_at', type: 'dateIST'},
      ],
   };
   public addItemForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      title: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('folder'),
      icon: new UntypedFormControl(),
      status: new UntypedFormControl('', [Validators.required]),
      file: new UntypedFormControl(),
      parent: new UntypedFormControl(0),
      sort_order: new UntypedFormControl(),
      programs: new UntypedFormControl()
   });

   constructor(public loader: NgxUiLoaderService, public api: ApiService
   ) {
      this.loadPrograms();
   }

   ngAfterViewInit(): void {
      this.endpoint = environment.endpoint + 'admin/paid-material/list?p=' + this.selectedProgram;
      this.programUpdated();
   }

   loadPrograms() {
      this.loader.start();
      this.api.get('models/programs', {})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.programs = res;
         })
   }


   saveFile() {
      if (this.addItemForm.invalid) {
         this.addItemForm.markAllAsTouched();
         return;
      }
      if (this.addItemForm.value.type == null) this.addItemForm.patchValue({type: 'folder'})
      this.loader.start();
      this.api.post('admin/paid-material/save', this.addItemForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.table.loadData();
            this.form.nativeElement.reset();
            this.addItemForm.patchValue({parent: this.folderTree[(this.folderTree.length - 1)].id, programs: [this.selectedProgram]},);
         })
   }

   resetForm() {

   }

   edit(item: any) {
      const clonedItem = {...item};
      if (clonedItem.programs) {
         clonedItem.programs = clonedItem.programs.map(p => p.id)
      }
      this.addItemForm.patchValue(clonedItem);
   }

   open(item: any) {
      this.folderTree.push(item);
      this.endpoint = environment.endpoint + 'admin/paid-material/list?parent=' + item.id + '&p=' + this.selectedProgram;
      this.form.nativeElement.reset();
      this.addItemForm.patchValue({parent: item.id, programs: [this.selectedProgram], type: 'folder'});
   }

   delete(item) {
      this.loader.start();
      this.api.post('admin/paid-material/delete', {id: item.id})
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            this.table.loadData();
         })
   }

   restore(item: { id: number; title: string }) {
      const idx = this.folderTree.findIndex(i => item.id === i.id);
      this.folderTree.splice(idx, 100);
      this.open(item);
   }

   programUpdated() {
      this.folderTree = [{title: 'Home', id: 0}];
      this.addItemForm.reset({parent: 0, type: 'folder', programs: [this.selectedProgram]});
      this.endpoint = environment.endpoint + 'admin/paid-material/list?p=' + this.selectedProgram;
   }
}
