import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ILaravelDataTableConfig} from '../../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';

@Component({
   selector: 'app-manage-manage-current-affair-comments',
   templateUrl: './manage-manage-current-affair-comments.component.html',
   styleUrls: ['./manage-manage-current-affair-comments.component.scss']
})
export class ManageManageCurrentAffairCommentsComponent {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/current-affair/comments/list';
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Comment', key: 'comment', type: 'text'},
         {name: 'User', key: 'user', type: 'text', width: 'min'},
         {name: 'Status', key: 'status', type: 'text', width: 'min'}
      ],
   };
   public form: UntypedFormGroup;
   public pageForm: UntypedFormGroup
   public queryParams;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public route: ActivatedRoute,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl('', Validators.compose([])),
         user_id: new UntypedFormControl('', Validators.compose([])),
         author_name: new UntypedFormControl('', Validators.compose([])),
         comment: new UntypedFormControl('', Validators.compose([])),
         status: new UntypedFormControl('', Validators.compose([])),
         type: new UntypedFormControl('', Validators.compose([])),
         subject_id: new UntypedFormControl('', Validators.compose([])),
         parent: new UntypedFormControl('', Validators.compose([])),
         rating: new UntypedFormControl('', Validators.compose([])),
         admin_comment: new UntypedFormControl()
         //add
      });


      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl(),
      });

      this.route.queryParamMap.subscribe((params: any) => {
         this.queryParams = params.params;
         this.endpoint = this.endpoint + '?type=current-affair&id=' + this.queryParams.id
      });
   }

   delete(id) {
      this.loader.start();
      this.api.post('admin/comment/delete', {id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((_) => {
            this.table.loadData();
         });
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/comment/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }


   edit(item: any) {
      this.form.patchValue(item);
      this.modal.open(this.addNewModal);
   }
}
