<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Comments - {{queryParams?.title}}</h3>

</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template lt-cell-template field="comment" let-idx="index" let-item="">
      <div [innerHTML]="item.comment"></div>
      <a (click)="edit(item)" class="badge badge-primary text-white text-uppercase pt-1 mr-3">Edit</a>
      <a mwlConfirmationPopover (confirm)="delete(item.id)" class="badge badge-danger  text-white text-uppercase pt-1">Delete</a>
   </ng-template>
   <ng-template lt-cell-template field="user" let-idx="index" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.profile_image" width="100" height="100"></app-cdn-image>
         <div class="ml-2">
            <strong class="">{{item.user.name}}</strong>
            <small class="d-block">{{item.user.email}}</small>
         </div>
      </div>
   </ng-template>
    <ng-template lt-cell-template field="status"  let-idx="index" let-item="">
       <app-badge [text]="item.status"></app-badge>
    </ng-template>

</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Comment</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form"><input type="hidden" formControlName="id" class="form-control">
         <div class="row">

            <div class="col-sm-12" *ngIf="selectedItem">
               <label class="d-block">Author : {{selectedItem.user.name}}</label>
               <label class="d-block">EMail : {{selectedItem.user.email}}</label>
               <label class="d-block">Phone : {{selectedItem.user.phone_no}}</label>

            </div>

            <div class="col-12 mt-4">
               <div class="form-group">
                  <label>COMMENT</label>
                  <textarea type="text" formControlName="comment" class="form-control"></textarea>
               </div>
            </div>


            <div class="col-6">
               <div class="form-group">
                  <label>RATING</label>
                  <input type="text" formControlName="rating" class="form-control">
               </div>
            </div>

            <div class="col-6">
               <div class="form-group">
                  <label class="d-block">STATUS</label>
                  <app-status formControlName="status" [statusList]="['Pending','Publish','Reject']"></app-status>

               </div>
            </div>
            <div class="col-12 mt-4" *ngIf="form.value.status=='publish'">
               <div class="form-group">
                  <label>ADMIN COMMENT</label>
                  <textarea type="text" formControlName="admin_comment" class="form-control"></textarea>
               </div>
            </div>

         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
