import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {finalize} from 'rxjs/operators';

@Component({
   selector: 'app-manage-who-we-are',
   templateUrl: './manage-who-we-are.component.html',
   styleUrls: ['./manage-who-we-are.component.scss']
})
export class ManageWhoWeAreComponent implements OnInit {
   public data = {
      bdrt: {},
      about_us: '',
      about_director: '',
      director_image: '',
      director_message: '',
   }

   constructor(public api: ApiService, public loader: NgxUiLoaderService) {
   }

   ngOnInit(): void {
      this.api.post('admin/settings', {key: 'who_we_are'}).subscribe((res: any) => {
         this.data = res;
         console.log(res);
      })
   }

   save() {
      this.loader.start();
      this.api.post('admin/settings/save', {key: 'who_we_are', value: this.data})

         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
         })
   }
}
