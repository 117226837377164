import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PhotoService} from '../../services/photo.service';
import {ApiService} from '../../services/api.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';

@Component({
   selector: 'app-multi-pic-uploader',
   templateUrl: './multi-pic-uploader.component.html',
   styleUrls: ['./multi-pic-uploader.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => MultiPicUploaderComponent),
         multi: true
      }
   ]
})
export class MultiPicUploaderComponent implements OnInit, ControlValueAccessor {
   @Output() public uploaded = new EventEmitter();
   @Input() public dir = 'misc';
   @Input() public label;
   @Input() public standAlone = false;
   public value = [];

   public progress = 0;
   propagateChange = (_: any) => {
   };

   constructor(public photoService: PhotoService, public api: ApiService) {
   }

   ngOnInit() {
   }

   registerOnChange(fn: any): void {
      this.propagateChange = fn;
   }

   registerOnTouched(fn: any): void {
   }

   writeValue(obj: any): void {
      if (obj) {
         this.value = obj;
      }
   }

   upload($e) {
      const files = $e.target.files;
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
         //   console.log(files);
         formData.append('icon[]', files[i]);
      }
      formData.set('dir', this.dir);

      this.api.upload('admin/upload-image', formData, true).subscribe((res: any) => {
         if (res.status === 'progress') {
            this.progress = res.message;
         } else {
            if (res && res.files && res.files.images) {
               this.progress = null;
               if (!Array.isArray(this.value)) {
                  this.value = [];
               }
               this.value = this.value.concat(res.files.images);
               $e.target.value = null;
               this.emitUpdates();

               if (this.standAlone) {
                  this.uploaded.emit(this.value);
                  this.value = [];
               }
            }
         }
      });
   }

   emitUpdates() {
      this.propagateChange(this.value);
   }

   remove(img: any) {
      const index = this.value.indexOf(img);
      this.value.splice(index, 1);
   }
}
