import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NoticeCountService} from '../../services/notice-count.service';
import {ILaravelDataTableConfig} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';
import * as lo from 'lodash';

@Component({
   selector: 'app-manage-enquiry-callback',
   templateUrl: './manage-enquiry-callback.component.html',
   styleUrls: ['./manage-enquiry-callback.component.scss']
})
export class ManageEnquiryCallbackComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   public pageId;
   public selectedItem;
   public endpoint = environment.endpoint + 'admin/enquiry-callback/list';
   public displayExport = false;
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'ID', key: 'id', type: 'text', width: 'min'},
         {name: 'Name', key: 'request_name', type: 'text'},
         {name: 'Phone', key: 'request_phoneno', type: 'text'},
         {name: 'Email', key: 'request_email', type: 'text'},
         {name: 'Program', key: 'program.course_name', type: 'text'},
         {name: 'Date', key: 'created_at', type: 'dateIST'},
         {name: 'Status', key: 'status'},
      ]
   };
   public form: UntypedFormGroup;
   public exportForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public noticeCountService: NoticeCountService,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
      });
      this.noticeCountService.clear('callback');
      this.exportForm = new UntypedFormGroup({
         from: new UntypedFormControl(),
         to: new UntypedFormControl(),
         type: new UntypedFormControl('all')
      });
   }

   ngOnInit() {
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modal.open(addNew);
   }

   save() {
      this.loader.start();
      this.api.post('admin/enquiry-callback/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
            this.table.loadData();
         });
   }

   delete(item, index) {

      this.loader.start();
      this.api.post('admin/enquiry-callback/delete', {id: item.id})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe(() => {
            this.table.removeItemAtIndex(index);
         });

   }

   markAsRead(item, unread = false) {
      this.loader.startBackground();
      this.api.post('admin/enquiry-callback/mark-as-read', {id: item.id, unread})
         .pipe(finalize(() => this.loader.stopBackground()))
         .subscribe((res: any) => {
            lo.assign(item, {
               status: res.data.status,
               read_at: res.data.read_at,
            });
         });
   }

   export() {
      this.loader.start();
      this.api.post('admin/enquiry-callback/export', this.exportForm.value)
         .pipe(finalize(() => this.loader.stop()))
         .subscribe((res: any) => {
            window.location = res.url;
         });
   }
}
