import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
   selector: 'app-doc-uploader',
   templateUrl: './icon-uploader.component.html',
   styleUrls: ['./icon-uploader.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => DocUploaderComponent),
         multi: true
      }
   ]
})
export class DocUploaderComponent implements ControlValueAccessor {
   public value;
   @Input()
   endpoint = 'admin/upload-doc-file';

   @Input()
   public label = 'Upload';

   @Input()
   public url;

   @Input()
   public directory;

   @Input()
   public formats;

   public progress = 0;
   @Output() uploaded = new EventEmitter();
   public propagateChange = (_: any) => {
   };
   @ViewChild('file', {static: true}) file;

   constructor(
      public toastr: ToastrService,
      public api: ApiService,
      public auth: AuthService,
      public router: Router,
      public loader: NgxUiLoaderService) {

   }

   writeValue(obj: any): void {

      this.value = obj;

   }

   registerOnChange(fn: any): void {
      this.propagateChange = fn;
   }

   registerOnTouched(fn: any): void {

   }

   setDisabledState?(isDisabled: boolean): void {

   }

   iconUpload($event) {
      const icon = $event.target.files[0];

      const formData = new FormData();
      formData.append('icon', icon);
      formData.set('dir', this.directory);
      this.api.upload(this.endpoint, formData).subscribe((res: any) => {
         if (res.status === 'progress') {
            this.progress = res.message;

         } else {
            if (res.files) {
               //   console.log(res);
               this.progress = 0;
               this.propagateChange(res.files.path);
               this.value = res.files.path;

               this.uploaded.emit({
                  path: res.files.path,
                  url: res.files.url
               });
               this.file.nativeElement.value = '';

            }

         }
      });
   }

   clear() {
      this.file.nativeElement.value = '';
      this.url = null;
      this.propagateChange(null);
      this.value = null;

   }
}
