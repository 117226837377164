<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Experts</h3>
   <div>
      <button class="btn btn-success btn-icon mr-2" (click)="modal.open(bdrt)">Edit BDRT</button>
      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>
   </div>
</div>

<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">

   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
   <ng-template field="name" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.featured_image" class="mr-2"></app-cdn-image>
         {{item.name}}
      </div>
   </ng-template>


</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{form.value.id ? 'Update' : 'New'}} Teacher</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <div class="row"><!--add-->
            <input type="hidden" formControlName="id" class="form-control">


            <div class="col-6">
               <div class="form-group">
                  <label for="">Teacher Name</label>
                  <input type="text" formControlName="name" class="form-control">
               </div>
            </div>
            <div class="col-6">
               <div class="form-group">
                  <label for="">Qualification</label>
                  <input type="text" formControlName="qualification" class="form-control">
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label for="">Featured Image</label>
                  <app-photo-uploader dir="teachers" formControlName="featured_image"></app-photo-uploader>
               </div>
            </div>

            <div class="col-12">
               <div class="form-group">
                  <label for="">Description</label>
                  <app-editor formControlName="description"></app-editor>
               </div>
            </div>
            <div class="col-6">
               <div class="form-group">
                  <label class="d-block">Status</label>
                  <app-status formControlName="status"></app-status>
               </div>
            </div>
            <div class="col-6">
               <div class="form-group">
                  <label for="">Sort Order</label>
                  <input type="text" appNumberOnly formControlName="sort_order" class="form-control">
               </div>
            </div>


         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>


<ng-template #bdrt let-modal>
   <div class="modal-header">
      <h5 class="modal-title">Teachers BDRT</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="pageForm">
         <app-bdrt formControlName="bdrt"></app-bdrt>
         <input type="hidden" formControlName="id" class="form-control">
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="saveBdrt()" class="btn btn-primary">{{form.value.id ? 'Update' : 'Save'}}</button>
   </div>
</ng-template>
