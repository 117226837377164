<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Paid Material</h3>
   <div>
   </div>

</div>
<div>
   <h6>Select a program to manage its content</h6>
   <p-dropdown
      [(ngModel)]="selectedProgram"
      (ngModelChange)="programUpdated()"
      [autoDisplayFirst]="false"
      styleClass="tw-w-full"
      [filter]="true"
      filterBy="name"
      [options]="programs"
      [optionLabel]="'name'"
      [optionValue]="'id'"></p-dropdown>
</div>
<div class="tw-flex tw-mt-8 tw-gap-8" *ngIf="selectedProgram">
   <div class="tw-grow">
      <div class="tw-p-4 tw-bg-gray-100">
         <a class="tw-cursor-pointer" (click)="restore(item)" *ngFor="let item  of folderTree"> {{item.title}} ></a>
      </div>
      <xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
         <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
            <a class="tw-mx-2 tw-text-white tw-rounded tw-px-4 tw-py-2 tw-bg-blue-500" (click)="edit(item)">Edit</a>
            <a class="tw-mx-2 tw-text-white tw-rounded tw-px-4 tw-py-2 tw-bg-purple-500" (click)="open(item)" *ngIf="item.type=='folder'">Open</a>
            <a class="tw-mx-2 tw-text-white tw-rounded tw-px-4 tw-py-2 tw-bg-red-500" mwlConfirmationPopover (confirm)="delete(item)">Delete</a>
         </ng-template>
      </xs-laravel-data-table>
   </div>
   <div class="tw-w-[400px]">
      <div class="section">{{addItemForm.value.id ? 'Update' : "Add New"}} Item</div>
      <form [formGroup]="addItemForm" (keydown.enter)="$event.preventDefault()" errorTailor #addForm>
         <div class="form-group">
            <label for="">Name</label>
            <input formControlName="title" type="text" class="form-control">
         </div>

         <div class="form-group">
            <label for="">Type</label>
            <select formControlName="type" class="form-control">
               <option value="folder">Folder</option>
               <option value="document">Document/File</option>
               <option value="link">Link</option>
               <option value="video">Video</option>
               <option value="youtube">Youtube Video</option>
            </select>
         </div>

         <div class="form-group" *ngIf="addItemForm.value.type=='folder'">
            <label class="d-block">Icon</label>
            <app-icon-uploader formControlName="icon"></app-icon-uploader>
         </div>
         <ng-container *ngIf="addItemForm.value.type && addItemForm.value.type !='folder'">
            <ng-container [ngSwitch]="addItemForm.value.type">
               <div class="form-group" *ngSwitchCase="'youtube'">
                  <label class="d-block">Youtube Video Url</label>
                  <input class="form-control" formControlName="file"/>
               </div>
               <div class="form-group" *ngSwitchCase="'link'">
                  <label class="d-block">Link Url</label>
                  <input class="form-control" formControlName="file"/>
               </div>

               <div class="form-group" *ngSwitchDefault>
                  <label class="d-block">Upload File</label>
                  <app-doc-uploader endpoint="admin/upload-doc" formControlName="file"></app-doc-uploader>
               </div>

            </ng-container>


         </ng-container>
         <div class="form-group" *ngIf="addItemForm.value.parent==0">
            <label for="" class="form-label">Include in other batches</label>
            <p-multiSelect formControlName="programs" display="chip" [filterBy]="'name,batch_code'" styleClass="tw-w-full" [options]="programs" optionLabel="name" optionValue="id">
               <ng-template let-item pTemplate="item">
                  <div class="country-item">
                     <div> {{item.name}}</div>
                     <small class="tw">{{item.batch_code}}</small>
                  </div>
               </ng-template>
            </p-multiSelect>
         </div>
         <div class="row">
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="" class="d-block">Sort Order</label>
                  <input class="form-control" formControlName="sort_order" appNumberOnly/>
               </div>
            </div>
            <div class="col-sm-6">
               <div class="form-group">
                  <label for="" class="d-block">Status</label>
                  <app-status formControlName="status"></app-status>
               </div>
            </div>
         </div>

         <button (click)="saveFile()" class="btn btn-block btn-success">{{addItemForm.value.id ? 'Update Item' : "Save Item"}}</button>

      </form>

   </div>
</div>
