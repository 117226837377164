import {Component, Input, OnInit} from '@angular/core';
import * as lo from 'lodash';

@Component({
   selector: 'app-section-header',
   templateUrl: './section-header.component.html',
   styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {
   public section;
   @Input() sections;
   @Input() index;

   constructor() { }

   ngOnInit(): void {
      this.getSection();
   }

   getSection() {
      let section = null;
      if (!this.sections) return;
      lo.each(this.sections, (item) => {
         const range = item.range.split('-');

         if (+range[0] === this.index) {
            section = item.marks;
         }
      });

      this.section = section;
   }
}
