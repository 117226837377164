import {NgModule} from '@angular/core';
import {CheckboxGroupComponent} from './checkbox-group.component';
import {CommonModule} from '@angular/common';

@NgModule({
   declarations: [CheckboxGroupComponent],
   imports: [
      CommonModule
   ],
   exports: [CheckboxGroupComponent]
})
export class CheckboxGroupModule {
}
