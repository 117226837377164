<div class="modal-header">
   <h5 class="modal-title">#Test Order Id: {{testOrderId}}</h5>
   <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <form [formGroup]="form">

      <div class="form-group">
         <label>Transaction ID</label>
         <input class="form-control" type="text" formControlName="gateway_transaction_id">
      </div>
      <div class="form-group">
         <label>Date</label>
         <app-date-picker formControlName="added_date"></app-date-picker>
      </div>
      <div class="section">Amounts</div>
      <app-sub-payment formControlName="sub_payments" [items]="orderItems"></app-sub-payment>

      <div class="form-cgroup">
         <label class="d-block">Transaction Status</label>
         <app-status [statusList]="['success','failed']" formControlName="gateway_status"></app-status>
      </div>

      <div class="form-group mt-3">
         <label for="">Cash Payment</label>
         <app-toggle-switch label0="No" label1="Yes" formControlName="cash"></app-toggle-switch>
      </div>

   </form>
</div>
<div class="modal-footer">
   <button *ngIf="form.value.id" (click)="modal.close(false)" class="btn btn-danger   btn-primary">Cancel</button>
   <button class="btn  btn-primary" (click)="save()">{{form.value.id ? 'Update' : 'Add'}}  Transaction</button>
</div>
