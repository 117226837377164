import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ManageCurrentAffairCategoryComponent} from './manage-current-affair-category/manage-current-affair-category.component';
import {ManageCurrentAffairComponent} from './manage-current-affair/manage-current-affair.component';
import {ManageManageCurrentAffairCommentsComponent} from './manage-manage-current-affair-comments/manage-manage-current-affair-comments.component';

const routes: Routes = [
   {path: 'list', component: ManageCurrentAffairComponent},
   {path: 'list/comments', component: ManageManageCurrentAffairCommentsComponent},
   {path: 'categories', component: ManageCurrentAffairCategoryComponent},
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class CurrentAffairsRoutingModule {
}
