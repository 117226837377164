import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import t from 'typy';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize} from 'rxjs/operators';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';

@Component({
   selector: 'app-manage-main-courses',
   templateUrl: './manage-main-courses.component.html',
   styleUrls: ['./manage-main-courses.component.scss']
})
export class ManageMainCoursesComponent implements OnInit {
   @ViewChild('addNew') addNew;
   public form: UntypedFormGroup;
   public tree;
   public shownPanels = [];
   private selectedItem: null;
   public modalRef;
   public updating = false;
   public pageForm: UntypedFormGroup;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public loader: NgxUiLoaderService) {

      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         course_name: new UntypedFormControl(),
         sort: new UntypedFormControl(),
         status: new UntypedFormControl(),
         parent: new UntypedFormControl(''),
         bdrt: new UntypedFormControl()
      });
      this.pageForm = new UntypedFormGroup({
         id: new UntypedFormControl(),
         bdrt: new UntypedFormControl()
      });
      this.loadPage();
   }

   ngOnInit() {
      this.getTree();
   }

   saveBdrt() {
      this.loader.start();
      this.api.post('admin/page/save', this.pageForm.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modal.dismissAll();
         });
   }

   loadPage() {
      this.loader.start();
      this.api.get('admin/page/7', {})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.pageForm.patchValue(res);
         });
   }

   getTree() {
      this.api.post('admin/course/get-tree', {}).subscribe((res: any) => {
         this.tree = res;
      });
   }

   drop(event, arr) {
      moveItemInArray(arr, event.previousIndex, event.currentIndex);
   }

   save() {

      this.updating = true;
      this.api.post('admin/main-course/save', this.form.value)
         .pipe(finalize(() => {
            this.updating = false;
         }))
         .subscribe((res: any) => {
            this.modalRef.close();
            this.getTree();
         });

   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset();
      this.modalRef = this.modal.open(addNew);
   }

   edit($event: any) {
      this.selectedItem = $event;
      setTimeout(() => {
         this.form.patchValue(this.selectedItem);
      });

      this.modalRef = this.modal.open(this.addNew);
   }

   delete($event: any) {
      //console.log($event);
      swal.fire({
         title: 'Delete :' + $event.course_name + '?',
         icon: 'question',
         showCancelButton: true,
         cancelButtonText: 'No',
         confirmButtonText: 'Yes',
      }).then(res => {
         if (res.value) {
            this.loader.start();
            this.api.post('admin/main-course/delete', {id: $event.id})
               .pipe(finalize(() => {
                  this.loader.stop();
               }))
               .subscribe(() => {
                  this.getTree();
               });
         }
      });
   }

   statusUpdate($event: any) {
      this.loader.start();
      this.api.post('admin/main-course/save', {id: $event.item.id, status: $event.status})
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            $event.item.status = $event.status;
         });
   }
}
