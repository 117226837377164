<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Book Coupon Batches</h3>
   <div>
      <div class="spaced-btns">
         <button class="btn btn-secondary btn-icon" routerLink="/dashboard/manage/book-coupons">Manage Book Coupons</button>
         <button class="btn btn-primary btn-icon" (click)="showAddModal=true"> Add New</button>
      </div>
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">

   <ng-template lt-cell-template field="actions" let-item let-idx="index">
      <div class="spaced-btns">
         <button (click)="edit(item,idx)" class="btn btn-sm btn-primary">Edit</button>
         <button (confirm)="delete(item,idx)" class="btn btn-sm btn-danger" mwlConfirmationPopover>Delete</button>
      </div>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <span class="tbl-status" [ngClass]="'status-'+item.status">{{item.status}}</span>
   </ng-template>

</xs-laravel-data-table>

<p-dialog maximizable="true" modal="true" header="Add New Coupon Batch" [(visible)]="showAddModal" (onHide)="form.reset();selectedTests=[]">

   <form [formGroup]="form">
      <div class="form-group">
         <label for="">Name</label>
         <input type="text" class="form-control" formControlName="name">
      </div>

      <div class="row">
         <div class="col-sm-6">
            <div class="form-group">
               <label for="" class="d-block">Valid From</label>
               <app-prime-date-time-picker formControlName="active_from"></app-prime-date-time-picker>

            </div>
         </div>
         <div class="col-sm-6">
            <div class="form-group">
               <label for="" class="d-block">Valid To</label>
               <app-prime-date-time-picker formControlName="active_to"></app-prime-date-time-picker>
            </div>
         </div>
      </div>
      <div class="form-group">
         <label for="" class="d-block">Description</label>
         <app-editor formControlName="description"></app-editor>
      </div>
      <div class="form-group">
         <label for="">Free Tests Count</label>
         <input type="text" class="form-control" appNumberOnly formControlName="test_count">
      </div>

      <ng-container *ngIf="selectedTests.length>0">
         <h5>Selected Tests</h5>
         <ul class="list-unstyled ">
            <li *ngFor="let t of selectedTests;let idx=index" class="pt-2 pb-2 border-bottom d-flex justify-content-between">
               <div>
                  <span class="p-badge">{{idx + 1}}</span> {{t.name}}
               </div>
               <button (click)="addTest(t)" class="btn btn-sm btn-primary"
                       [ngClass]="{'btn-primary': !isSelected(selectedTests,t.id),'btn-danger': isSelected(selectedTests,t.id)}">{{isSelected(selectedTests, t.id) ? 'Remove' : 'Add'}} </button>

            </li>
         </ul>
      </ng-container>
      <div class="form-group">
         <label for="" class="d-block">Search Test Series</label>
         <p-autoComplete appendTo="body" field="name" (onSelect)="selected($event)" [suggestions]="searchResults" (completeMethod)="search($event)">
            <ng-template let-item pTemplate="item">
               <div class="d-flex"> {{item.name}} <span>add</span></div>
            </ng-template>
         </p-autoComplete>

      </div>
      <ul class="list-unstyled ">
         <li *ngFor="let t of tests" class="pt-2 pb-2 border-bottom d-flex justify-content-between">
            {{t.name}}
            <button (click)="addTest(t)" class="btn btn-sm btn-primary"
                    [ngClass]="{'btn-primary': !isSelected(selectedTests,t.id),'btn-danger': isSelected(selectedTests,t.id)}">{{isSelected(selectedTests, t.id) ? 'Remove' : 'Add'}} </button>

         </li>
      </ul>

      <button type="button" class="btn btn-success" (click)="saveBatch()" [disabled]="form.invalid">{{form.value.id ? 'Update' : 'Add'}}  Batch</button>
   </form>
</p-dialog>



