import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PhotoService} from '../../services/photo.service';
import {IApplication} from '../../../../projects/student-dashboard/interfaces/IApplication';
import {environment} from '../../../environments/environment';
import {UrlHelperService} from '../../services/url-helper.service';

@Component({
   selector: 'app-enrollment-detail-view',
   templateUrl: './enrollment-detail-view.component.html',
   styleUrls: ['./enrollment-detail-view.component.scss']
})
export class EnrollmentDetailViewComponent implements OnInit, OnChanges {

   @Input() order;
   public application: IApplication;
   public environment = environment;

   constructor(public photoService: PhotoService, public url: UrlHelperService) {
   }

   ngOnInit(): void {
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (this.order) {
         this.application = this.order.application;
      }
   }

}
